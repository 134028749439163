import React from 'react'
import PropTypes from 'prop-types'

const IconClose = ({ className, onClick }) => {
	return (
		<svg
			className={className}
			onClick={onClick}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			stroke="#969FA8"
		>
			<path 
				d="M6 6L18 18M18 6L6 18"
				stroke="inherit"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>

	)

}

IconClose.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func
}

export default IconClose
