import {
	REQUEST_FAILED,
	DELETE_ERROR,
	DELETE_LAST_ERROR,
	DELETE_All_ERRORS
} from '../types/errorTypes'

const initialState = {
	errors: []
}

export const errorReducer = (state = initialState, action) => {
	switch (action.type) {
		case REQUEST_FAILED:
			return { ...state, errors: [...state.errors, action.payload] }
		case DELETE_ERROR:
			return { ...state, errors: state.errors.filter(item => item.createdAt !== action.payload) }
		case DELETE_LAST_ERROR:
			return { ...state, errors: [state.errors.pop()] }
		case DELETE_All_ERRORS:
			return { ...state, errors: [] }
		default: return state
	}
}
