import React from 'react'
import PropTypes from 'prop-types'
const IconSearch = ({ className }) => {
	return (
		<svg
			className={className} width="15"
			height="15" viewBox="0 0 15 15"
			xmlns="http://www.w3.org/2000/svg">
			<path d="M6.60567 0C2.9634 0 0 2.9634 0 6.60567C0 10.2481 2.9634 13.2113 6.60567 13.2113C10.2481 13.2113 13.2113 10.2481 13.2113 6.60567C13.2113 2.9634 10.2481 0 6.60567 0ZM6.60567 11.9919C3.63576 11.9919 1.21951 9.57561 1.21951 6.6057C1.21951 3.63579 3.63576 1.21951 6.60567 1.21951C9.57558 1.21951 11.9918 3.63576 11.9918 6.60567C11.9918 9.57558 9.57558 11.9919 6.60567 11.9919Z" />
			<path d="M14.822 13.9594L11.326 10.4634C11.0878 10.2252 10.702 10.2252 10.4638 10.4634C10.2256 10.7015 10.2256 11.0876 10.4638 11.3257L13.9597 14.8216C14.0788 14.9407 14.2348 15.0002 14.3908 15.0002C14.5467 15.0002 14.7028 14.9407 14.822 14.8216C15.0602 14.5836 15.0602 14.1974 14.822 13.9594Z" />
		</svg>

	)
}
IconSearch.propTypes = {
	className: PropTypes.string,

}
export default IconSearch