import React from 'react'
import PropTypes from 'prop-types'

const CirclePreloader = ({ className, width, height, fill }) => {
	return (
		<svg
			fill={fill || '#ec6608'}
			className={className}
			svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg"
			xlink="http://www.w3.org/1999/xlink" version="1.0"
			width={width || '24px'} height={height || '24px'}
			viewBox="0 0 128 128" space="preserve"><g><path d="M64 9.75A54.25 54.25 0 0 0 9.75 64H0a64 64 0 0 1 128 0h-9.75A54.25 54.25 0 0 0 64 9.75z" /><animateTransform
				attributeName="transform" type="rotate"
				from="0 64 64" to="360 64 64"
				dur="800ms" repeatCount="indefinite"></animateTransform></g></svg>

	)

}

CirclePreloader.propTypes = {
	className: PropTypes.string,
	width: PropTypes.string,
	height: PropTypes.string,
	fill: PropTypes.string,
}

export default CirclePreloader
