/**
* Method of recursively finding an element in an array
* @param {array} array the array in which to find the element
* @param {number} id value to find
* @returns {object} returns the found object
*/
const findItemToArrayRecursive = (arr, params, value) => {
	return arr.reduce((a, item) => {
		if (a) return a
		if (item[params] == value) return item
		if (item.children) return findItemToArrayRecursive(item.children, params, value)
	}, null)
}

export default findItemToArrayRecursive