import {
	OPEN_SEARCH,
	CLOSE_SEARCH
} from '../types/searchTypes'

export function openSearch() {
	return {
		type: OPEN_SEARCH
	}
}
export function closeSearch() {
	return {
		type: CLOSE_SEARCH
	}
}