import { takeEvery, put, call } from 'redux-saga/effects'

import ApiService from 'services/ApiService'

import {
	REQUEST_CHANGE_PRODUCT_TO_FOLDERS,
	REQUEST_GET_FOLDER_LIST,
	REQUEST_DELETE_FOLDER,
	REQUEST_CHANGE_FOLDER,
	REQUEST_ADD_FOLDER,

	CHANGE_PRODUCT_TO_FOLDERS,
	GET_FOLDER_LIST,
	DELETE_FOLDER,
	CHANGE_FOLDER,
	ADD_FOLDER
} from '../types/favoriteTypes'

import { REQUEST_FAILED } from '../types/errorTypes'

export function* WatcherFavoriteSaga() {
	yield takeEvery(REQUEST_CHANGE_PRODUCT_TO_FOLDERS, WorkerChangeProductToFolders)
	yield takeEvery(REQUEST_GET_FOLDER_LIST, WorkerFetchFolderList)
	yield takeEvery(REQUEST_CHANGE_FOLDER, WorkerChangeFolder)
	yield takeEvery(REQUEST_DELETE_FOLDER, WorkerDeleteFolder)
	yield takeEvery(REQUEST_ADD_FOLDER, WorkerAddFolder)
}

function* WorkerFetchFolderList() {
	try {

		const result = yield call(fetchFolderList)
		yield put({ type: GET_FOLDER_LIST, payload: { folders: result.data }})

	} catch (error) {

		yield put({ type: REQUEST_FAILED, payload: { message: error.response.data.message || 'Непредвиденная ошибка' } })

	}
}

function* WorkerAddFolder({ payload: {payload, reject: callback} }) {
	try {

		const result = yield call(addFolder, payload.name)
		yield put({ type: ADD_FOLDER, payload: { id: result.data.id, name: payload.name }})

	} catch (error) {

		yield put({ type: REQUEST_FAILED, payload: { createdAt: Date.now(), message: error.response.data.message || 'Непредвиденная ошибка' } })
		callback()

	}
}

function* WorkerChangeFolder({ payload: {payload, reject: callback} }) {
	try {
		yield call(changeFolder, payload)
		yield put({ type: CHANGE_FOLDER, payload})

	} catch (error) {

		yield put({ type: REQUEST_FAILED, payload: { createdAt: Date.now(), message: error.response.data.message || 'Непредвиденная ошибка' } })
		callback()

	}
}

function* WorkerDeleteFolder({ payload: {payload, reject: callback} }) {
	try {
		yield call(deleteFolder, payload.element_id)
		yield put({ type: DELETE_FOLDER, payload: { id: payload.element_id }})

	} catch (error) {

		yield put({ type: REQUEST_FAILED, payload: { createdAt: Date.now(), message: error.response.data.message || 'Непредвиденная ошибка' } })
		callback()

	}
}

function* WorkerChangeProductToFolders({ payload: {payload, reject: callback} }) {
	try {
		
		yield call(changeProductsToFolder, payload)
		yield put({ 
			type: CHANGE_PRODUCT_TO_FOLDERS,
			payload
		})

	} catch (error) {

		yield put({ type: REQUEST_FAILED, payload: { createdAt: Date.now(), message: error.response.data.message || 'Непредвиденная ошибка' } })
		callback()

	}
}

const fetchFolderList = async () => {
	return await ApiService.request('favorities/list', {})
}

const addFolder = async (name) => {
	return await ApiService.request('favorities/list', {
		data: { name },
		method: 'POST'
	})
}

const changeFolder = async (params) => {
	return await ApiService.request('favorities/list', {
		data: params,
		method: 'PUT'
	})
}

const deleteFolder = async (element_id) => {
	return await ApiService.request('favorities/list', {
		data: { element_id },
		method: 'DELETE'
	})
}

const changeProductsToFolder = async (params) => {
	return await ApiService.request('favorities/list/product', {
		data: params,
		method: 'POST'
	})
}