import React from 'react'
import PropTypes from 'prop-types'

const ActionIcon = ({ circleFill, pathFill }) => {
	return (
		<svg
			width="24" height="24"
			viewBox="0 0 24 24" fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<circle
				cx="12" cy="12"
				r="12" fill={circleFill || '#EDF3FA'} />
			<path d="M15.4219 10.8852V7C15.0848 7 7.39024 7 7 7V15.3789H12.6659C13.2231 16.3911 14.1636 17.302 15.0996 18C16.3996 17.0312 18 15.3971 18 13.7676C18 12.2772 16.8701 11.046 15.4219 10.8852ZM11.877 7.64453V9.02357L10.5879 9.45326V7.64453H11.877ZM7.64453 14.7344V7.64453H9.94336V10.3475L12.5215 9.48813V7.64453H14.7773V10.8852C13.3292 11.046 12.1992 12.2772 12.1992 13.7676C12.1992 14.0956 12.2645 14.4201 12.3746 14.7344H7.64453ZM15.0996 17.2218C14.5208 16.7408 12.8438 15.2216 12.8438 13.7676C12.8438 12.5237 13.8557 11.5117 15.0996 11.5117C16.3435 11.5117 17.3555 12.5237 17.3555 13.7676C17.3555 15.2217 15.6783 16.741 15.0996 17.2218Z" fill={pathFill || '#0256B1'} />
			<path d="M15.0996 12.1562C14.2111 12.1562 13.4883 12.8791 13.4883 13.7676C13.4883 14.6561 14.2111 15.3789 15.0996 15.3789C15.9881 15.3789 16.7109 14.6561 16.7109 13.7676C16.7109 12.8791 15.9881 12.1562 15.0996 12.1562ZM15.0996 14.7344C14.5665 14.7344 14.1328 14.3007 14.1328 13.7676C14.1328 13.2345 14.5665 12.8008 15.0996 12.8008C15.6327 12.8008 16.0664 13.2345 16.0664 13.7676C16.0664 14.3007 15.6327 14.7344 15.0996 14.7344Z" fill={pathFill || '#0256B1'} />
			<path d="M8.33203 12.1562H10.9102V12.8008H8.33203V12.1562Z" fill={pathFill || '#0256B1'} />
			<path d="M8.33203 13.4453H9.62109V14.0898H8.33203V13.4453Z" fill={pathFill || '#0256B1'} />
		</svg>
	)

}

ActionIcon.propTypes = {
	circleFill: PropTypes.string,
	pathFill: PropTypes.string,
}

export default ActionIcon
