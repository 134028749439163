import React from 'react'
import PropTypes from 'prop-types'

import 'scss/_vars.scss'

const IconBasket = ({ className, onClick }) => {
	return (
		<svg
			className={className} onClick={onClick}
			width="28" height="25"
			viewBox="0 0 28 25" xmlns="http://www.w3.org/2000/svg">
			<path d="M9.02132 16.4065H9.0226L9.0258 16.4063H23.8985C24.2646 16.4063 24.5866 16.1634 24.6872 15.8113L27.9684 4.32694C28.0391 4.07935 27.9896 3.81318 27.8347 3.60767C27.6796 3.40217 27.4371 3.28126 27.1797 3.28126H7.12947L6.54307 0.642366C6.45954 0.267029 6.12672 0 5.7422 0H0.820314C0.367219 0 0 0.367219 0 0.820314C0 1.27341 0.367219 1.64063 0.820314 1.64063H5.08424C5.18806 2.10825 7.89039 14.269 8.04591 14.9686C7.17411 15.3476 6.56251 16.2168 6.56251 17.2266C6.56251 18.5835 7.66652 19.6875 9.02345 19.6875H23.8985C24.3516 19.6875 24.7188 19.3203 24.7188 18.8672C24.7188 18.4141 24.3516 18.0469 23.8985 18.0469H9.02345C8.57121 18.0469 8.20314 17.6788 8.20314 17.2266C8.20314 16.775 8.56993 16.4076 9.02132 16.4065Z" />
			<path d="M8.20312 22.1484C8.20312 23.5054 9.30713 24.6094 10.6641 24.6094C12.021 24.6094 13.125 23.5054 13.125 22.1484C13.125 20.7915 12.021 19.6875 10.6641 19.6875C9.30713 19.6875 8.20312 20.7915 8.20312 22.1484ZM10.6641 21.3281C11.1163 21.3281 11.4844 21.6962 11.4844 22.1484C11.4844 22.6007 11.1163 22.9688 10.6641 22.9688C10.2118 22.9688 9.84375 22.6007 9.84375 22.1484C9.84375 21.6962 10.2118 21.3281 10.6641 21.3281Z" />
			<path d="M19.7969 22.1484C19.7969 23.5054 20.9009 24.6094 22.2578 24.6094C23.6148 24.6094 24.7188 23.5054 24.7188 22.1484C24.7188 20.7915 23.6148 19.6875 22.2578 19.6875C20.9009 19.6875 19.7969 20.7915 19.7969 22.1484ZM22.2578 21.3281C22.7101 21.3281 23.0781 21.6962 23.0781 22.1484C23.0781 22.6007 22.7101 22.9688 22.2578 22.9688C21.8056 22.9688 21.4375 22.6007 21.4375 22.1484C21.4375 21.6962 21.8056 21.3281 22.2578 21.3281Z" />
		</svg>
	)

}

IconBasket.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,

}

export default IconBasket