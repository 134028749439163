import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { Parser } from 'html-to-react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { useFetch } from 'hooks/useFetch'
import BasketService from 'services/BasketService'
import LocalStorageService from 'services/LocalStorageService'
import useDispatchSaga from 'hooks/useDispatchSaga'
import { priceFormatter } from 'helpers'

import AvailabilityInfo from 'components/AvailabilityInfo/AvailabilityInfo'
import SwitchQuantity from 'components/SwitchQuantity/SwitchQuantity'
import Title from 'components/ui/Title/Title'
import Button from 'components/ui/Button/Button'

import IconAddToCart from 'assets/icons/IconAddToCart'
import IconFavorite from 'assets/icons/IconFavorite'
import IconBox from 'assets/icons/IconBox'
import CirclePreloader from 'assets/preloaders/Circle'
import IconDelete from 'assets/icons/IconDelete'

import './product-table.scss'
import RestsSkeleton from 'components/ui/skeletons/RestsSkeleton'
import ModalAddToFavorite from 'components/Modals/AddToFavorite'

const ProductsTable = ({ sectionId, products, title, inAvailability, filterProduct, isSearch = false, clearSearchResult }) => {

	const basketProducts = useSelector(state => state.basket.basket)

	const { dispatchSaga } = useDispatchSaga()
	const history = useHistory()
	const htmlToReactParser = new Parser()
	const { request } = useFetch()

	const localStorage = LocalStorageService.get()

	const [showPrices, setShowPrices] = useState(localStorage.productCatalogPricesView)
	const [items, setItems] = useState([])

	const [productAddToBasketId, setProductAddToBasketId] = useState('')
	const [actualRests, setActualRests] = useState(null)
	const [isLoadingRests, setIsLoadingRests] = useState(false)
	const [topTableHeadCell, setTopTableHeadCell] = useState(0)

	const favoriteInfoInitial = {
		openModal: false,
		product: null,
		folderIds: []
	}
	const [favoriteInfo, setFavoriteInfo] = useState(favoriteInfoInitial)
	const [localFavoritesState, setLocalFavoritesState] = useState(products.map(item => {
		return {
			id: item.id,
			folderIds: item.in_favorities
		}
	}))
	
	const handleScroll = () => {

		const isHorizontalScroll = document.scrollingElement && document.scrollingElement.scrollWidth > document.scrollingElement.clientWidth

		if (!isHorizontalScroll) {

			setTopTableHeadCell(100)
		} else {
			setTopTableHeadCell(0)
		}

	}

	const changeQuantity = (changeItem) => {

		const newItems = items.map(item => {
			return changeItem.id === item.id ? changeItem : item
		})
		setItems(newItems)
	}

	const setShowPricesHandler = (priceType) => {

		const newObj = { ...showPrices, [priceType]: !showPrices[priceType] }
		setShowPrices(newObj)
		LocalStorageService.set({ ...localStorage, productCatalogPricesView: newObj })

	}

	const addToBasket = (item, productKeyInBasket) => {

		setProductAddToBasketId(item.id)

		BasketService.addToBasket(
			basketProducts[productKeyInBasket],
			{ ...item, price: item.price.base },
			dispatchSaga,
			() => setProductAddToBasketId('')
		)

	}

	const countPriceColumn = Object.values(showPrices).filter(item => item === true).length

	useEffect(async () => {

		if (items.length === 0 && products.length > 0) {
			setItems(products.map(item => {
				return { ...item, quantity: 1 }
			}))

			const uuids = products.map(product => product.xml_id)

			const { data } = await request(
				'catalog/rests',
				{ uuids },
				{},
				'GET'
			)

			setActualRests(data)
		}

	}, [products])

	useEffect(() => {

		if (actualRests) {

			const actualProducts = items.map(product => {
				let actualProductRests = actualRests[product.id]
				return actualProductRests ? { ...product, availability: actualProductRests } : product
			})

			setItems(actualProducts)
			setIsLoadingRests(true)
		}

	}, [actualRests])

	useEffect(() => {

		if (productAddToBasketId.length > 0) {
			setProductAddToBasketId('')
		}

	}, [basketProducts])

	useEffect(() => {
		handleScroll()
		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', () => handleScroll)
		}

	}, [])

	const changeProductToFavoriteHandler = (params) => {
		setLocalFavoritesState(prevState => {
			return prevState.map(prevItem => {
				return prevItem.id === params.product_id ? 
					{ 
						...prevItem,
						folderIds: params.element_id
					}
					:
					prevItem
			})
		})
	}

	return (
		<div className="product-table">
			<div className="product-table__top">
				<div className="product-table__title">
					{title &&
						<Title
							content={title}
							type="h1"
							className="product-table__titleText"
						/>

					}
					{isSearch &&
						<div className="search-elements__clear" onClick={clearSearchResult}>
							<IconDelete className="search-elements__clearIcon" />
						</div>
					}
				</div>

				{
					<div className="product-table__selectionDataDisplay">
						<Button
							text="Цена"
							className={classNames('product-table__button', showPrices.base && 'product-table__button--active')}
							callback={() => setShowPricesHandler('base')}
						/>
						<Button
							text="Розничная цена"
							className={classNames('product-table__button', showPrices.retail && 'product-table__button--active')}
							callback={() => setShowPricesHandler('retail')}
						/>
						<Button
							text="Только в наличии"
							className={classNames('product-table__button product-table__button--last', inAvailability && 'product-table__button--active')}
							callback={() => filterProduct(!inAvailability)}
						/> 
						
					</div>
				}

			</div>
			{
				products.length > 0 ?
					<>
						<table className="table product-table__table">
							<thead className="table__head">
								<tr className="table__row">
									<td
										className='table__cell table__cell--head table__cell--fixed'
										style={{ top: topTableHeadCell }}
									>
									</td>
									<td
										className='table__cell table__cell--head table__cell--fixed product-table__tableHeadСell--vendorCode'
										style={{ top: topTableHeadCell }}
									>
										Артикул
									</td>
									<td
										className={
											classNames(
												'table__cell table__cell--head table__cell--fixed product-table__tableHeadСell--name',
												countPriceColumn === 0 && 'product-table__tableHeadСell--nameWithoutPrice',
												countPriceColumn === 1 && 'product-table__tableHeadСell--nameLeastOnePrice'
											)
										}
										style={{ top: topTableHeadCell }}
									>
										Наименование
									</td>
									<td
										className='table__cell table__cell--head table__cell--fixed product-table__tableHeadСell--availability'
										style={{ top: topTableHeadCell }}
									>
										Наличие
									</td>
									{
										showPrices.base &&
										<td
											className='table__cell table__cell--head table__cell--fixed product-table__tableHeadСell--basePrice'
											style={{ top: topTableHeadCell }}
										>
											Цена
										</td>
									}
									{
										showPrices.retail &&
										<td
											className='table__cell table__cell--head table__cell--fixed product-table__tableHeadСell--retailPrice'
											style={{ top: topTableHeadCell }}
										>
											Розн. цена
										</td>
									}
									<td
										className='table__cell table__cell--head table__cell--fixed product-table__tableHeadСell--quantity'
										style={{ top: topTableHeadCell }}
									>
										Количество
									</td>
									{
										showPrices.base &&
										<td
											className='table__cell table__cell--head table__cell--fixed product-table__tableHeadСell--summ'
											style={{ top: topTableHeadCell }}
										>
											Сумма
										</td>
									}
									<td
										className="table__cell table__cell--fixed"
										style={{ top: topTableHeadCell }}
									></td>
								</tr>

							</thead>
							<tbody className="table__body">
								{items.map(item => {
									
									const productKeyInBasket = basketProducts.findIndex(product => product.product_id === item.id)
									const isFavorites = localFavoritesState.find(itemFavorite => itemFavorite.id === item.id).folderIds.length > 0
									return (
										<tr className="table__row" key={item.id}>
											<td className="table__cell product-table__tableBodyСell product-table__tableBodyСell--favorite">
												<IconFavorite
													className={
														classNames(
															'product-table__favorite',
															isFavorites && 'product-table__favorite--active'
														)
													}
													onClick={() => (setFavoriteInfo({
														openModal: true,
														productId: item.id,
														folderIds: item.in_favorities
													}))}
												/>
											</td>
											<td className="table__cell product-table__tableBodyСell'">
									
												{item.vendorCode}
											</td>
											<td
												className={
													classNames(
														'table__cell product-table__tableBodyСell product-table__tableBodyСell--name',
														countPriceColumn === 0 && 'product-table__tableBodyСell--nameWithoutPrice',
														countPriceColumn === 1 && 'product-table__tableBodyСell--nameLeastOnePrice'
													)
												}
												onClick={() => history.push(`/catalog/${sectionId ? sectionId : item.sectionId}/${item.id}/`)}
											>
												<div className="product-table__toElement">{htmlToReactParser.parse(item.name)}</div>
											</td>
											<td className="table__cell product-table__tableBodyСell">
												{
													isLoadingRests ?
														<>
															{`${item.availability.reduce((prevValue, currentEl) => {
																return prevValue + currentEl.free
															}, 0)}/${item.availability.reduce((prevValue, currentEl) => {
																return prevValue + currentEl.quantity
															}, 0)}`}
															<AvailabilityInfo stores={item.availability} />
														</> :
														<RestsSkeleton />
												}

											</td>
											{
												showPrices.base &&
												<td className="table__cell product-table__tableBodyСell">
													{priceFormatter(item.price.base)}
												</td>
											}
											{
												showPrices.retail &&
												<td className="table__cell product-table__tableBodyСell">
													{priceFormatter(item.price.retail)}
												</td>
											}
											<td className="table__cell product-table__tableBodyСell">
												<SwitchQuantity
													item={item}
													callback={(item) => changeQuantity(item)} />
											</td>
											{
												showPrices.base &&
												<td className="table__cell product-table__tableBodyСell">
													{priceFormatter(item.quantity * item.price.base)}
												</td>
											}
											<td className="table__cell product-table__tableBodyСell product-table__tableBodyСell--addToBasket">
												<div
													className={
														classNames(
															'product-table__addToBasket',
															productKeyInBasket !== -1 && 'product-table__addToBasket--toBasket',
															item.price.base === 0 && 'product-table__addToBasket--disabled'
														)
													}
													onClick={() => item.price.base !== 0 && productAddToBasketId.length === 0 && addToBasket(item, productKeyInBasket)}
												>
													{
														productAddToBasketId === item.id ?
															<CirclePreloader
																className='product-table__addToBasketPreloader'
																width="14px"
																height="14px"
															/>
															:

															<IconAddToCart className='product-table__addToBasketIcon' />

													}

												</div>
											</td>
										</tr>
									)
								})}
							</tbody>
						</table>
						{
							favoriteInfo.openModal &&
								<ModalAddToFavorite
									productId={favoriteInfo.productId}
									folderIds={localFavoritesState.find(item => item.id === favoriteInfo.productId).folderIds}
									onClose={() => setFavoriteInfo(favoriteInfoInitial)}
									callback={params => changeProductToFavoriteHandler(params)}
								/>
						}
					</>
					:
					products.length === 0 && title.length > 0 &&
					<div className="catalog-section__empty">
						<IconBox />
						<div className="catalog-section__emptyText">Раздел пуст</div>
					</div>
			}

		</div>

	)
}
ProductsTable.propTypes = {
	sectionId: PropTypes.string,
	products: PropTypes.array.isRequired,
	title: PropTypes.string,
	inAvailability: PropTypes.bool,
	filterProduct: PropTypes.func,
	isSearch: PropTypes.bool,
	clearSearchResult: PropTypes.func
}
export default React.memo(ProductsTable)
