import React from 'react'
import PropTypes from 'prop-types'

const IconDelete = ({ className, onClick }) => {
	return (
		<svg
			className={className} onClick={onClick}
			viewBox="0 0 10 10" fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path d="M5.55245 5.00001L9.88558 0.666858C10.0381 0.5143 10.0381 0.266957 9.88558 0.114419C9.73302 -0.03812 9.48568 -0.0381395 9.33314 0.114419L4.99999 4.44757L0.666858 0.114419C0.5143 -0.0381395 0.266957 -0.0381395 0.114419 0.114419C-0.03812 0.266977 -0.0381395 0.51432 0.114419 0.666858L4.44755 4.99999L0.114419 9.33314C-0.0381395 9.4857 -0.0381395 9.73304 0.114419 9.88558C0.190688 9.96185 0.290668 9.99998 0.390648 9.99998C0.490628 9.99998 0.590589 9.96185 0.666878 9.88558L4.99999 5.55245L9.33312 9.88558C9.40939 9.96185 9.50937 9.99998 9.60935 9.99998C9.70933 9.99998 9.80929 9.96185 9.88558 9.88558C10.0381 9.73302 10.0381 9.48568 9.88558 9.33314L5.55245 5.00001Z" />
		</svg>
	)

}

IconDelete.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,

}

export default IconDelete