import {
	GET_FOLDER_LIST,
	CHANGE_PRODUCT_TO_FOLDERS,
	CHANGE_FOLDER,
	DELETE_FOLDER,
	ADD_FOLDER
} from '../types/favoriteTypes'

const initialState = {
	folders: [],
	favoriteLoading: false,
}

export const favoriteReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_FOLDER_LIST:
			return { ...state, folders: action.payload.folders, favoriteLoading: true }
		case ADD_FOLDER:
			return { ...state, folders: [...state.folders, {...action.payload, count: 0}] }
		case DELETE_FOLDER:
			return { ...state, folders: state.folders.filter(item => item.id !== action.payload.id) }
		case CHANGE_FOLDER:
			return { ...state, folders: state.folders.map(item => item.id === action.payload.element_id ? {...item, ...action.payload.params} : item) }
		case CHANGE_PRODUCT_TO_FOLDERS:
			return { ...state, folders: state.folders.map(item => {
				const findFolder = action.payload.list.find(itemList => itemList.id === item.id)
				return {
					...item,
					count: findFolder ? findFolder.count : item.count
				}
			})}
		default: return state
	}
}
