import React, { useRef } from 'react'
import PropTypes from 'prop-types'

import { useOnClickOutside } from 'hooks/useOnClickOutside'

import CirclePreloader from 'assets/preloaders/Circle'
import Title from 'components/ui/Title/Title'

import IconSuccess from 'assets/icons/IconSuccess'

import './style.scss'

const PriceListPopup = ({ isGenerationDoc, setIsGenerationDoc, priceListURL }) => {

	const popupRef = useRef(null)

	useOnClickOutside([popupRef], () => {

		if (isGenerationDoc !== 'loading') {
			setIsGenerationDoc('')
		}
	})

	return (
		<div className="price-list-popup">
			<div ref={popupRef} className="price-list-popup__container">

				{isGenerationDoc === 'loading' &&
					<>
						<CirclePreloader className="price-list-popup__preloader" />
						<Title className="price-list-popup__title" content="Формирование прайс-листа..." />
						<div className="price-list-popup__info">Это может занять до 30 секунд. Пожалуйста, подождите и не закрывайте окно.</div>
					</>
				}

				{isGenerationDoc === 'success' &&
					<>
						<IconSuccess className="price-list-popup__iconSuccess" />
						<Title className="price-list-popup__title" content="Прайс-лист сформирован" />
						<div className="price-list-popup__info">Спасибо за ожидание</div>
						<a
							href={priceListURL}
							onClick={() => setIsGenerationDoc('')}
							className="price-list-popup__download"
						>
							Скачать прайс-лист
						</a>
					</>
				}

			</div>
		</div>

	)
}

PriceListPopup.propTypes = {
	isGenerationDoc: PropTypes.string,
	setIsGenerationDoc: PropTypes.func,
	priceListURL: PropTypes.string,
}

export default PriceListPopup