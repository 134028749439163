import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './title.scss'

const Title = ({ content, type, style, className = null }) => {
	return (
		<div
			className={classNames('title', type && `title--${type}`, className && className)}
			style={style}>{content}</div>
	)
}

Title.propTypes = {
	content: PropTypes.string.isRequired,
	type: PropTypes.string,
	style: PropTypes.object,
	className: PropTypes.string,
}
export default Title
