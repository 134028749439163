import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import MapService from 'services/MapService'
import MapSkeleton from '../skeletons/MapSkeleton'

import './map.scss'

const Map = ({ options, onLoadMap, mapID, style }) => {
	const { ...opt } = options
	const [mapLoading, setMapLoading] = useState(false)

	useEffect(async () => {

		if (Object.keys(options).length !== 0 && options.constructor === Object) {

			const mapObj = await MapService.initMap(opt, mapID)
			setMapLoading(true)
			if (onLoadMap) {
				onLoadMap(mapObj)
			}

		}

	}, [options])

	return (
		<>

			<div
				className='map map__contact'
				style={style}
				id={mapID}
			>{!mapLoading && <MapSkeleton />}</div>

		</>

	)
}

Map.propTypes = {
	mapID: PropTypes.string.isRequired,
	options: PropTypes.object.isRequired,
	onLoadMap: PropTypes.func,
	style: PropTypes.object,

}

export default Map