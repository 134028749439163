import React, { useState, useEffect, useContext } from 'react'
import { useDispatch } from 'react-redux'

import { REQUEST_FAILED } from 'redux/types/errorTypes'

import { useFetch } from 'hooks/useFetch'

import { AuthContext } from 'context/AuthContext'

import TokenService from 'services/TokenService'

import Input from 'components/ui/Input/Input'
import Title from 'components/ui/Title/Title'
import Button from 'components/ui/Button/Button'
import Select from 'components/ui/Select/Select'
import IconArrow from 'assets/icons/IconArrow'
import CirclePreloader from 'assets/preloaders/Circle'

import './counterparty-form.scss'

const CounterpartyForm = () => {

	const auth = useContext(AuthContext)
	const dispatch = useDispatch()
	const { request } = useFetch()

	const [counterpartys, setCounterpartys] = useState(null)
	const [agreements, setAgreements] = useState(null)
	const [agreementSelectKey, setAgreementSelectKey] = useState(null)
	const [agreementDefault, setAgreementDefault] = useState(null)
	const [preloaderVisible, setPreloaderVisible] = useState(false)

	useEffect(() => {
		const {
			id,
			agreements,
			children,
			login,
			name,
		} = auth.user

		setCounterpartys([
			{
				id: id,
				name: name,
				agreements: agreements,
				login: login,
			},
			...children
		])

		setAgreements(agreements)
		setAgreementDefault(getFirstAgreement(agreements))
		setAgreementSelectKey(name)

	}, [])

	const getFirstAgreement = agreements => {
		const [firstAgreement] = agreements
		return firstAgreement ? firstAgreement.name : null
	}

	const submitForm = async () => {
		try {

			const selectCounterparty = counterpartys.find(counterpartys => counterpartys.selected !== false)
			const selectAgreement = agreements.find(agreement => agreement.selected !== false)

			if (selectCounterparty.id !== auth.user.id) {

				setPreloaderVisible(true)
				const response = await request('user/change', { login: selectCounterparty.login })
				TokenService.setTokenStorage(response.data)

			}

			auth.login(
				{
					login: selectCounterparty.login,
					agreement: selectAgreement.xmlId
				}
			)

		} catch (error) {
			dispatch({ type: REQUEST_FAILED, payload: { message: error.message || 'Что-то пошло не так' } })
			setPreloaderVisible(false)
		}

	}

	const changeCounterparty = (item) => {

		setAgreementSelectKey(item.name)
		setAgreementDefault(getFirstAgreement(item.agreements))
		setAgreements(item.agreements)

	}

	return (
		<div className="counterparty-form">

			<Title
				content="Компания"
				style={{ marginBottom: '19px' }} />
			{counterpartys &&
				<Select
					className="counterparty-form__select"
					items={counterpartys}
					onChange={(item) => changeCounterparty(item)}
					defaultValue={auth.user.name}

				/>
			}

			{agreements && agreementDefault ?
				agreements.length > 1 ?
					<Select
						key={agreementSelectKey}
						className="counterparty-form__select counterparty-form__select--agreement"
						items={agreements}
						onChange={() => true}
						defaultValue={agreementDefault}
					/> :
					<Input
						key={agreementSelectKey}
						defaultValue={agreementDefault} disabled
						className="counterparty-form__input" />
				:
				<div className="counterparty-form__notAgreement">Нет соглашения, пожалуйста выберете другой аккаунт</div>
			}

			<div className="counterparty-form__auth">
				<div className="counterparty-form__authLogout" onClick={() => auth.logout()}>
					<IconArrow className="counterparty-form__authLogoutIcon" />
					Назад
				</div>
				<Button
					className="counterparty-form__authLogin"
					callback={!preloaderVisible && submitForm}
					text={
						!preloaderVisible ?
							'Войти' :
							<CirclePreloader className="counterparty-form__preloader" />
					}
					disabled={agreements && agreements.length === 0}
				/>
			</div>

		</div>
	)

}

export default CounterpartyForm