import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { dateFormatter } from 'helpers'

import Select from 'components/ui/Select/Select'
import InputCalendar from 'components/ui/InputCalendar/InputCalendar'

const OrderFilter = ({ filterParams, setFilterParams, statutes, loadingData, setDetailOrdersId, setDetailOrders }) => {

	const [statusOrder, setStatusOrder] = useState([])
	const [statusOrderDefault, setStatusOrderDefault] = useState({})

	useEffect(() => {

		const statusAll = { name: 'Все', code: 'All' }
		const statutesArray = Object.values(statutes)

		setStatusOrder([statusAll, ...statutesArray])
		setStatusOrderDefault(statusAll)

	}, [statutes])

	return (
		<div className="order-list__filter">
			<InputCalendar
				placeholder='от'
				className="order-list__filterCalendar order-list__filterCalendar--from"
				callback={date => setFilterParams(prevState => ({ ...prevState, date: { ...prevState.date, from: date } }))}
				defaultDate={filterParams.date.from}
				maxDate={filterParams.date.to}
				disabled={!loadingData}
				setDetailOrdersId={setDetailOrdersId}
				setDetailOrders={setDetailOrders}
			/>
			<div className="order-list__filterCalendarBetweenDelimiter"></div>
			<InputCalendar
				placeholder='до'
				className="order-list__filterCalendar order-list__filterCalendar--to"
				callback={date => setFilterParams(prevState => ({ ...prevState, date: { ...prevState.date, to: date } }))}
				defaultDate={filterParams.date.to}
				minDate={filterParams.date.from}
				disabled={!loadingData}
				setDetailOrdersId={setDetailOrdersId}
				setDetailOrders={setDetailOrders}
			/>
			{
				statusOrder.length > 0 &&
				<Select
					className={classNames('order-list__filterSelect', !loadingData && 'order-list__filterSelect--disabled')}
					items={statusOrder}
					onChange={item => setFilterParams(prevState => ({ ...prevState, status: item.code }))}
					defaultValue={statusOrderDefault.name}
					disabled={!loadingData}
					setDetailOrdersId={setDetailOrdersId}
					setDetailOrders={setDetailOrders}
				/>
			}
		</div>

	)
}

OrderFilter.propTypes = {
	filterParams: PropTypes.object.isRequired,
	setFilterParams: PropTypes.func.isRequired,
	statutes: PropTypes.array,
	loadingData: PropTypes.bool,
	setDetailOrdersId: PropTypes.func,
	setDetailOrders: PropTypes.func,
}

export default OrderFilter