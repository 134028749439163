import React, { useRef, useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { dateFormatter, maskDate } from 'helpers'
import { useOnClickOutside } from 'hooks/useOnClickOutside'

import CalendarCustom from 'components/ui/CalendarCustom/CalendarCustom'
import Input from 'components/ui/Input/Input'
import IconCalendar from 'assets/icons/IconCalendar'

import './input-calendar.scss'

const InputCalendar = (
	{
		placeholder,
		className,
		classNamePlaceholder,
		callback,
		defaultDate,
		minDate,
		maxDate,
		disabled = false,
		dateDisabled,
		expression,
		setDetailOrdersId,
		setDetailOrders,
	}
) => {

	const [showCalendar, setShowCalendar] = useState(false)
	const [date, setDate] = useState('')
	const [inputValue, setInputValue] = useState('')

	const inputCalendarRef = useRef(null)
	const calendarRef = useRef(null)

	useOnClickOutside([inputCalendarRef], () => {
		setShowCalendar(false)

	})

	const onSelectDateCalendar = (selectDate) => {
		setDate(selectDate)
		setInputValue(dateFormatter(selectDate))
		setShowCalendar(false)
	}

	useEffect(() => {

		const exp = expression === 'delivery' ?
			date && defaultDate instanceof Date && date.toLocaleDateString() !== defaultDate.toLocaleDateString() :
			date && date instanceof Date && date.toLocaleDateString() !== defaultDate

		if (exp) {

			if (minDate) {

				const rcfInputValue = new Date(inputValue.split('.')
					.reverse()
					.join('-')
				)

				if (rcfInputValue.getTime() < minDate.getTime()) {
					setInputValue(dateFormatter(minDate))
					callback(minDate)
					setDetailOrdersId && setDetailOrdersId([])
					setDetailOrders && setDetailOrders([])
				} else {

					if (dateFormatter(date) !== dateFormatter(defaultDate)) {
						callback(rcfInputValue)
						setDetailOrdersId && setDetailOrdersId([])
						setDetailOrders && setDetailOrders([])
					}
				}
			} else {

				setInputValue(dateFormatter(date || defaultDate))
				if (dateFormatter(date) !== dateFormatter(defaultDate)) {
					callback(date || defaultDate)
					setDetailOrdersId && setDetailOrdersId([])
					setDetailOrders && setDetailOrders([])
				}
			}

		}

	}, [date])

	useEffect(() => {

		const defaultDateS = defaultDate ? defaultDate.setHours(0) : 0
		const inputValueS = inputValue ? new Date(inputValue.split('.')
			.reverse()
			.join('-')
		).setHours(0) : 0

		if (defaultDate && inputValue.length === 0 || defaultDateS !== inputValueS) {

			setInputValue(dateFormatter(defaultDate))

		}

	}, [defaultDate])

	useEffect(() => {

		if (minDate === defaultDate) {

			setInputValue(dateFormatter(minDate))

		}

	}, [minDate])

	const regExpDate = (value) => {
		var reg = /^(?:(?:31(\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
		return reg.test(value) ? value : ''
	}

	return (
		<div
			className={classNames('input-calendar', className)}
			onClick={() => !disabled && setShowCalendar(true)}
			ref={inputCalendarRef}
		>
			<Input
				disabled={disabled}
				className="input-calendar__control"
				classNamePlaceholder={classNamePlaceholder}
				value={inputValue}
				onBlur={() => {
					const inputDate = regExpDate(inputValue)
					if (inputDate.length > 0) {
						setDate(
							new Date(inputDate.split('.')
								.reverse()
								.join('-')
							)
						)
					} else {
						setInputValue(dateFormatter(defaultDate) || '')
						setDate(defaultDate || '')
						if (defaultDate !== '' && (dateFormatter(date) !== dateFormatter(defaultDate))) {
							callback(defaultDate || '')
							setDetailOrdersId && setDetailOrdersId([])
							setDetailOrders && setDetailOrders([])

						}

					}
				}}
				onChange={(e) => {
					setShowCalendar(false)
					setInputValue(maskDate(e.target.value))
				}}
				placeholderVisible={placeholder}
			>
				<IconCalendar
					className="input-calendar__controlIcon input-calendar__controlIcon--calendar"
				/>

			</Input>
			{showCalendar &&
				<CalendarCustom
					className="input-calendar__сalendar"
					defaultDate={defaultDate}
					minDate={minDate}
					maxDate={maxDate}
					onSelect={(date) => onSelectDateCalendar(date)}
					ref={calendarRef}
					dateDisabled={dateDisabled}
				/>
			}
		</div>

	)
}

InputCalendar.propTypes = {
	placeholder: PropTypes.string,
	className: PropTypes.string,
	classNamePlaceholder: PropTypes.string,
	callback: PropTypes.func,
	disabled: PropTypes.bool,
	minDate: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	maxDate: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	defaultDate: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	dateDisabled: PropTypes.array,
	expression: PropTypes.string,
	setDetailOrdersId: PropTypes.func,
	setDetailOrders: PropTypes.func,
}

export default InputCalendar