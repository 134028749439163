import { takeEvery, put, call } from 'redux-saga/effects'

import ApiService from 'services/ApiService'

import {
	REQUEST_GET_BASKET,
	REQUEST_DELETE_BASKET_ITEM,
	REQUEST_DELETE_BASKET_All,
	REQUEST_CHANGE_BASKET_ITEM,
	REQUEST_ADD_BASKET_ITEM,
	GET_BASKET,
	GET_BASKET_FAILED,
	DELETE_BASKET_ITEM,
	DELETE_BASKET_All,
	CHANGE_BASKET_ITEM,
	ADD_BASKET_ITEM
} from '../types/basketTypes'

import { REQUEST_FAILED } from '../types/errorTypes'

export function* WatcherBasketSaga() {
	yield takeEvery(REQUEST_GET_BASKET, WorkerFetchBasket)
	yield takeEvery(REQUEST_DELETE_BASKET_ITEM, WorkerDeleteBasketItem)
	yield takeEvery(REQUEST_DELETE_BASKET_All, WorkerDeleteBasketAll)
	yield takeEvery(REQUEST_CHANGE_BASKET_ITEM, WorkerChangeBasketItem)
	yield takeEvery(REQUEST_ADD_BASKET_ITEM, WorkerAddBasketItem)
}

function* WorkerFetchBasket() {
	try {

		const result = yield call(fetchBasket)
		yield put({ type: GET_BASKET, payload: { list: result.data.list, total: result.data.total } })

	} catch (error) {

		yield put({ type: REQUEST_FAILED, payload: { message: error.response.data.message || 'Непредвиденная ошибка' } })
		yield put({ type: GET_BASKET_FAILED, payload: true })

	}
}

function* WorkerDeleteBasketItem({ payload: { payload, callback } }) {
	try {

		const response = yield call(deleteBasketItem, payload)
		const result = { itemId: payload, total: response.data.total }
		yield put({ type: DELETE_BASKET_ITEM, payload: result })

	} catch (error) {

		yield call(setError, { message: error.response.data.message, callback })

	}
}

function* WorkerDeleteBasketAll({ payload: { payload, callback } }) {
	try {

		const response = yield call(deleteBasketAll)
		const result = { basket: payload, total: response.data.total }
		yield put({ type: DELETE_BASKET_All, payload: result })

	} catch (error) {

		yield call(setError, { message: error.response.data.message, callback })

	}
}

function* WorkerAddBasketItem({ payload: { payload, reject } }) {

	try {

		const response = yield call(addBasketItem, payload)
		const result = { item: { ...payload, id: response.data.id, product_id: payload.id }, total: response.data.total }

		yield put({ type: ADD_BASKET_ITEM, payload: result })

	} catch (error) {

		yield call(setError, { message: error.response.data.message, callback: reject })

	}

}

function* WorkerChangeBasketItem({ payload: { payload, reject: callback } }) {

	try {

		const response = yield call(changeBasketItem, payload)
		const result = { item: { ...payload.data, quantity: payload.quantity, id: payload.id }, total: response.data.total }
		yield put({ type: CHANGE_BASKET_ITEM, payload: result })

	} catch (error) {

		yield call(setError, { message: error.response.data.message, callback })

	}
}

function* setError({ message, callback }) {

	yield put({ type: REQUEST_FAILED, payload: { createdAt: Date.now(), message: message || 'Непредвиденная ошибка' } })
	callback()

}

const fetchBasket = async () => {

	return await ApiService.request('basket', {})
}

const deleteBasketItem = async (id) => {
	return await ApiService.request(
		'basket',
		{
			data: { id },
			method: 'DELETE',
		}
	)
}

const deleteBasketAll = async () => {

	return await ApiService.request(
		'basket/clear',
		{
			data: {},
			method: 'DELETE',
		}
	)
}

const changeBasketItem = async ({ id, quantity }) => {

	return await ApiService.request(
		'basket',
		{
			data: { id, quantity },
			method: 'PUT'
		}
	)

}

const addBasketItem = async ({ id, quantity }) => {
	return await ApiService.request(
		'basket',
		{
			data: { id, quantity },
			method: 'POST'
		}
	)
}
