import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './checkbox.scss'

const Checkbox = ({ className, type, label = null, ...rest }) => {
	return (
		<div className={classNames('checkbox', className)} >
			<input
				className={classNames('checkbox__control', type === 'main' && 'checkbox__control--main', type === 'second' && 'checkbox__control--second')}
				type="checkbox"
				{...rest}
			/>
			<label htmlFor={rest.id} className="checkbox__text">{label}</label>

		</div>
	)
}

Checkbox.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	rest: PropTypes.object,
	type: PropTypes.string,

}

export default Checkbox
