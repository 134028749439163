import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import { REQUEST_FAILED } from 'redux/types/errorTypes'

import { useFetch } from 'hooks/useFetch'

import { dateFormatter } from 'helpers'

import ModalActionForUploadedFile from 'components/ModalActionForUploadedFile/ModalActionForUploadedFile'
import Button from 'components/ui/Button/Button'
import Select from 'components/ui/Select/Select'
import Title from 'components/ui/Title/Title'
import InputCalendar from 'components/ui/InputCalendar/InputCalendar'

import './style.scss'

const Reconciliation = ({ invisible }) => {

	const dispatch = useDispatch()

	const modalActionForUploadedFileRef = useRef(null)

	const [selectYear, setSelectYear] = useState((new Date()).getFullYear())
	const [activePeriod, setActivePeriod] = useState('')
	const [periods, setPeriods] = useState({})
	const [selectItems, setSelectItems] = useState([])
	const [loadingDoc, setLoadingDoc] = useState(false)
	const [docURL, setDocURL] = useState('')

	const [date, setDate] = useState(
		{
			from: '',
			to: '',
		}
	)

	const { request } = useFetch()

	useEffect(() => {

		const years = []
		const startYears = 2016
		const endYears = (new Date()).getFullYear()
		const yearsArray = Array.from({ length: endYears + 1 - startYears }, (_, i) => { return { name: i + startYears } })
		for (let year of yearsArray) {

			let yearObj = {
				name: year.name,
				value: {
					from: new Date(year.name, 0, 1),
					to: new Date(year.name, 11, 31),
				}
			}

			if (year.name === endYears) {

				setDate(yearObj.value)
				yearObj.selected = true
			}
			years.push(yearObj)

		}
		setSelectItems(years)
	}, [])

	const getLastDayOfMonth = (year, month) => {
		let date = new Date(year, month + 1, 0)
		return date.getDate()
	}

	useEffect(() => {
		const periodsObj = {
			year: [
				{
					name: 'Год',
					date: {
						from: new Date(selectYear, 0, 1),
						to: new Date(selectYear, 11, getLastDayOfMonth(selectYear, 11)),
					}
				}
			],
			halfYear: [
				{
					name: '1 полугодие',
					date: {
						from: new Date(selectYear, 0, 1),
						to: new Date(selectYear, 5, getLastDayOfMonth(selectYear, 5)),
					}
				},
				{
					name: '2 полугодие',
					date: {
						from: new Date(selectYear, 6, 1),
						to: new Date(selectYear, 11, getLastDayOfMonth(selectYear, 11)),
					}
				}
			],
			quarterYear: [
				{
					name: '1 квартал',
					date: {
						from: new Date(selectYear, 0, 1),
						to: new Date(selectYear, 2, getLastDayOfMonth(selectYear, 2)),
					}
				},
				{
					name: '2 квартал',
					date: {
						from: new Date(selectYear, 3, 1),
						to: new Date(selectYear, 5, getLastDayOfMonth(selectYear, 5)),
					}
				},
				{
					name: '3 квартал',
					date: {
						from: new Date(selectYear, 6, 1),
						to: new Date(selectYear, 8, getLastDayOfMonth(selectYear, 8)),
					}
				},
				{
					name: '4 квартал',
					date: {
						from: new Date(selectYear, 9, 1),
						to: new Date(selectYear, 11, getLastDayOfMonth(selectYear, 11)),
					}
				}
			],
			months: [
				{
					name: 'Январь',
					date: {
						from: new Date(selectYear, 0, 1),
						to: new Date(selectYear, 0, getLastDayOfMonth(selectYear, 0)),
					}
				},
				{
					name: 'Февраль',
					date: {
						from: new Date(selectYear, 1, 1),
						to: new Date(selectYear, 1, getLastDayOfMonth(selectYear, 1)),
					}
				},
				{
					name: 'Март',
					date: {
						from: new Date(selectYear, 2, 1),
						to: new Date(selectYear, 2, getLastDayOfMonth(selectYear, 2)),
					}
				},
				{
					name: 'Апрель',
					date: {
						from: new Date(selectYear, 3, 1),
						to: new Date(selectYear, 3, getLastDayOfMonth(selectYear, 3)),
					}
				},
				{
					name: 'Май',
					date: {
						from: new Date(selectYear, 4, 1),
						to: new Date(selectYear, 4, getLastDayOfMonth(selectYear, 4)),
					}
				},
				{
					name: 'Июнь',
					date: {
						from: new Date(selectYear, 5, 1),
						to: new Date(selectYear, 5, getLastDayOfMonth(selectYear, 5)),
					}
				},
				{
					name: 'Июль',
					date: {
						from: new Date(selectYear, 6, 1),
						to: new Date(selectYear, 6, getLastDayOfMonth(selectYear, 6)),
					}
				},
				{
					name: 'Август',
					date: {
						from: new Date(selectYear, 7, 1),
						to: new Date(selectYear, 7, getLastDayOfMonth(selectYear, 7)),
					}
				},
				{
					name: 'Сентябрь',
					date: {
						from: new Date(selectYear, 8, 1),
						to: new Date(selectYear, 8, getLastDayOfMonth(selectYear, 8)),
					}
				},
				{
					name: 'Октябрь',
					date: {
						from: new Date(selectYear, 9, 1),
						to: new Date(selectYear, 9, getLastDayOfMonth(selectYear, 9)),
					}
				},
				{
					name: 'Ноябрь',
					date: {
						from: new Date(selectYear, 10, 1),
						to: new Date(selectYear, 10, getLastDayOfMonth(selectYear, 10)),
					}
				},
				{
					name: 'Декабрь',
					date: {
						from: new Date(selectYear, 11, 1),
						to: new Date(selectYear, 11, getLastDayOfMonth(selectYear, 11)),
					}
				},
			]
		}

		setPeriods(periodsObj)
		setActivePeriod(periodsObj.year[0].name)
	}, [selectYear])

	const periodsRender = (periodName, periods, className = '') => {

		const items = []
		for (let [key, period] of Object.entries(periods)) {
			items.push(
				<Button
					key={key}
					className={
						classNames(
							'reconciliation__button',
							periods.length - 1 == key ? 'reconciliation__button--mr0' : '',
							className,
							activePeriod === period.name && 'reconciliation__button--active'
						)
					}
					text={period.name}
					callback={() => changeActivePeriod(periodName, key, period.name)}
				/>
			)
		}
		return items
	}
	const changeActivePeriod = (periodName, periodKey, name) => {
		setActivePeriod(name)
		setDate(periods[periodName][periodKey].date)
	}

	const onSelectDateCalendar = (name, date) => {
		setDate(prevState => ({ ...prevState, [name]: date }))
		setActivePeriod('')
	}

	const setYear = ({ value, name }) => {
		setDate(value)
		setSelectYear(name)
	}

	const generateURL = async () => {

		const { from, to } = date

		const reverseDateFrom = dateFormatter(from).split('.')
			.reverse()
			.join('-')
		const reverseDateTo = dateFormatter(to).split('.')
			.reverse()
			.join('-')

		try {
			setLoadingDoc(true)

			const response = await request(
				'document',
				{
					'type': 'actofrec',
					'params[start_date]': reverseDateFrom,
					'params[finish_date]': reverseDateTo
				}
			)

			const [document] = response.data.list
			setDocURL(document.url)

		} catch (error) {
			dispatch({ type: REQUEST_FAILED, payload: { createdAt: Date.now(), message: error.message || 'Ошибка загрузки данных' } })
		} finally {
			setLoadingDoc(false)
		}

	}

	return (
		<div className={classNames('container container__content', invisible && 'invisible')}>

			<div className="reconciliation">
				<div className="reconciliation__datePreset">
					<Title
						content="Акт сверки"
						style={{ marginBottom: '30px', fontSize: '20px' }} />
					<div className="reconciliation__year">
						<Select
							placeholder='Год'
							items={selectItems}
							onChange={(item) => setYear(item)}
							defaultValue={selectYear}
						/>
					</div>
					{Object.keys(periods).length !== 0 &&
						<>
							<div className="reconciliation__reportingPeriod">
								<div className="reconciliation__reportingYear">
									{periodsRender('year', periods.year)}
								</div>
								<div className="reconciliation__reportingHalfYear">
									{periodsRender('halfYear', periods.halfYear)}
								</div>
								<div className="reconciliation__reportingQuarterYear">
									{periodsRender('quarterYear', periods.quarterYear)}
								</div>
							</div>
							<div className="reconciliation__reportingMonths">
								{periodsRender('months', periods.months, 'reconciliation__button--mb10')}
							</div>
						</>
					}
				</div>
				<div className="reconciliation__datePeriod">
					<div className="reconciliation__datePeriodTitle">
						Период
					</div>
					<InputCalendar
						placeholder='от'
						className="reconciliation__datePeriodInputCalendar"
						callback={date => onSelectDateCalendar('from', date)}
						maxDate={date.to}
						defaultDate={date.from}
					/>
					<InputCalendar
						placeholder='до'
						className="reconciliation__datePeriodInputCalendar reconciliation__datePeriodInputCalendar--last"
						callback={date => onSelectDateCalendar('to', date)}
						minDate={date.from}
						defaultDate={date.to}
					/>

					<Button
						className={classNames('reconciliation__download', loadingDoc && 'reconciliation__download--loading')}
						text={!loadingDoc ? 'Сформировать акт' : 'Формируем'}
						callback={() => !loadingDoc && generateURL()}
					/>
					{
						docURL.length > 0 &&
						<ModalActionForUploadedFile
							ref={modalActionForUploadedFileRef}
							docUrl={docURL}
							className="reconciliation__actionWithDocs"
							closeModal={() => setDocURL('')}
						/>
					}

				</div>

			</div>
		</div>
	)
}
Reconciliation.propTypes = {
	invisible: PropTypes.bool,
}
export default Reconciliation
