import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { useDebounce } from 'hooks/useDebounce'

import Input from 'components/ui/Input/Input'
import CirclePreloader from 'assets/preloaders/Circle'

import IconIncrease from 'assets/icons/IconIncrease'
import IconDecrease from 'assets/icons/IconDecrease'

import './style.scss'

export default function SwitchQuantity({ item, className, theme = '', callback, debounced = false, processing = false }) {

	const [quantity, setQuantity] = useState(item.quantity)

	const debouncedQuantity = useDebounce(quantity, 500)

	const maxQuantity = 999999

	const changeQuantity = (value) => {
		callback({ ...item, quantity: value < maxQuantity ? value : maxQuantity })
	}

	const valueInput = debounced ? quantity : item.quantity

	useEffect(() => {

		if (debouncedQuantity !== item.quantity && debouncedQuantity.toString().length > 0) {
			changeQuantity(+debouncedQuantity)
		}

	}, [debouncedQuantity])

	useEffect(() => {

		if (quantity !== item.quantity && debounced) {
			setQuantity(item.quantity)
		}

	}, [item.quantity, processing])

	return (
		<div className={classNames('switch-quantity', className, theme.length > 0 && `switch-quantity__${theme}`)}>
			<div
				className={classNames('switch-quantity__control switch-quantity__control--decrease', processing || valueInput === 1 && 'switch-quantity__control--disabled')}
				onClick={() => !processing && valueInput !== 1 && changeQuantity(+valueInput - 1)}>
				<IconDecrease className="switch-quantity__icon" />
			</div>

			<Input
				className="switch-quantity__input"
				onChange={(e) => {
					const value = Number(e.target.value.replace(/\D/g, ''))
					return debounced ? setQuantity(value !== 0 ? value : '') : changeQuantity(value)

				}}
				onBlur={(e) => e.target.value.length === 0 && changeQuantity(1)}
				value={valueInput}
			>
				{
					processing &&
					<div className="switch-quantity__preloader">
						<CirclePreloader className="switch-quantity__preloaderIcon" />
					</div>
				}

			</Input>

			<div
				className={classNames('switch-quantity__control switch-quantity__control--increase', processing || valueInput >= maxQuantity && 'switch-quantity__control--disabled')}
				onClick={() => !processing && valueInput < maxQuantity && changeQuantity(+valueInput + 1)}
			>
				<IconIncrease className="switch-quantity__icon" />
			</div>
		</div >
	)

}

SwitchQuantity.propTypes = {
	item: PropTypes.object.isRequired,
	className: PropTypes.string,
	callback: PropTypes.func,
	debounced: PropTypes.bool,
	processing: PropTypes.bool,
	theme: PropTypes.string,
	// disabled: PropTypes.bool,

}