import React, { useRef, forwardRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { CSSTransition } from 'react-transition-group'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import HeaderFavorite from 'components/HeaderFavorite/HeaderFavorite'
import HeaderBasket from 'components/HeaderBasket/HeaderBasket'
import HeaderUser from 'components/HeaderUser/HeaderUser'
import BasketFixed from 'components/BasketFixed/BasketFixed'
import Input from 'components/ui/Input/Input'
import IconSearch from 'assets/icons/IconSearch'
import IconDelete from 'assets/icons/IconDelete'

import './header-main.scss'

const HeaderMain = forwardRef(({ isSticky, searchQuery, clearSearchResult }, ref) => {

	const history = useHistory()

	const [showHeaderBasketNew, setShowHeaderBasket] = useState(false)

	const basketFixed = useRef(null)
	const basketHeader = useRef(null)

	const onChangeSearchQuery = (queryValue) => {
		history.push({
			search: `?query=${queryValue}`
		})
	}

	return (
		<>
			<div className='header-main' ref={ref}>
				<div className={classNames('header-main__content', isSticky && 'header-main__content--fixed')}>
					<div className={classNames('header-main__contentContainer', isSticky && 'header-main__contentContainer--fixed')}>
						<div className="header-main__wrap header-main__wrap--flexGrow1">
							<div
								className="header-main__button"
								onClick={() => history.push('/catalog/')}
							>
								Каталог
							</div>
							<Input
								className="header-main__searchInput"
								placeholder="Поиск"
								value={searchQuery}
								onChange={e => onChangeSearchQuery(e.target.value)}
							>
								<IconSearch className="header-main__icon header-main__icon--search" />
								{searchQuery.length > 0 &&
									<div className="header-main__searchInputClear" onClick={clearSearchResult}>
										<div className="header-main__searchInputClearText">Очистить поиск</div>
										<IconDelete className="header-main__searchInputClearIcon" />
									</div>
								}
							</Input>
						</div>
						<div className="header-main__wrap header-main__wrap--flexEnd">
							<HeaderFavorite />
							<HeaderBasket
								ref={basketHeader}
								onOpen={() => setShowHeaderBasket(true)}
							/>
							<HeaderUser />
						</div>

					</div>

					<CSSTransition
						in={showHeaderBasketNew}
						timeout={250}
						classNames={{
							enter: 'header-main__basket--enter',
							enterActive: 'header-main__basket--enterActive',
							exit: 'header-main__basket--exit',
							exitActive: 'header-main__basket--exitActive',
						}}
						unmountOnExit
						nodeRef={basketFixed}
					>
						<BasketFixed
							ref={basketFixed}
							refBasketHeader={basketHeader}
							className='header-main__basket'
							onClose={() => setShowHeaderBasket(false)}
						/>
					</CSSTransition>
				</div>
			</div>
		</>
	)
})

HeaderMain.displayName = 'HeaderMain'
HeaderMain.propTypes = {
	isSticky: PropTypes.bool,
	searchQuery: PropTypes.string,
	clearSearchResult: PropTypes.func,
}
export default HeaderMain