import React, { useState, useEffect, createRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'
import { Parser } from 'html-to-react'

import IconArrow from 'assets/icons/IconArrow'

import './accordion.scss'

const Accordion = ({ items }) => {

	const htmlToReactParser = new Parser()

	const [openAccordion, setOpenAccordion] = useState([])
	const [elRefs, setElRefs] = useState([])

	useEffect(() => {
		setElRefs(() => (
			Array(items.length).fill()
				.map(() => createRef())
		))
	}, [items])

	const toggleAccordion = code => {

		const isOpen = openAccordion.find(i => i === code)
		if (!isOpen) {
			setOpenAccordion(prev => {
				return [...prev, code]
			})
		} else {
			setOpenAccordion(prev => {
				return prev.filter(i => i !== code)
			})
		}
	}

	return (
		<div className="accordion">
			{
				items.filter(item => item.content.length > 0).map(({ code, name, content }, index) => {
					return (
						<div
							className="accordion__item" key={code}
							onClick={() => toggleAccordion(code)}>
							<div className="accordion__itemHeader">
								<div className="accordion__itemHeaderTitle">{name}</div>
								<IconArrow />
							</div>
							<CSSTransition
								in={openAccordion.find(i => i === code) ? true : false}
								timeout={500}
								classNames={{
									enter: 'accordion__itemPanel--enter',
									enterActive: 'accordion__itemPanel--enterActive',
									exit: 'accordion__itemPanel--exit',
									exitActive: 'accordion__itemPanel--exitActive',
								}}
								unmountOnExit
								nodeRef={elRefs[index]}
							>
								<div className="accordion__itemPanel" ref={elRefs[index]}>
									<div className="accordion__itemPanelContent">
										{
											code === 'properties' ?
												content.map((item, key) => {
													return (
														<div key={key} className="accordion__itemPanelContentItem">
															<div key={key} className="accordion__itemPanelContentItemName">
																{item.name}:
															</div>
															<div key={key} className="accordion__itemPanelContentItemValue">
																{item.value}
															</div>
														</div>
													)
												})
												:
												htmlToReactParser.parse(content)
										}
									</div>
								</div>
							</CSSTransition>
						</div>
					)
				})
			}
		</div>
	)
}

Accordion.propTypes = {
	items: PropTypes.array.isRequired
}
export default Accordion