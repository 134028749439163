import { createStore, compose, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { rootReducer } from './reducers/rootReducer'
import rootSaga from './saga/rootSaga'

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const saga = createSagaMiddleware()

const store = createStore(
	rootReducer,
	composeEnhancers(applyMiddleware(saga))
)

saga.run(rootSaga)

export default store