import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import IconArrow from 'assets/icons/IconArrow'
import IconAvatar from 'assets/icons/IconAvatar'

import './user-counterparty.scss'

const UserCounterparty = ({ currentAgreement, user, toggleList, changeUser, changeAgreement, open, activeCounterparty }) => {

	const issetChildren = user.children && user.children.length > 0 && currentAgreement

	return (
		<div
			className={classNames('user-counterparty', !currentAgreement && 'user-counterparty--other')}
			onClick={() => !currentAgreement ? changeUser(user) : undefined}
		>
			<IconAvatar className="user-counterparty__icon" />
			<div className="user-counterparty__info">
				<div
					className={classNames('user-counterparty__name', !activeCounterparty.length > 0 && 'user-counterparty__name--disabled')}
					onClick={issetChildren ? toggleList : undefined}
				>
					<div className="user-counterparty__nameText" title={user.name}>{user.name}</div>
					{issetChildren && <IconArrow className={open ? 'user-counterparty__openIconArrow' : ''} />}
				</div>
				{currentAgreement &&
					<div
						className={classNames('user-counterparty__agreement', user.agreements.length <= 1 && 'user-counterparty__agreement--disabled')}
						onClick={() => user.agreements.length > 1 && changeAgreement(user)}
						title={currentAgreement}
					>
						{currentAgreement}
					</div>

				}
			</div>
		</div >
	)
}

UserCounterparty.propTypes = {
	currentAgreement: PropTypes.string,
	user: PropTypes.object.isRequired,
	toggleList: PropTypes.func,
	changeUser: PropTypes.func,
	changeAgreement: PropTypes.func,
	open: PropTypes.bool,
	activeCounterparty: PropTypes.array
}
export default UserCounterparty