import React, { useRef, useImperativeHandle } from 'react'
import classNames from 'classnames'
import Calendar from 'react-calendar'
import PropTypes from 'prop-types'

import IconArrow from 'assets/icons/IconArrow'

import './calendar.scss'

const CalendarCustom = React.forwardRef(({ className, onSelect, defaultDate, minDate, maxDate, dateDisabled }, ref) => {
	const currentDate = defaultDate || new Date()

	const calendarRef = useRef()
	useImperativeHandle(ref, () => ({
		getCalendarNode: () => {
			return calendarRef.current
		}
	}))

	const renderNavLabel = (month, year) => {
		return (
			<>
				<div className="react-calendar__navigation__label__labelMonth">{month}</div>
				{year && <div className="react-calendar__navigation__label__labelYear">{year}</div>}

			</>
		)

	}

	return (
		<div
			className="react-calendar-wrap"
			ref={calendarRef}>
			<Calendar

				locale='ru-RU'
				nextLabel={<IconArrow className="react-calendar__navigation__arrow__arrowIcon react-calendar__navigation__arrow__arrowIcon--next" />}
				next2Label={null}
				prevLabel={<IconArrow className="react-calendar__navigation__arrow__arrowIcon react-calendar__navigation__arrow__arrowIcon--prev" />}
				prev2Label={null}
				navigationLabel={({ label }) => {
					const [month, year] = label.split(' ')
					return renderNavLabel(month, year)
				}}
				className={classNames('input', className)}
				minDetail='month'
				minDate={minDate || null}
				maxDate={maxDate || null}
				onChange={value => onSelect(value)}
				value={currentDate}

				tileDisabled={({ date }) => dateDisabled && dateDisabled.indexOf(date.getDay()) != -1}
			/>
		</div>

	)
})
CalendarCustom.propTypes = {
	defaultDate: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	className: PropTypes.string,
	onSelect: PropTypes.func,
	minDate: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	maxDate: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	dateDisabled: PropTypes.array,

}
CalendarCustom.displayName = 'CalendarCustom'
export default CalendarCustom