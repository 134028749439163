import React from 'react'
import PropTypes from 'prop-types'

import 'scss/_vars.scss'

const IconNoPhoto = ({ className }) => {
	return (
		<svg
			className={className} width="200"
			height="130" viewBox="0 0 200 130"
			fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M79.7953 35.5217L33.3174 114.987L50.8614 124.985L97.3393 45.5195L79.7953 35.5217Z" fill="#F7F7F7" />
			<path d="M94.7133 109.989H30.3841C28.6075 109.99 26.8615 109.53 25.3222 108.655C23.783 107.779 22.5047 106.519 21.6157 105C20.7266 103.482 20.2583 101.759 20.2579 100.006C20.2575 98.2519 20.7249 96.5291 21.6132 95.0104L56.7016 35.0169C57.5878 33.4932 58.8656 32.227 60.406 31.3467C61.9464 30.4663 63.695 30.0028 65.475 30.0028C67.255 30.0028 69.0032 30.4663 70.5436 31.3467C72.084 32.227 73.3621 33.4932 74.2483 35.0169L118.113 110.004L129.807 130H153.221L91.795 24.9813C89.1277 20.4214 85.2913 16.6349 80.6716 14.0023C76.0519 11.3697 70.8117 9.98373 65.4774 9.98373C60.1431 9.98373 54.9026 11.3697 50.2829 14.0023C45.6632 16.6349 41.8272 20.4214 39.1599 24.9813L4.07146 84.9748C1.4049 89.5337 0.000684401 94.7051 2.50135e-07 99.9693C-0.000683901 105.234 1.40207 110.405 4.06745 114.965C6.73283 119.524 10.567 123.312 15.1846 125.945C19.8022 128.579 25.0408 129.967 30.3739 129.97H106.403L94.7133 109.989Z" fill="#F7F7F7" />
			<path d="M123.966 0H100.577L176.606 129.983H200L123.966 0Z" fill="#F7F7F7" />
		</svg>
	)

}

IconNoPhoto.propTypes = {
	className: PropTypes.string
}

export default IconNoPhoto