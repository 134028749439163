import { combineReducers } from 'redux'

import { favoriteReducer } from './favoriteReducer'
import { basketReducer } from './basketReducer'
import { errorReducer } from './errorReducer'
import { searchReducer } from './searchReducer'
import { appReducer } from './appReducer'

export const rootReducer = combineReducers({
	basket: basketReducer,
	errors: errorReducer,
	search: searchReducer,
	app: appReducer,
	favorite: favoriteReducer,
})