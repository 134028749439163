import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import FavoriteService from 'services/FavoriteService'
import useDispatchSaga from 'hooks/useDispatchSaga'

import FavoriteNewFolder from 'components/FavoriteNewFolder/FavoriteNewFolder'
import DeleteFavoriteFolder from 'components/Modals/DeleteFavoriteFolder'

import Input from 'components/ui/Input/Input'

import { 
	IconCheck,
	IconClose,
	IconPencil,
	IconTrash
} from 'assets/icons/favorite'

import './favorite-folders.scss'

const FavoriteFolders = ({ className, currentFolderId, setCurrentFolderId }) => {

	const inputStateInitial = {
		id: '',
		value: ''
	}
	const folders = useSelector(state => state.favorite.folders)
	const { dispatchSaga } = useDispatchSaga()

	const [inputState, setInputState] = useState(inputStateInitial)
	const [modalDelete, setModalDelete] = useState({
		open: false,
		folderId: null
	})
	const [processFolderId, setProcessFolderId] = useState(null)
	const [hoverId, setHoverId] = useState(null)

	const changeHandler = (folderId) => {
		setProcessFolderId(folderId)
		FavoriteService.changeFolder({
			element_id: folderId,
			params: {
				name: inputState.value
			}
		}, dispatchSaga, () => setProcessFolderId(null))
	}

	useEffect(() => {

		if(processFolderId){
			setProcessFolderId(null)
			setInputState(inputStateInitial)
			
		}

	}, [folders])
	
	return (
		<>
			<div className={classNames('favorite-folders', className)}>
				<div className='favorite-folders__title'>
					Списки избранных товаров
				</div>
				<div 
					className={
						classNames(
							'favorite-folders__items',
							folders.length > 0 && 'favorite-folders__items--notEmpty'
						)
					}
				>
					{
						folders.map(item => {
							return (
								<div 
									className={classNames(
										'favorite-folders__item',
										processFolderId === item.id && 'favorite-folders__item--process',
										currentFolderId === item.id && 'favorite-folders__item--active',
										inputState.id === item.id && 'favorite-folders__item--change',
									)}
									key={item.id}
									onMouseEnter={() => setHoverId(item.id)}
									onMouseLeave={() => setHoverId(null)}
									onClick={() => setCurrentFolderId(item.id)}
								>
									{
										inputState.id === item.id ?
											<Input
												className='favorite-folders__input'
												value={inputState.value}
												onChange={(e) => setInputState(prevState => {
													return {
														...prevState,
														value: e.target.value
													}
												})} /> : 
											<div className='favorite-folders__itemName'>{item.name}</div>
									}

									{
										item.count > 0 && <span className='favorite-folders__itemCount'>{item.count}</span>
									}

									{
										(inputState.id === item.id || hoverId === item.id )&& 
											<div className='favorite-folders__itemActions'>
												{
													inputState.id === item.id ?
														<>
															<IconCheck 
																className={
																	classNames(
																		'favorite-folders__icon',
																		'favorite-folders__icon--first'
																	)
																}
																onClick={() => changeHandler(item.id)}
															/>
															<IconClose
																className='favorite-folders__icon'
																onClick={() => setInputState(inputStateInitial)}
															/>
														</>
														:
														<>
															<IconPencil 
																className={classNames('favorite-folders__icon', 'favorite-folders__icon--first')}
																onClick={() => setInputState({
																	id: item.id,
																	value: item.name
																})}
															/>
															<IconTrash
																className='favorite-folders__icon'
																onClick={() => setModalDelete({
																	open: true,
																	folderId: item.id
																})}
															/>
														</>
												}
												
											</div>
									}
								</div>
							)
						})
					}
					
				</div>
				<FavoriteNewFolder
					classNameAddFolder={classNames(
						'favorite-folders__add',
						folders.length === 0 && 'favorite-folders__add--empty',
					)}
					classNameNewFolder='favorite-folders__new'
				/>
			</div>
			{
				modalDelete.open && 
				<DeleteFavoriteFolder
					folderId={modalDelete.folderId}
					onClose={() => setModalDelete({
						open: false
					})}
				/>
			}
		</>
	)
}

FavoriteFolders.propTypes = {
	className: PropTypes.string,
	currentFolderId: PropTypes.number,
	setCurrentFolderId: PropTypes.func
}

export default FavoriteFolders
