import React from 'react'
import PropTypes from 'prop-types'

const IconAddToCart = ({ className }) => {
	return (
		<svg
			className={className}
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="#FF7800"
			stroke="#FF7800" 
			xmlns="http://www.w3.org/2000/svg"
		>
			<circle 
				cx="14.0833"
				cy="16.25"
				r="1"
				fill="inherit"
				stroke="inherit"
				strokeWidth="1.16667"
				strokeLinejoin="round"
			/>
			<circle 
				cx="7.41683"
				cy="16.25"
				r="1"
				fill="inherit"
				stroke="inherit"
				strokeWidth="1.16667"
				strokeLinejoin="round"
			/>
			<path 
				d="M2.5 2.5L4.67785 2.7289L5.35194 4.99081M5.35194 4.99081L16.6667 5.32479L16.2172 10.4473L7.49675 11.8333L5.35194 4.99081Z"
				stroke="inherit"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)

}

IconAddToCart.propTypes = {
	className: PropTypes.string,
}

export default IconAddToCart
