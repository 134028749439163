import { useDispatch } from 'react-redux'

const useDispatchSaga = () => {

	const dispatch = useDispatch()

	const dispatchSaga = (dispatchType, payload, rejectCallback) => {

		new Promise((_, reject) => {
			dispatch({
				type: dispatchType,
				payload: {
					payload,
					reject
				},
			})

		}).catch(() => {
			rejectCallback()
		})
	}

	return { dispatchSaga }

}

export default useDispatchSaga