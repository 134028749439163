import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Radio from 'components/ui/Radio/Radio'

import './agreements-list.scss'

const AgreementsList = ({ items, currentCounterparty, currentItem, onSelect, layerShadow = null }) => {
	return (
		<div className="agreements-list">
			<div className="agreements-list__title">Выберите соглашение</div>
			{items.map((agreement, i) => {
				return <Radio
					key={agreement.xmlId}
					label={agreement.name}
					name='agreement'
					id={`agreement-${agreement.xmlId}`}
					defaultChecked={currentCounterparty ? currentItem === agreement.xmlId : i === 0}
					onClick={() => onSelect(agreement.xmlId)}
					className={classNames('agreements-list__radio', layerShadow && 'agreements-list__radio--zIndexNegative')}
				/>
			})}
		</div>
	)
}

AgreementsList.propTypes = {
	items: PropTypes.array,
	currentCounterparty: PropTypes.bool,
	currentItem: PropTypes.string,
	onSelect: PropTypes.func,
	layerShadow: PropTypes.bool,
}

export default AgreementsList