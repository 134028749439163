import { useState, useCallback, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useFetch } from 'hooks/useFetch'

import TokenService from 'services/TokenService'
import LocalStorageService from 'services/LocalStorageService'

export const useAuth = () => {

	const history = useHistory()

	const [token, setToken] = useState(null)
	const [agreement, setАgreement] = useState(null)
	const [user, setUser] = useState(null)
	const [notifications, setNotifications] = useState([])

	const { request: userRequest } = useFetch()
	const { request: siteNotice } = useFetch()

	const login = useCallback(async ({ login, agreement, productCatalogPricesView, inAvailability, inAvailabilityFavorite, failedCallback }) => {

		try {

			const tokenStorage = TokenService.getTokenStorage()
			const localStorage = LocalStorageService.get()

			if (!localStorage || localStorage.login !== login || !!localStorage.agreement || !agreement) {
				const responseUser = await userRequest('user/get', { 'login': login })
				setUser(responseUser.data)

			}

			LocalStorageService.set({
				login,
				agreement,
				productCatalogPricesView: productCatalogPricesView || {
					base: true,
					retail: false
				},
				inAvailability,
				inAvailabilityFavorite
			})

			setToken(tokenStorage.token)
			setАgreement(agreement || '')

		} catch (error) {

			failedCallback && failedCallback()
			logout()
			history.push('/')
		}

	}, [])

	const logout = useCallback(() => {

		setToken('')
		setUser('')
		setАgreement('')
		LocalStorageService.remove()
		TokenService.removeStorage()

	}, [])

	useEffect(async () => {

		if (user && agreement && agreement.length > 0) {
			const responseNotice = await siteNotice('main/notification')
			setNotifications(responseNotice.data.list)
		}
		
	}, [user, agreement])

	useEffect(() => {

		const tokenStorage = TokenService.getTokenStorage()
		const localStorage = LocalStorageService.get()

		if (tokenStorage && tokenStorage.token && localStorage) {
 
			login({
				login: localStorage.login,
				agreement: localStorage.agreement,
				productCatalogPricesView: localStorage.productCatalogPricesView,
				inAvailability: localStorage.inAvailability,
				inAvailabilityFavorite: localStorage.inAvailabilityFavorite
			})

		} else {

			setToken('')
			setАgreement('')
		}

	}, [login])

	useEffect(() => {

		const tokenStorage = TokenService.getTokenStorage()
		const localStorage = LocalStorageService.get()

		if (!tokenStorage && localStorage) {
			logout()
		}

	}, [token])

	return { token, login, logout, user, agreement, notifications, setNotifications, setUser }
}