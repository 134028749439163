/**
* Method for converting a number into a currency format
* @param {number} number number.
* @returns {parseFloat} returns a floating point number in currency format
*/
const dateFormatter = (date) => {

	if (date.length === 0) {
		return ''
	}

	const d = new Date(typeof date === 'object' ? date : date * 1000)
	let month = '' + (d.getMonth() + 1)
	let day = '' + d.getDate()
	let year = d.getFullYear()

	if (month.length < 2)
		month = '0' + month
	if (day.length < 2)
		day = '0' + day

	return [day, month, year].join('.')
}

export default dateFormatter