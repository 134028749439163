import React from 'react'
import PropTypes from 'prop-types'

import { useDocumentTitle } from 'hooks/useDocumentTitle'

import AuthPageContentBlock from 'components/AuthPageContentBlock'
import RegisterForm from 'components/RegisterForm'

import './style.scss'

const Register = ({ title }) => {

	useDocumentTitle(title)

	return (
		<div className="register">
			<AuthPageContentBlock />
			<RegisterForm />
		</div>
	)
}

Register.propTypes = {
	title: PropTypes.string,
}

export default Register