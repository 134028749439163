import React from 'react'
import PropTypes from 'prop-types'

const IconBox = ({ className }) => {
	return (
		<svg
			className={className} width="160"
			height="160" viewBox="0 0 160 160"
			fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M159.927 37.7206C159.927 37.1127 159.78 36.5049 159.486 35.9308C158.9 34.7826 157.762 34.0059 156.515 33.7695L81.7973 0.405234C80.5869 -0.135078 79.1563 -0.135078 77.9459 0.405234L2.49427 34.0735C1.02705 34.7151 0.0733608 36.0659 0 37.5855V37.6193C0 37.653 0 37.6868 0 37.7543V122.246C0 123.799 0.953691 125.217 2.49427 125.893L77.9459 159.561C77.9826 159.561 77.9826 159.561 78.0193 159.595C78.1293 159.629 78.2393 159.662 78.3494 159.73C78.3861 159.73 78.4227 159.764 78.4961 159.764C78.6061 159.797 78.7162 159.831 78.8262 159.865C78.8629 159.865 78.8996 159.899 78.9363 159.899C79.0463 159.932 79.193 159.932 79.3031 159.966C79.3398 159.966 79.3764 159.966 79.4131 159.966C79.5598 159.966 79.7432 160 79.89 160C80.0367 160 80.2201 160 80.3668 159.966C80.4035 159.966 80.4402 159.966 80.4768 159.966C80.5869 159.966 80.7336 159.932 80.8436 159.899C80.8803 159.899 80.917 159.865 80.9537 159.865C81.0637 159.831 81.1738 159.797 81.2838 159.764C81.3205 159.764 81.3572 159.73 81.4305 159.73C81.5406 159.696 81.6506 159.662 81.7607 159.595C81.7973 159.595 81.7973 159.595 81.834 159.561L157.506 125.791C159.01 125.116 160 123.698 160 122.144V37.8219C159.927 37.7881 159.927 37.7543 159.927 37.7206ZM79.8533 8.54369L145.291 37.7543L121.192 48.5268L55.7542 19.3162L79.8533 8.54369ZM79.8533 66.965L14.4154 37.7543L45.6671 23.8075L111.105 53.0182L79.8533 66.965ZM8.8033 44.2718L75.4516 74.0228V149.43L8.8033 119.679V44.2718ZM84.2549 149.43V74.0228L115.543 60.0422V79.7636C115.543 81.9924 117.524 83.816 119.945 83.816C122.366 83.816 124.347 81.9924 124.347 79.7636V56.0912L151.123 44.1368V119.544L84.2549 149.43Z" fill="#EFEFEF" />
		</svg>
	)
}

IconBox.propTypes = {
	className: PropTypes.string,

}

export default IconBox