import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { useFetch } from 'hooks/useFetch'

import LocalStorageService from 'services/LocalStorageService'

import { REQUEST_FAILED } from 'redux/types/errorTypes'

import { findItemToArrayRecursive } from 'helpers'

import PriceListSidebar from 'components/PriceList/PriceListSidebar'
import PriceListTree from 'components/PriceList/PriceListTree'
import Title from 'components/ui/Title/Title'
import Button from 'components/ui/Button/Button'
import LogoPreloader from 'assets/preloaders/LogoPreloader'

import './style.scss'

const PriceList = () => {

	const { request, error } = useFetch()
	const dispatch = useDispatch()

	const localStorage = LocalStorageService.get()

	const [sections, setSections] = useState([])
	const [sectionTree, setSectionTree] = useState([])
	const [isFinishLoading, setIsFinishLoading] = useState(false)
	const [priceListURL, setPriceListURL] = useState('')
	const [isSelectedCheckbox, setIsSelectedCheckbox] = useState(false)
	const [priceType, setPriceType] = useState([
		{
			name: 'Оптовые цены',
			code: 'base',
			value: true
		},
		{
			name: 'Розничные цены',
			code: 'retail',
			value: false
		}
	])

	const loadData = async () => {

		try {
			if (isFinishLoading) {
				setIsFinishLoading(false)
				setSectionTree([])
			}

			const response = await request('catalog/tree')
			setSections(recursiveSelected(response.data.tree, false))

		} catch (error) {

			dispatch({ type: REQUEST_FAILED, payload: { message: error.message } })

		} finally {

			setIsFinishLoading(true)

		}

	}

	useEffect(() => {

		loadData()

	}, [localStorage.login, localStorage.agreement])

	const loadingSectionTree = async (sectionId, sectionKey) => {

		try {

			let dataRequestItems = []

			if (!sections.find(section => section.id === sectionId).children) {
				const response = await request('catalog/tree', { 'sectionId': sectionId })
				const [data] = response.data
				dataRequestItems = data.items
			}

			const tree = sections.map((sectionItem, key) => key === sectionKey ?
				{ ...sectionItem, ...(dataRequestItems.length > 0 && { children: recursiveSelected(dataRequestItems, sectionItem.selected) }), open: true } :
				{ ...sectionItem, open: false }
			)

			setSections(tree)

			const openSection = sections.find(section => section.id === sectionId)

			setSectionTree(dataRequestItems.length > 0 ? recursiveSelected(dataRequestItems, openSection.selected) : openSection.children)

		} catch (error) {

			dispatch({ type: REQUEST_FAILED, payload: { message: error.message } })

		}

	}

	const recursiveSelected = (array, selected) => {

		const result = array.map(section => {

			if (section.children) {
				section.children = recursiveSelected(section.children, selected)
			}
			return { ...section, selected: selected }

		})

		return result
	}

	const recursiveSelectedTree = (array, id, selected, updChildren) => {

		const currentChildren = findItemToArrayRecursive(array, 'id', id).children

		let currentSection = {
			...findItemToArrayRecursive(array, 'id', id),
			selected: selected,
			...(
				updChildren ?
					{
						children:
							Object.values({
								...currentChildren,
								[findItemToArrayRecursive(array, 'id', updChildren.parent_id).children.findIndex(child => child.id === updChildren.id)]: updChildren
							})
					} :
					{
						...(
							currentChildren &&
							{
								children: Object.values(recursiveSelected(currentChildren, selected))
							}
						)

					}
			)
		}

		if (currentSection.children) {
			if (currentSection.children.filter(children => !children.selected).length === 0) {
				currentSection.selected = true
			} else {
				currentSection.selected = false
			}
		}

		if (currentSection.parent_id) {
			return recursiveSelectedTree(array, currentSection.parent_id, selected, currentSection)
		} else {
			return currentSection
		}

	}

	const changeSelectedSection = (parentId, id, selected) => {

		if (priceListURL.length > 0) {
			setPriceListURL('')
		}

		if (!parentId) {
			let updSection = []
			if (!id) {

				updSection = recursiveSelected(sections, selected)
				const openSection = updSection.find(section => section.open === true)

				if (openSection) {
					setSectionTree(openSection.children)
				}

			} else {

				updSection = sections.map(section => {
					return section.id === id ? { ...section, selected: selected, ...(section.children && { children: recursiveSelected(section.children, selected) }) } : section
				})

				const openSection = updSection.find(section => section.open === true)

				if (openSection) {
					setSectionTree(openSection.children)
				}

			}
			setSections(updSection)
		} else {

			const result = recursiveSelectedTree(sections, id, selected)
			const updSection = sections.map(section => section.id === result.id ? { ...result, children: result.children } : section)

			setSections(updSection)
			setSectionTree(result.children)
		}
	}

	const changeUnloadPrices = (name) => {
		setPriceType(priceType.map(price => price.name === name ? { ...price, value: !price.value } : price))
		setPriceListURL('')

	}

	useEffect(() => {
		findItemToArrayRecursive(sections, 'selected', true) ? setIsSelectedCheckbox(true) : setIsSelectedCheckbox(false)
	}, [sections])

	return (
		<div className="price-list">
			<Title
				content="Прайс-лист"
				type="h1"
				className="price-list__title"
			/>

			<div className="price-list__content">
				{
					isFinishLoading ?
						!error ?
							<>
								<PriceListSidebar
									sections={sections}
									setSectionTree={setSectionTree}
									isSelectedCheckbox={isSelectedCheckbox}
									changeSelectedSection={changeSelectedSection}
									loadingSectionTree={loadingSectionTree}
									priceType={priceType}
									priceListURL={priceListURL}
									setPriceListURL={setPriceListURL}
									changeUnloadPrices={changeUnloadPrices}
								/>
								<PriceListTree
									sectionTree={sectionTree}
									changeSelectedSection={changeSelectedSection}
									loadingSectionTree={loadingSectionTree}
								/>
							</> :
							<div className="price-list__error">Ошибка загрузки данных</div> :
						<div className="price-list__preloader"><LogoPreloader /></div>
				}

			</div>

		</div>
	)

}

export default PriceList