import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import FavoriteService from 'services/FavoriteService'
import useDispatchSaga from 'hooks/useDispatchSaga'

import FavoriteNewFolder from 'components/FavoriteNewFolder/FavoriteNewFolder'

import Checkbox from 'components/ui/Checkbox/Checkbox'
import Button from 'components/ui/Button/Button'
import Modal from 'components/ui/Modal'

import { IconClose } from 'assets/icons/favorite'

import './style.scss'

const ModalAddToFavorite = ({ onClose, productId, folderIds, callback }) => {

	const {folders} = useSelector(state => state.favorite)
	const { dispatchSaga } = useDispatchSaga()

	const [proccessFolderId, setProccessFolderId] = useState(false)
	const [localFavoritesState, setLocalFavoritesState] = useState([])
	const [showAddNewFolder, setShowAddNewFolder] = useState(false)
	const [allowChangeButton, setAllowChangeButton] = useState(false)
	
	const changeProductToFolderHandler = (params) => {

		setLocalFavoritesState(prevState => {
			return prevState.map(prevItem => {
				return prevItem.id === params.elementId ? { ...prevItem, checked: params.checked, action: params.action } : prevItem
			})
		})
		
	}

	const setInitialLocalFavoritesState = () => {
		setLocalFavoritesState(folders.map(folder => {
			return {
				...folder,
				checked: folderIds.includes(folder.id.toString()),
				action: null,
				initChecked: folderIds.includes(folder.id.toString()),
			}
		}))
	}

	const changeToFavorite = () => {
		if(localFavoritesState.filter(item => item.action).length > 0){
			FavoriteService.changeProductToFolders({
				list: localFavoritesState.filter(item => item.action).map(item => {
					return {
						id: item.id,
						action: item.action,
						count: item.checked ? ++item.count : --item.count
					}
				}),
				product_id: productId
			}, dispatchSaga, () => setProccessFolderId(false))
	
			callback({
				product_id: productId,
				element_id: localFavoritesState.filter(item => item.checked).map(item => item.id.toString())
			})
			onClose()
		}
	}

	useEffect(() => {

		if(proccessFolderId){
			setProccessFolderId(false)
		}

		if(folders.length > 0){
			setInitialLocalFavoritesState()
		}

	}, [folders])
	useEffect(() => {

		if(localFavoritesState.length > 0){
			setAllowChangeButton(localFavoritesState.some(item =>  { 
				return item.checked !== item.initChecked
			}))
		}

	}, [localFavoritesState])

	return (
		<Modal classNameWrapper="modal-add-to-favorite">
			<>
				<div className='modal-add-to-favorite__top'>
					<div className='modal-add-to-favorite__title'>
						Добавить в избранное
					</div>
					<IconClose 
						className={classNames(
							'modal-add-to-favorite__icon',
							'modal-add-to-favorite__icon--close'
						)}
						onClick={() => onClose()}
					/>
				</div>
				<div 
					className={classNames(
						'modal-add-to-favorite__items',
						folders.length > 0 && 'modal-add-to-favorite__items--notEmpty'
					)}
				>
					{
						localFavoritesState.length > 0 && localFavoritesState.map(item => {
							return (
								<div 
									key={item.id}
									className={classNames(
										'modal-add-to-favorite__item',
										proccessFolderId === item.id && 'modal-add-to-favorite__item--proccess'
									)}
								>
									<Checkbox
										id={`favorite-item-${item.id}`}
										className='modal-add-to-favorite__checkbox'
										label={item.name}
										type="main"
										checked={item.checked}
										onChange={(e) => changeProductToFolderHandler({
											elementId: item.id,
											checked: e.target.checked,
											action: e.target.checked ? 'add' : 'remove'
										})}
									/>
									
									{
										item.count > 0 && <div className='modal-add-to-favorite__itemCount'>{item.count}</div>
									}
									
								</div>
							)
						})
					}
				</div>
				{
					localFavoritesState.length > 0 &&
						<div
							className={classNames(
								'modal-add-to-favorite__action',
								showAddNewFolder && 'modal-add-to-favorite__action--openAddNewFolder'
							)}
						>
							<Button
								className={classNames(
									'modal-add-to-favorite__button',
									'modal-add-to-favorite__button--cancel'
									
								)}
								
								onClick={() => onClose()}
								text='Отмена'
							/>
							<Button
								className={classNames(
									'modal-add-to-favorite__button',
									'modal-add-to-favorite__button--change',
									!allowChangeButton && 'modal-add-to-favorite__button--disable',
								)}
								onClick={() => changeToFavorite()}
								text='Применить'
							/>
							
						</div>
				}

				<FavoriteNewFolder
					callback={param => setShowAddNewFolder(param)}
				/>
				
			</>
		</Modal>
	)
}

ModalAddToFavorite.propTypes = {
	onClose: PropTypes.func.isRequired,
	productId: PropTypes.string.isRequired,
	folderIds: PropTypes.array.isRequired,
	callback: PropTypes.func.isRequired,
}

export default ModalAddToFavorite