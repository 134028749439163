import React from 'react'

import PriceListComponent from 'components/PriceList'

const PriceList = () => {

	return (
		<div className="container container__content">
			<PriceListComponent />
		</div>
	)
}
export default PriceList