import React from 'react'
import PropTypes from 'prop-types'

import './availability-info-detail.scss'
import RestsSkeleton from 'components/ui/skeletons/RestsSkeleton'

const AvailabilityInfoDetail = ({ stores, preloader }) => {

	return (
		<div className="availability-info-detail">
			<div className="availability-info-detail__title">Наличие на складах:</div>
			<div className="availability-info-detail__items">
				{stores.map((store, index) => {
					return <div className="availability-info-detail__item" key={index}>
						<div className="availability-info-detail__itemName">{store.name}</div>
						{
							preloader ?
								<RestsSkeleton height={20} /> :
								<div className="availability-info-detail__itemCount">{store.free}/{store.quantity}</div>
						}

					</div>
				})}
			</div>

		</div>
	)

}
AvailabilityInfoDetail.propTypes = {
	stores: PropTypes.array,
	preloader: PropTypes.bool,
}
export default AvailabilityInfoDetail