import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { priceFormatter } from 'helpers'

import './finance-widget.scss'

const FinanceWidget = ({ className, data, theme }) => {

	const isTheme = theme.length > 0

	return (
		theme === 'header' && data.code === 'expdebet' && data.value === 0 ? null
			:
			<div className={classNames(`finance-widget finance-widget--${data.code}`, className, isTheme && `finance-widget--${theme}`)} >
				{!theme && <div className="finance-widget__icon"></div>}
				<div className={classNames('finance-widget__value', isTheme && `finance-widget__value--${theme}`)}>{priceFormatter(data.value)}</div>
				<div className={classNames('finance-widget__title', isTheme && `finance-widget__title--${theme}`)}>{data.name}</div>
			</div>
	)
}

FinanceWidget.propTypes = {
	className: PropTypes.string,
	data: PropTypes.object,
	theme: PropTypes.string,
}
export default FinanceWidget