import React, { useState, useEffect, Fragment, useRef, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { useFetch } from 'hooks/useFetch'

import { hexToRgbA, priceFormatter, dateFormatter } from 'helpers'

import { REQUEST_FAILED } from 'redux/types/errorTypes'

import { AuthContext } from 'context/AuthContext'

import OrderDetail from './OrderDetail'
import OrderModalUploadedFile from 'components/OrderModalUploadedFile/OrderModalUploadedFile'
import OrderFilter from 'components/OrderList/OrderFilter'
import OrderListMainSkeleton from 'components/ui/skeletons/OrderListMainSkeleton'
import Pagination from 'components/ui/Pagination/Pagination'
import Title from 'components/ui/Title/Title'
import IconArrow from 'assets/icons/IconArrow'

import CirclePreloader from 'assets/preloaders/Circle'
import HorizontalPreloader from 'assets/preloaders/Horizontal.gif'

import './order-list.scss'
import 'components/ui/Button/button.scss'

const OrderList = ({ limit, title, useFilter = false }) => {

	const { request, error } = useFetch()
	const dispatch = useDispatch()

	const auth = useContext(AuthContext)

	const tableHead = {
		showDetail: '',
		id: 'ID',
		number1C: 'Номер 1С',
		invoice: 'Расх. наклад',
		date: 'Дата заказа',
		delivery: 'Обеспечение',
		status: 'Статус',
		docs: 'Документы',
		price: 'Сумма',
	}

	const docInfoInitialState = {
		loading: false,
		type: '',
		id: '',
		list: []
	}

	const [orderList, setOrderList] = useState([])
	const [orderListLoading, setOrderListLoading] = useState(false)

	const [detailOrdersId, setDetailOrdersId] = useState([])
	const [detailOrders, setDetailOrders] = useState([])
	const [loadDetailOrder, setLoadDetailOrder] = useState(null)

	const [docInfo, setDocInfo] = useState(docInfoInitialState)

	const [paginationSetting, setPaginationSetting] = useState({
		currentPage: 1,
		elementCount: null,
		pagesCount: null,
	})

	const [filterParams, setFilterParams] = useState({
		status: '',
		date: {
			from: '',
			to: '',
		}
	})

	const [statusesForFilter, setStatusesForFilter] = useState([])

	const docsLinkRefs = useRef([])
	const modalActionForUploadedFileRef = useRef(null)

	const history = useHistory()

	const loadData = async (newPage = false, filter = {}) => {

		try {

			const getParams = new URLSearchParams(history.location.search)
			const getParamsPage = getParams.get('page')

			const { currentPage } = paginationSetting
			const page = newPage || getParamsPage || currentPage

			if (orderListLoading) {

				setOrderList([])
				setOrderListLoading(false)

			}

			const { from, to, status } = filter

			const res = await request(
				'order/list',
				{
					limit: limit,
					navPage: page,
					...(from && from.length > 0 && { 'filter[from]': from }),
					...(to && to.length > 0 && { 'filter[to]': to }),
					...(status && status.length > 0 && status !== 'All' && { 'filter[status]': status })
				}
			)

			const { list, navigation, statuses } = res.data

			setOrderList(list)

			if (filterParams) {
				setStatusesForFilter(statuses)
			}

			setOrderListLoading(true)
			setPaginationSetting(navigation)

			if (newPage) {
				history.push({
					search: `?page=${newPage}`
				})
			}

		} catch (error) {
			dispatch({ type: REQUEST_FAILED, payload: { createdAt: Date.now(), message: error.message || 'Ошибка загрузки данных' } })
		}
	}

	const fetchDetailOrder = async (event, id) => {

		if (!docsLinkRefs.current.includes(event.target) && (!modalActionForUploadedFileRef.current || !modalActionForUploadedFileRef.current.contains(event.target))) {
			if (!detailOrders.find(order => order.id === id)) {
				try {

					setLoadDetailOrder(id)

					const res = await request('order/detail', { id })
					setDetailOrders(prevState => {
						return [...prevState, ...[
							{
								products: res.data.items,
								comment: res.data.comment,
								delivery: orderList.find(order => order.id === id).delivery,
								id: id
							}
						]]
					})

					setLoadDetailOrder(null)

				} catch (error) {

					dispatch({ type: REQUEST_FAILED, payload: { createdAt: Date.now(), message: error.message || 'Ошибка загрузки данных' } })
					setLoadDetailOrder(null)

				}

			}

			setDetailOrdersId(prevState => {
				if (prevState.indexOf(id) !== -1) {
					return prevState.filter(i => i != id)
				} else {
					return [...prevState, id]
				}
			})
		}

	}

	const loadDoc = async (type, id, external) => {

		setDocInfo(prevState => ({ ...prevState, loading: true, type, id }))

		try {

			const { data } = await request('document', { type, 'params[document_id]': id, 'params[external]': external })
			setDocInfo(prevState => ({ ...prevState, list: data.list, loading: false }))

		} catch (error) {

			dispatch({ type: REQUEST_FAILED, payload: { message: error.message || 'Ошибка загрузки данных' } })
			setDocInfo(docInfoInitialState)

		}

	}

	const changeOrderStatus = ({ id, ...status }) => {
		setOrderList(prev => prev.map(order => {
			return order.id === id ? { ...order, status: status } : order
		}))
	}

	const getParamsFilter = (filterParams) => {

		const { date, status } = filterParams
		return { from: dateFormatter(date.from), to: dateFormatter(date.to), status: status }

	}

	useEffect(() => {

		loadData()

	}, [])

	useEffect(() => {

		loadData(false, getParamsFilter(filterParams))

	}, [filterParams])

	useEffect(() => {

		if (orderListLoading) {
			loadData()
		}

	}, [auth.user.login])

	return (
		<>
			<div className="order-list">
				<div className="order-list__topBlock">
					<Title
						content={title}
						type="h2"
						className="order-list__title"
					/>
					{
						useFilter ?
							<OrderFilter
								filterParams={filterParams}
								setFilterParams={setFilterParams}
								statutes={statusesForFilter}
								loadingData={orderListLoading}
								setDetailOrdersId={setDetailOrdersId}
								setDetailOrders={setDetailOrders}
							/>
							:
							<div className="order-list__linkToOrders" onClick={() => history.push('/orders/')}>Подробнее</div>
					}

				</div>
				<table className='table'>
					<thead className="table__head">
						<tr className="table__row">
							{Object.keys(tableHead).map(index => (
								<td
									className={`table__cell table__cell--head  order-list__tableHeadСell order-list__tableHeadСell--${index}`}
									key={index}
								>
									{tableHead[index]}
								</td>
							))}
							<td className="table__cell"></td>
						</tr>
					</thead>
					{orderList.length > 0
						?
						<tbody className="table__body">
							{orderList.map((item, key) => {
								const [date, time] = item.date.split(' ')
								return (
									<Fragment key={key}>
										<tr
											className={
												classNames(
													'table__row',
													'order-list__tableBodyRow',
													key % 2 === 0 && 'order-list__tableBodyRow--honest',
													detailOrdersId.includes(item.id) && 'order-list__tableBodyRow--open',
												)
											}
											onClick={(e) => fetchDetailOrder(e, item.id)}
										>
											<td className="table__cell order-list__tableBodyСell order-list__tableBodyСell--showDetail" >
												{loadDetailOrder === item.id ?
													<CirclePreloader
														className="order-list__preloader"
														width="14px"
														height="14px"
													/> :
													<div className="order-list__showDetail" >
														<IconArrow
															className={
																classNames(
																	'order-list__arrowIcon',
																	detailOrdersId.includes(item.id) && 'order-list__arrowIcon--rotate'
																)
															}
														/>
													</div>
												}

											</td>
											<td className='table__cell order-list__tableBodyСell order-list__tableBodyСell--id'>
												{item.id}
											</td>
											<td className='table__cell order-list__tableBodyСell order-list__tableBodyСell--number1C'>
												{item.number}
											</td>

											<td className='table__cell order-list__tableBodyСell order-list__tableBodyСell--invoice'>
												{item.waybills.length > 0 ? item.waybills.map((waybill, key) =>
													<div className='order-list__tableBodyСell--invoice-item' key={key}>
														{waybill}
													</div>

												)
													:
													<div className='order-list__tableBodyСell--invoice-item'>
														--
													</div>
												}
											</td>
											<td className='table__cell order-list__tableBodyСell order-list__tableBodyСell--date'>
												{date}
												<span className="order-list__time">{time}</span>
											</td>
											<td className='table__cell order-list__tableBodyСell order-list__tableBodyСell--delivery'>{item.delivery.method}</td>
											<td className='table__cell order-list__tableBodyСell order-list__tableBodyСell--status'>
												<div
													title={item.status.name}
													className="order-list__status"
													style={{ color: item.status.color, border: `1px solid ${hexToRgbA(item.status.color, 0.4)}` }}>
													{item.status.name}
												</div>
											</td>
											<td className='table__cell order-list__tableBodyСell order-list__tableBodyСell--docs'>
												{
													docInfo.id === item.id_1с && docInfo.type === 'billdoc' && docInfo.loading
														?
														<img
															src={HorizontalPreloader}
															className='order-list__horizontalPreloader order-list__horizontalPreloader--mr22'
															alt="loading..."
														/> :
														<span
															ref={el => docsLinkRefs.current[key] = el}
															className={classNames(
																'order-list__uploadLink order-list__uploadLink--mr20',
																docInfo.id !== '' && docInfo.loading && 'order-list__uploadLink--disabled',
																item.id_1с === null && 'order-list__uploadLink--disabled'
															)}
															onClick={() => docInfo.id === '' && item.id_1с !== null && loadDoc('billdoc', item.id_1с, item.is_external)}
														>
															Счет

															{
																docInfo.id === item.id_1с && docInfo.list.length > 0 && docInfo.type === 'billdoc' &&
																<OrderModalUploadedFile
																	ref={modalActionForUploadedFileRef}
																	docUrl={docInfo.list}
																	closeModal={() => setDocInfo(docInfoInitialState)}
																/>
															}
														</span>

												}
												{
													docInfo.id === item.id_1с && docInfo.type === 'updoc' && docInfo.loading
														?
														<img
															src={HorizontalPreloader}
															className='order-list__horizontalPreloader order-list__horizontalPreloader'
															alt="loading..."
														/> :
														<span
															ref={el => (docsLinkRefs.current[key + orderList.length] = el)}
															className={classNames(
																'order-list__uploadLink',
																docInfo.id !== '' && docInfo.loading && 'order-list__uploadLink--disabled',
																item.id_1с === null && 'order-list__uploadLink--disabled'
															)}
															onClick={() => docInfo.id === '' && item.id_1с !== null && loadDoc('updoc', item.id_1с, item.is_external)}
														>
															УПД
															{
																docInfo.id === item.id_1с && docInfo.list.length > 0 && docInfo.type === 'updoc' &&
																<OrderModalUploadedFile
																	ref={modalActionForUploadedFileRef}
																	docUrl={docInfo.list}
																	closeModal={() => setDocInfo(docInfoInitialState)}
																/>
															}
														</span>

												}

											</td>
											<td className='table__cell order-list__tableBodyСell order-list__tableBodyСell--price'>
												{priceFormatter(item.price)}
											</td>
										</tr>

										{
											detailOrdersId.includes(item.id) && detailOrders.find(order => order.id === item.id) &&
											<tr className={classNames('table__row order-list__tableBodyRow--detail', key % 2 === 0 && 'table__row order-list__tableBodyRow--honest')}>
												<td
													colSpan={Object.keys(tableHead).length + 1}
													className="table__cell order-list__tableBodyСell order-list__tableBodyСell--detailContainer order-list__tableBodyСell--pb45"
												>

													<OrderDetail
														order={detailOrders.find(order => order.id === item.id)}
														canceled={item.status.code === 'C'}
														changeOrderStatus={changeOrderStatus}
													/>
												</td>
											</tr>
										}
									</Fragment>
								)
							})}
						</tbody>

						:

						<tbody className="table__body">
							{
								!error && !orderListLoading
									?
									<OrderListMainSkeleton rowCount={limit || 4} />
									:
									<tr className="table__row">
										{
											orderList.length === 0 && orderListLoading
												?
												<td className='table__cell  order-list__tableBodyСell--somethingWrong' colSpan='9'>
													Нет ни одной записи
												</td>
												:
												<td className='table__cell order-list__tableBodyСell--somethingWrong' colSpan='9'>
													Ошибка загрузки данных
												</td>

										}
									</tr>

							}
						</tbody>
					}
				</table>
			</div>
			{
				paginationSetting.pagesCount > 1 && !limit && orderListLoading &&
				<Pagination
					setting={paginationSetting}
					callback={page => loadData(page, getParamsFilter(filterParams))}
					className='order-list__pagination'
				/>
			}

		</>
	)
}
OrderList.propTypes = {
	limit: PropTypes.number,
	filterParams: PropTypes.object,
	setStatusesForFilter: PropTypes.func,
	title: PropTypes.string,
	useFilter: PropTypes.bool,
}
export default OrderList