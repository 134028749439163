import React from 'react'
import PropTypes from 'prop-types'

import './skeleton.scss'

const OrderListMainSkeleton = ({ rowCount = 1 }) => {

	const renderTableRow = (key) => {

		return (
			<tr key={key}>
				<td>
					<div className="skeleton skeleton--line skeleton__orderListMain skeleton__orderListMain--buttons"></div>
				</td>
				<td>
					<div className="skeleton skeleton--line skeleton__orderListMain skeleton__orderListMain--id"></div>
				</td>
				<td>
					<div className="skeleton skeleton--line skeleton__orderListMain skeleton__orderListMain--number1C"></div>
				</td>
				<td>
					<div className="skeleton skeleton--line skeleton__orderListMain skeleton__orderListMain--invoice"></div>
				</td>
				<td>
					<div className="skeleton skeleton--line skeleton__orderListMain skeleton__orderListMain--date"></div>
				</td>
				<td>
					<div className="skeleton skeleton--line skeleton__orderListMain skeleton__orderListMain--delivery"></div>
				</td>
				<td>
					<div className="skeleton skeleton--line skeleton__orderListMain skeleton__orderListMain--status"></div>
				</td>
				<td>
					<div className="skeleton skeleton--line skeleton__orderListMain skeleton__orderListMain--docs"></div>
				</td>
				<td>
					<div className="skeleton skeleton--line skeleton__orderListMain skeleton__orderListMain--price"></div>
				</td>

			</tr>

		)
	}

	const rows = []

	while (rowCount) {
		rows.push(renderTableRow(rowCount))
		rowCount--
	}

	return rows

}

OrderListMainSkeleton.propTypes = {
	rowCount: PropTypes.number
}

export default OrderListMainSkeleton