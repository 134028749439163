import React, { useEffect, useState, useRef, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

import { openSearch, closeSearch } from 'redux/actions/searchActions'
import { AuthContext } from 'context/AuthContext'
import { useDebounce } from 'hooks/useDebounce'

import HeaderNav from 'components/HeaderNav/HeaderNav'
import Notifications from 'components/Notifications'
import HeaderMain from 'components/HeaderMain/HeaderMain'
import SearchElements from 'components/SearchElements/SearchElements'

import './header.scss'

const Header = () => {
	
	const { user, agreement} = useContext(AuthContext)
	const dispatch = useDispatch()
	const history = useHistory()
	
	const { pageNotFound } = useSelector(state => state.app)

	const HeaderMainRef = useRef(null)

	const [headerMainHeight, setHeaderMainHeight] = useState(0)
	const [isSticky, setSticky] = useState(false)

	const [searchQuery, setSearchQuery] = useState('')
	const debouncedSearchQuery = useDebounce(searchQuery, 1000)

	const handleScroll = () => {

		const isHorizontalScroll = document.scrollingElement.scrollWidth > document.scrollingElement.clientWidth

		if (HeaderMainRef.current && !isHorizontalScroll) {

			setSticky(HeaderMainRef.current.getBoundingClientRect().top <= 0)
		} else {
			setSticky(false)
		}

	}

	const clearSearchResult = () => {
		history.push({
			search: ''
		})
	}

	useEffect(() => {
		if (HeaderMainRef.current) {
			setHeaderMainHeight(HeaderMainRef.current.firstElementChild.clientHeight)
		}

		window.addEventListener('scroll', handleScroll)

		return () => {
			window.removeEventListener('scroll', () => handleScroll)
		}

	}, [])

	useEffect(() => {

		const getParams = new URLSearchParams(history.location.search)
		const getParamsQuery = getParams.get('query')

		if (getParamsQuery) {
			setSearchQuery(getParamsQuery)
		} else {
			if (searchQuery.length > 0) {
				setSearchQuery('')
			}

		}

		if (getParamsQuery && getParamsQuery.length >= 3) {
			dispatch(openSearch())
		} else {
			dispatch(closeSearch())
		}

	}, [history.location.search])

	return (
		<div className={classNames('container header', searchQuery.length >= 3 && 'container__content')}>
			{
				user && agreement &&
				<Notifications />
			}
			<HeaderNav />
			{
				!pageNotFound &&
				<>
					<HeaderMain
						ref={HeaderMainRef}
						isSticky={isSticky}
						searchQuery={searchQuery}
						clearSearchResult={clearSearchResult}
					/>
					{isSticky && <div className='header__fakeHeaderMain' style={{ height: headerMainHeight }}></div>}
					{
						searchQuery.length >= 3 &&
						<SearchElements
							query={debouncedSearchQuery}
							title={searchQuery}
							clearSearchResult={clearSearchResult}
						/>
					}
				</>
			}

		</div>

	)

}
export default Header