import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import DeliveryCalc from 'components/DeliveryCalc/DeliveryCalc'
import Title from 'components/ui/Title/Title'

import './style.scss'

const Delivery = ({ invisible }) => {

	return (
		<div className={classNames('container container__content', invisible && 'invisible')}>
			<div className="delivery">

				<DeliveryCalc>
					<Title
						content="Рассчитать стоимость доставки"
						className="delivery__title"
					/>
				</DeliveryCalc >
			</div>
		</div>
	)
}

Delivery.propTypes = {
	invisible: PropTypes.bool,
}

export default Delivery