export const REQUEST_CHANGE_PRODUCT_TO_FOLDERS = 'REQUEST_CHANGE_PRODUCT_TO_FOLDERS'
export const REQUEST_GET_FOLDER_LIST = 'REQUEST_GET_FOLDER_LIST'
export const REQUEST_DELETE_FOLDER = 'REQUEST_DELETE_FOLDER'
export const REQUEST_CHANGE_FOLDER = 'REQUEST_CHANGE_FOLDER'
export const REQUEST_ADD_FOLDER = 'REQUEST_ADD_FOLDER'

export const CHANGE_PRODUCT_TO_FOLDERS = 'CHANGE_PRODUCT_TO_FOLDERS'
export const SET_PRODUCT_LIST = 'SET_PRODUCT_LIST'
export const GET_FOLDER_LIST = 'GET_FOLDER_LIST'
export const DELETE_FOLDER = 'DELETE_FOLDER'
export const CHANGE_FOLDER = 'CHANGE_FOLDER'
export const ADD_FOLDER = 'ADD_FOLDER'
