import React, { useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { findItemToArrayRecursive } from 'helpers'

import { REQUEST_FAILED } from 'redux/types/errorTypes'

import { useFetch } from 'hooks/useFetch'

import Checkbox from 'components/ui/Checkbox/Checkbox'
import Button from 'components/ui/Button/Button'

import PriceListPopup from './PriceListPopup'

import './style.scss'

const PriceListSidebar = ({ sections, isSelectedCheckbox, changeSelectedSection, loadingSectionTree, priceType, priceListURL, setPriceListURL, changeUnloadPrices }) => {

	const { request } = useFetch()
	const dispatch = useDispatch()

	const [isGenerationDoc, setIsGenerationDoc] = useState('')

	const isSquare = (array) => array && array.filter(children => children.selected).length !== array.length && findItemToArrayRecursive(array, 'selected', true)

	const getSectionIdsActive = (initial, array) => {

		return array.reduce((a, section) => {

			if (section.selected) a.push(section.id)
			if (section.children && !section.selected) return getSectionIdsActive(a, section.children)

			return a

		}, initial)

	}

	const generationPriceList = async () => {

		try {

			setIsGenerationDoc('loading')

			const sectionParentSelectedLength = sections.filter(children => children.selected).length
			const sectionIdsActive = sectionParentSelectedLength === sections.length ? [] : getSectionIdsActive([], sections)
			const prices = priceType.filter(type => type.value).map(type => type.code)

			if (sectionIdsActive.length !== 0 || sectionParentSelectedLength === sections.length) {

				const response = await request('pricelist', {
					sections: sectionIdsActive,
					prices
				})

				setPriceListURL(response.data.url)
			}

		} catch (error) {

			dispatch({ type: REQUEST_FAILED, payload: { message: error.message } })

		} finally {

			setIsGenerationDoc('success')

		}
	}

	return (
		<div className="price-list-sidebar">
			<div className="price-list-sidebar__unloadPrices">

				{
					priceType.map((price, key) => {
						return <Button
							key={key}
							text={price.name}
							callback={() => changeUnloadPrices(price.name)}
							className={
								classNames(
									'price-list-sidebar__unloadPricesButton',
									key === priceType.length - 1 && 'price-list-sidebar__unloadPricesButton--last',
									price.value && 'price-list-sidebar__unloadPricesButton--active'
								)
							}
						/>
					})
				}

			</div>

			<div className="price-list-sidebar__items">
				<div className="price-list-sidebar__item price-list-sidebar__wrap">
					<Checkbox
						id='all'
						label='Выбрать все'
						value='Выбрать все'
						type="main"
						className={
							classNames(
								'price-list-sidebar__checkbox',
								isSquare(sections) && 'checkbox--square'
							)
						}
						onChange={(e) => changeSelectedSection(null, null, e.target.checked)}
						checked={sections.length > 0 && sections.filter(children => children.selected).length === sections.length}
					/>
					<div className="price-list-sidebar__redPoint" />
					{isSelectedCheckbox &&
						<div className="price-list-sidebar__clear" onClick={() => changeSelectedSection(null, null, false)} >Очистить</div>
					}

				</div>

				{
					sections.map((section, key) => {

						return (
							<div key={key} className="price-list-sidebar__item">
								<Checkbox
									name={section.id}
									id={section.id}
									type="main"
									value={section.name || false}
									onChange={(e) => changeSelectedSection(null, section.id, e.target.checked)}
									checked={section.selected || false}
									className={
										classNames(
											'price-list-sidebar__checkbox',
											isSquare(section.children) && 'checkbox--square'
										)
									}
								/>
								<div className="price-list-sidebar__itemName" onClick={() => loadingSectionTree(section.id, key)}>
									{section.name}
								</div>
							</div>
						)
					})
				}
			</div>

			{isSelectedCheckbox &&
				<Button
					text='Сформировать прайс'
					className="price-list-sidebar__generation"
					callback={generationPriceList}

				/>
			}

			{isGenerationDoc.length > 0 &&
				<PriceListPopup
					isGenerationDoc={isGenerationDoc}
					setIsGenerationDoc={setIsGenerationDoc}
					priceListURL={priceListURL}
				/>
			}

		</div>
	)

}

PriceListSidebar.propTypes = {
	sections: PropTypes.array,
	isSelectedCheckbox: PropTypes.bool,
	changeSelectedSection: PropTypes.func,
	loadingSectionTree: PropTypes.func,
	priceType: PropTypes.array,
	priceListURL: PropTypes.string,
	setPriceListURL: PropTypes.func,
	changeUnloadPrices: PropTypes.func,
}

export default PriceListSidebar