import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import PaginationSkeleton from 'components/ui/skeletons/PaginationSkeleton'
import IconArrow from 'assets/icons/IconArrow'

import './pagination.scss'

const Pagination = ({ setting, callback, className, visibleSkeleton = true }) => {

	const { currentPage, pagesCount } = setting
	const [paginationArray, setPaginationArray] = useState([])
	const [isReady, setIsReady] = useState(false)

	useEffect(() => {
		setPaginationArray(getPaginationArray())
	}, [currentPage])

	const button = (page, className = '', text = null) => {
		let [key] = page.toString().split('|')
		key = parseInt(key)
		return (
			<div
				className={
					classNames(
						'pagination__button',
						className,
						page === currentPage && 'pagination__button--current'
					)
				}
				onClick={() => {
					if (key !== 0 && page !== currentPage && key !== pagesCount + 1) {
						callback(key)
					}
				}}
				key={page}
			>
				{text || page}
			</div>
		)
	}

	const getPaginationArray = () => {

		const modulo = currentPage % 3

		let leftVisible = 0
		let start = 0
		let end = 0
		let max = 0

		let out = []
		const navs = [...Array(pagesCount).keys()]

		if (modulo === 0) {
			leftVisible = 1
		} else if (modulo === 1) {
			leftVisible = 2
		}

		switch (true) {
			case pagesCount <= 6:
				max = pagesCount - 2
				break
			case pagesCount - currentPage === 4 && leftVisible === 0:
				max = 4
				break
			default:
				max = 3
				break
		}

		if (pagesCount - currentPage <= 3) {
			navs.reverse()
			start = 0
			end = max
		} else {
			if (leftVisible === 0) {
				start = currentPage + leftVisible
			} else if (leftVisible === 1) {
				start = currentPage - leftVisible
			} else {
				if (currentPage === 1) {
					start = currentPage + 1
				} else {
					start = currentPage - leftVisible
				}

			}
			end = start + max
		}

		let navsSlice = navs.slice(start, end)

		if (pagesCount - currentPage <= 3) {
			navsSlice.reverse()
		}

		for (let nav of navsSlice) {
			out.push(button(nav))
		}
		setIsReady(true)
		return out
	}

	const paginationItemsRender = () => {
		return paginationArray.map(pagination => pagination)
	}

	const prevDots = () => {
		if (paginationArray.length > 0) {
			const [first] = paginationArray
			const key = parseInt(first.key)
			if (key >= 5) {
				return button(key - 1, 'pagination__button--dots', '...')
			}
		}

	}
	const nextDots = () => {
		if (paginationArray.length > 0) {
			const [last] = paginationArray.slice().reverse()
			const key = parseInt(last.key)
			if (key !== pagesCount - 1) {

				return button(key + 1, 'pagination__button--dots', '...')
			}
		}

	}

	const firtsPage = () => {
		return button(1)
	}

	const lastPage = () => {
		return button(pagesCount)
	}

	const prevArrow = () => {
		if (paginationArray.length >= 3) {
			return button(
				`${currentPage - 1}|arrow`,
				`pagination__arrow ${currentPage === 1 ? 'pagination__arrow--disable' : ''} pagination__arrow--prev`,
				<IconArrow className="pagination__icon" />
			)
		}
	}

	const nextArrow = () => {
		if (paginationArray.length >= 3) {
			return button(
				`${currentPage + 1}|arrow`,
				`pagination__arrow ${currentPage === pagesCount ? 'pagination__arrow--disable' : ''}  pagination__arrow--next`,
				<IconArrow className="pagination__icon pagination__icon--next" />
			)
		}
	}

	return (
		<div className={classNames('pagination', className)} >
			{isReady ?
				[
					prevArrow(),
					firtsPage(),
					prevDots(),
					paginationItemsRender(),
					nextDots(),
					lastPage(),
					nextArrow()
				]
				:
				visibleSkeleton &&
				<PaginationSkeleton />
			}
		</div>
	)
}

Pagination.propTypes = {
	setting: PropTypes.object,
	callback: PropTypes.func,
	className: PropTypes.string,
	visibleSkeleton: PropTypes.bool,
}
export default Pagination
