import React from 'react'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useHistory } from 'react-router-dom'
import * as Yup from 'yup'

import { useFetch } from 'hooks/useFetch'

import Title from 'components/ui/Title/Title'
import Input from 'components/ui/Input/Input'
import Button from 'components/ui/Button/Button'

import './style.scss'
import CirclePreloader from 'assets/preloaders/Circle'

const ChangePasswordFormSchema = Yup.object().shape({
	password: Yup.string().required(' '),
	confirmpass: Yup.string().required(' ')
		.min(6, 'Пароль должен быть не менее 6 символов длиной.')
		.oneOf([Yup.ref('password'), null], 'Пароли должны совпадать')
})

const ChangePasswordForm = ({ userCheckword, userLogin }) => {

	const { request } = useFetch()
	const history = useHistory()

	const formik = useFormik({
		validationSchema: ChangePasswordFormSchema,
		initialValues: {
			login: userLogin,
			code: userCheckword,
			password: '',
			confirmpass: '',
		},
		initialStatus: false,
		onSubmit: async values => {
			try {

				await request('user/changepassword', {}, { ...values }, 'POST')
				formik.setStatus(true)

				setTimeout(() => {
					history.push('/')
				}, 3000)

			} catch (error) {

				formik.setFieldError('server', ...error.message)

			}

		},
	})

	return (
		<form
			className="change-pass-form"
			onSubmit={formik.handleSubmit}
		>
			<Title
				content="Восстановить пароль"
				className="change-pass-form__title"
			/>
			<div className="change-pass-form__note">
				Придумайте новый пароль для завершения восстановления
			</div>
			<Input
				type="password"
				className={
					classNames(
						'change-pass-form__input',
						formik.errors.password && formik.touched.password && 'change-pass-form__input--error'
					)
				}
				placeholder="Придумайте новый пароль"
				name="password"
				value={formik.values.password}
				onChange={formik.handleChange}
			/>
			<Input
				type="password"
				className={
					classNames(
						'change-pass-form__input change-pass-form__input--last',
						formik.errors.confirmpass && formik.touched.confirmpass && 'change-pass-form__input--error'
					)
				}
				placeholder="Повторите пароль"
				name="confirmpass"
				value={formik.values.confirmpass}
				onChange={formik.handleChange}
			/>

			<div className="change-pass-form__bottom">
				{
					formik.errors.confirmpass &&
					<div className="change-pass-form__message change-pass-form__message--error">
						{formik.errors.confirmpass}
					</div>
				}
				{
					formik.errors.server &&
					<div className="change-pass-form__message change-pass-form__message--error">
						{formik.errors.server}
					</div>
				}
				{
					formik.status &&
					<div className="forgot-pass-form__message forgot-pass-form__message--success">
						Пароль был изменен. Сейчас Вы будете перенаправлены на главную страницу для авторизации
					</div>
				}
				{
					!formik.status &&
					<Button
						type="submit"
						className="change-pass-form__submit"
						text={
							!formik.isSubmitting ?
								'Далее' :
								<CirclePreloader className="change-pass-form__preloader" />
						}
					/>
				}

			</div>

		</form>
	)
}

ChangePasswordForm.propTypes = {
	userCheckword: PropTypes.string,
	userLogin: PropTypes.string
}

export default ChangePasswordForm
