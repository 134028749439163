import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { DELETE_ERROR, DELETE_All_ERRORS, DELETE_LAST_ERROR } from 'redux/types/errorTypes'

import './erron-notification.scss'
import Button from 'components/ui/Button/Button'

const ErrorNotification = () => {

	const errors = useSelector(state => state.errors.errors)
	const dispatch = useDispatch()

	const timeShowError = 3000

	const clearAllErrors = () => {
		dispatch({
			type: DELETE_All_ERRORS
		})
	}

	useEffect(() => {

		if (errors.length > 0 && errors.find(error => error.createdAt)) {

			const intervalId = setInterval(() => {

				errors.forEach(item => {
					if (item.createdAt && Date.now() - timeShowError > item.createdAt) {
						dispatch({
							type: DELETE_ERROR,
							payload: item.createdAt
						})
					}
				})

			}, 1000)

			return () => {
				clearInterval(intervalId)
			}
		}

		if (errors.length > 1) {
			dispatch({
				type: DELETE_LAST_ERROR
			})
		}

	}, [errors])

	return (

		errors.length > 0 &&
		<div className="error-notification">

			<>
				{errors.map((error, key) => <p key={key} className="error-notification__message">{error.message}</p>)}
				<Button
					className="error-notification__button" text="OK"
					callback={clearAllErrors} />
			</>

		</div>

	)
}
export default ErrorNotification