import { useContext, useState, useCallback } from 'react'

import { AuthContext } from 'context/AuthContext'

import ApiService from 'services/ApiService'

export const useFetch = () => {

	const auth = useContext(AuthContext)

	const [error, setError] = useState(null)

	const request = useCallback(async (url, params = {}, data = {}, method = 'GET', headers = {}) => {

		try {

			const res = await ApiService.request(url, {
				params,
				data,
				method,
				headers
			})
			return res

		} catch (e) {

			if (e.response.status === 401) {
				auth.logout()
			}

			setError(true)
			throw e.response.data

		}
	}, [])

	return { request, error }
}
