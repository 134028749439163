import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Radio from 'components/ui/Radio/Radio'

import './order-params.scss'

const OrderParams = ({ className, title, content, name, onChange }) => {
	return (

		<div className={classNames('order-params', className)}>
			<div className="order-params__title">{title}</div>
			<div className="order-params__radioBlock">
				{content &&
					content.map((item, key) => {
						return (
							<Radio
								key={key}
								className={content.length - 1 !== key ? 'order-params__radio--mr-50' : ''}
								label={item.name}
								callback={() => onChange(item)}
								name={name}
								defaultChecked={key === 0}
								id={`radio-${name}-${item.id}`}

							/>
						)
					})
				}
			</div>
		</div >

	)

}
OrderParams.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string,
	content: PropTypes.array,
	name: PropTypes.string,
	onChange: PropTypes.func,
}
export default OrderParams