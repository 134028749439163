export const REQUEST_GET_BASKET = 'REQUEST_GET_BASKET'
export const REQUEST_DELETE_BASKET_ITEM = 'REQUEST_DELETE_BASKET_ITEM'
export const REQUEST_DELETE_BASKET_All = 'REQUEST_DELETE_BASKET_All'
export const REQUEST_CHANGE_BASKET_ITEM = 'REQUEST_CHANGE_BASKET_ITEM'
export const REQUEST_ADD_BASKET_ITEM = 'REQUEST_ADD_BASKET_ITEM'

export const GET_BASKET = 'GET_BASKET'
export const GET_BASKET_FAILED = 'GET_BASKET_FAILED'
export const DELETE_BASKET_ITEM = 'DELETE_BASKET_ITEM'
export const DELETE_BASKET_All = 'DELETE_BASKET_All'
export const CHANGE_BASKET_ITEM = 'CHANGE_BASKET_ITEM'
export const ADD_BASKET_ITEM = 'ADD_BASKET_ITEM'

export const LOADING_BASKET_FLAG_TO_DEFAULT = 'LOADING_BASKET_FLAG_TO_DEFAULT'
