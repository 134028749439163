import React from 'react'
import PropTypes from 'prop-types'

const IconClose = ({ className, onClick }) => {
	return (
		<svg 
			className={className}
			onClick={onClick}
			viewBox="0 0 17 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g>
				<path 
					d="M1.83336 1.3335L15.0326 14.5327M15.0325 1.3335L1.83325 14.5327"
					stroke="white"
					strokeWidth="2.5"
					strokeLinecap="round"
					strokeLinejoin="round"
				/>
			</g>
		</svg>
	)

}

IconClose.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,

}

export default IconClose