import React from 'react'
import PropTypes from 'prop-types'

const IconIncrease = ({ className, onClick }) => {
	return (
		<svg
			className={className}
			onClick={onClick}
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path 
				d="M8 3.33334V12.6667"
				stroke="#1D2023"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M12.6668 8L3.3335 8"
				stroke="#1D2023"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>

	)

}

IconIncrease.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,

}

export default IconIncrease