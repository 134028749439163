import React, { forwardRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { useOnClickOutside } from 'hooks/useOnClickOutside'

import 'components/ui/Button/button.scss'
import './order-modal-uploaded-file.scss'

const OrderModalUploadedFile = forwardRef(({ docUrl, className, closeModal }, ref) => {

	useOnClickOutside([ref], event => {
		if (ref.current && !ref.current.contains(event.target)) {
			closeModal()
		}
	})

	return (
		<div
			ref={ref}
			className={
				classNames(
					'order-modal-uploaded-file',
					className
				)
			}
		>
			{
				docUrl.map((item, key) => {
					return (
						<div
							key={key}
							className={'order-modal-uploaded-file__item'}
						>
							<div className={'order-modal-uploaded-file__itemTitle'}>
								{item.name}
							</div>
							<a
								href={item.url} target="_blank"
								rel="noreferrer"
								className="order-modal-uploaded-file__itemLink"
							>
								Посмотреть
							</a>
						</div>
					)
				})
			}

		</div >
	)

})

OrderModalUploadedFile.displayName = 'OrderModalUploadedFile'

OrderModalUploadedFile.propTypes = {
	docUrl: PropTypes.array,
	className: PropTypes.string,
	closeModal: PropTypes.func,
}

export default OrderModalUploadedFile