import React from 'react'
import PropTypes from 'prop-types'

const IconLogout = ({ className }) => {
	return (
		<svg
			className={className} width="12"
			height="12" viewBox="0 0 12 12"
			fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M5.98007 11H1.49501C1.21992 11 0.996679 10.776 0.996679 10.5V1.50001C0.996679 1.22401 1.21994 1.00003 1.49501 1.00003H5.98007C6.25565 1.00003 6.4784 0.776533 6.4784 0.500036C6.4784 0.22354 6.25565 0 5.98007 0H1.49501C0.670757 0 0 0.673017 0 1.50001V10.5C0 11.327 0.670757 12 1.49501 12H5.98007C6.25565 12 6.4784 11.7765 6.4784 11.5C6.4784 11.2235 6.25565 11 5.98007 11Z" fill="#75767B" />
			<path d="M11.8511 5.64383L8.8212 2.64382C8.62585 2.44982 8.30991 2.45234 8.11655 2.64883C7.92319 2.84533 7.92518 3.16183 8.12154 3.35583L10.2868 5.49982H4.48466C4.20908 5.49982 3.98633 5.72331 3.98633 5.99981C3.98633 6.27631 4.20908 6.49982 4.48466 6.49982H10.2868L8.12154 8.64381C7.92521 8.83782 7.92371 9.15431 8.11655 9.35081C8.21421 9.44981 8.34279 9.49981 8.47137 9.49981C8.59796 9.49981 8.72452 9.45181 8.8212 9.3558L11.8511 6.35579C11.9458 6.2618 11.9996 6.13378 11.9996 5.99979C11.9996 5.86584 11.9463 5.73834 11.8511 5.64383Z" fill="#75767B" />
		</svg>
	)
}

IconLogout.propTypes = {
	className: PropTypes.string,
}

export default IconLogout