import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { setPageNotFound } from 'redux/actions/appActions'

import { useDocumentTitle } from 'hooks/useDocumentTitle'

import Title from 'components/ui/Title/Title'
import Button from 'components/ui/Button/Button'

import './style.scss'

const PageNotFound = ({ title }) => {

	useDocumentTitle(title)

	const dispatch = useDispatch()
	const history = useHistory()

	useEffect(() => {

		dispatch(setPageNotFound(true))

		return () => {
			dispatch(setPageNotFound(false))
		}

	}, [])
	return (
		<div className='container container__content'>
			<div className='page-not-found'>
				<div className='page-not-found__left'>
					<svg
						width="480" height="333"
						viewBox="0 0 480 333" fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path d="M360.694 297.435H80.4258V299.645H360.694V297.435Z" fill="#0256B1" />
						<path d="M479.999 297.435H464.158V299.645H479.999V297.435Z" fill="#0256B1" />
						<path d="M98.4805 263.529L100.918 298.541H139.136L136.588 260.878L98.4805 263.529Z" fill="#0256B1" />
						<path d="M140.357 299.645H99.9229L97.375 262.424L137.698 259.662L140.357 299.645ZM101.917 297.436H137.92L135.483 262.092L99.5906 264.522L101.917 297.436Z" fill="#0256B1" />
						<path d="M66.7999 298.54L111 191.295L75.6622 180.802L26.9199 298.54H66.7999Z" fill="#0256B1" />
						<path d="M67.575 299.644H25.2578L75.1078 179.367L112.551 190.522L67.575 299.644ZM28.5811 297.435H66.1348L109.56 191.958L76.3264 182.017L28.5811 297.435Z" fill="#0256B1" />
						<path d="M156.971 298.541H198.624C198.513 296.884 198.291 295.117 198.181 293.35C196.851 273.027 198.513 257.785 203.276 247.845C208.04 237.905 215.24 232.493 224.767 231.941C234.183 231.278 241.827 235.585 247.698 244.863C253.68 254.141 257.225 268.941 258.665 289.263C258.887 292.466 258.998 295.559 259.108 298.541H301.315C301.315 294.564 301.204 290.588 300.872 286.391C299.985 273.027 297.77 260.767 294.225 249.502C290.68 238.347 285.695 228.848 279.27 220.896C272.845 213.054 264.869 207.09 255.563 203.003C246.147 199.027 235.402 197.371 223.106 198.144C210.588 199.027 199.953 202.12 191.091 207.421C182.339 212.723 175.25 219.681 169.932 228.296C164.615 236.911 160.848 247.072 158.633 258.559C156.528 270.156 155.863 282.636 156.75 296C156.86 296.994 156.971 297.767 156.971 298.541Z" fill="white" />
						<path d="M302.312 299.645H258.001V298.54C257.89 295.558 257.779 292.465 257.558 289.373C256.118 269.271 252.462 254.472 246.812 245.415C241.163 236.469 233.851 232.382 224.989 233.045C215.795 233.707 209.037 238.677 204.384 248.397C199.732 258.227 198.07 273.358 199.399 293.349C199.51 295.116 199.621 296.773 199.843 298.43L199.953 299.645H156.085L155.975 297.988C155.975 297.436 155.864 296.883 155.864 296.331C154.978 283.077 155.531 270.376 157.747 258.558C159.963 246.851 163.84 236.579 169.157 227.854C174.474 219.128 181.675 211.949 190.648 206.537C199.621 201.125 210.477 198.033 223.106 197.149C235.402 196.266 246.591 197.922 256.118 202.119C265.534 206.206 273.621 212.391 280.156 220.343C286.582 228.295 291.677 238.015 295.333 249.28C298.878 260.546 301.204 273.027 302.091 286.391C302.423 290.367 302.534 294.454 302.534 298.54L302.312 299.645ZM260.106 297.436H300.096C300.096 293.791 299.986 290.036 299.653 286.501C298.767 273.248 296.552 260.988 293.007 249.943C289.462 238.898 284.588 229.4 278.273 221.779C271.959 214.048 264.094 208.194 255.01 204.218C245.815 200.242 235.07 198.695 222.995 199.469C210.699 200.352 200.175 203.334 191.534 208.525C182.894 213.716 175.915 220.675 170.819 229.069C165.612 237.463 161.846 247.624 159.741 259C157.636 270.597 156.972 283.077 157.858 296.221C157.858 296.663 157.969 297.104 157.969 297.546H197.295C197.184 296.221 197.073 294.895 196.962 293.57C195.633 273.137 197.295 257.675 202.169 247.513C207.154 237.131 214.687 231.609 224.657 230.946C225.321 230.946 225.986 230.836 226.651 230.836C235.513 230.836 242.935 235.364 248.585 244.31C254.567 253.809 258.222 268.498 259.773 289.263C259.884 292.024 260.106 294.785 260.106 297.436Z" fill="#0256B1" />
						<path d="M456.071 298.54L453.302 281.09L433.472 282.525L430.37 238.457L392.374 241.107L395.365 285.066L355.928 287.716L404.892 168.875L369.553 158.382L311.506 298.54H456.071Z" fill="#0256B1" />
						<path d="M457.4 299.644H309.844L368.888 156.946L406.442 168.101L357.589 286.501L394.146 283.961L391.155 240.003L431.478 237.242L434.469 281.31L454.187 279.985L457.4 299.644ZM313.167 297.436H454.852L452.415 282.304L432.475 283.63L429.484 239.561L393.592 241.991L396.583 285.949L354.266 288.821L403.451 169.648L370.218 159.707L313.167 297.436Z" fill="#0256B1" />
						<path
							opacity="0.1" d="M241.051 208.857C241.384 213.606 231.746 218.134 219.56 219.128C207.264 220.122 197.073 217.14 196.629 212.391C196.297 207.642 205.935 203.114 218.12 202.12C230.417 201.125 240.608 204.108 241.051 208.857Z"
							fill="#0256B1" />
						<path
							opacity="0.1" d="M262.544 211.507C261.879 213.385 257.67 213.716 253.239 212.06C248.807 210.513 245.816 207.642 246.481 205.764C247.146 203.887 251.355 203.555 255.786 205.212C260.217 206.869 263.319 209.63 262.544 211.507Z"
							fill="#0256B1" />
						<path d="M13.2933 297.435H0V299.645H13.2933V297.435Z" fill="#0256B1" />
						<path d="M68.9044 315.438H55.9434V317.648H68.9044V315.438Z" fill="#0256B1" />
						<path d="M111.001 315.438H87.5156V317.648H111.001V315.438Z" fill="#0256B1" />
						<path d="M371.215 315.438H360.691V317.648H371.215V315.438Z" fill="#0256B1" />
						<path d="M429.375 315.438H383.846V317.648H429.375V315.438Z" fill="#0256B1" />
						<path d="M48.2983 330.79H36.7773V333H48.2983V330.79Z" fill="#0256B1" />
						<path d="M446.544 330.79H435.023V333H446.544V330.79Z" fill="#0256B1" />
						<path d="M206.823 210.072C205.272 210.072 204.053 208.857 204.053 207.31C204.164 183.233 196.853 167.439 195.08 164.457C194.748 164.125 194.416 163.573 194.305 163.021C194.083 162.027 194.416 160.922 195.302 160.26C196.188 159.597 197.296 159.486 198.293 159.928C200.841 161.143 204.829 173.403 204.829 173.513C207.044 180.582 209.703 192.179 209.703 207.31C209.592 208.857 208.374 210.072 206.823 210.072Z" fill="#0256B1" />
						<path d="M272.623 179.036C271.958 179.036 271.293 178.815 270.739 178.262C269.632 177.158 269.632 175.501 270.629 174.397C272.512 172.409 277.386 166.003 275.171 162.248C272.955 158.382 263.871 154.848 258.665 153.522C257.225 153.191 256.228 151.645 256.671 150.209C257.003 148.773 258.554 147.889 259.994 148.221C261.545 148.662 275.724 152.307 279.934 159.486C284.587 167.549 274.949 177.71 274.617 178.152C274.174 178.815 273.398 179.036 272.623 179.036Z" fill="#0256B1" />
						<path d="M253.127 78.0866C252.684 78.0866 252.241 77.9762 251.798 77.7553C250.469 77.0926 249.915 75.3254 250.69 74L269.08 39.0986C269.744 37.7732 271.517 37.221 272.846 37.9941C274.175 38.6568 274.729 40.424 273.954 41.7493L255.565 76.6508C255.122 77.5344 254.124 78.0866 253.127 78.0866Z" fill="#0256B1" />
						<path d="M181.342 102.385C181.231 102.385 181.121 102.385 181.01 102.385L136.477 97.7465C134.926 97.636 133.818 96.2002 134.04 94.7644C134.151 93.2181 135.591 92.1136 137.031 92.3345L181.564 96.9733C183.115 97.0838 184.222 98.5196 184.001 99.9554C184.001 101.391 182.782 102.385 181.342 102.385Z" fill="#0256B1" />
						<path d="M274.952 154.737L201.284 177.489C199.844 177.931 198.182 177.158 197.739 175.612L170.82 88.8C170.599 88.1373 170.71 87.3642 171.042 86.7015L180.569 68.5881C180.901 67.9254 181.566 67.3732 182.23 67.1523L242.051 48.7075C243.491 48.2657 245.152 49.0389 245.595 50.5851L276.835 151.203C277.278 152.749 276.392 154.295 274.952 154.737Z" fill="white" />
						<path d="M200.508 178.704C198.735 178.704 197.295 177.6 196.741 175.943L169.822 89.1313C169.49 88.1373 169.601 87.1432 170.044 86.1492L179.571 68.0358C180.014 67.1522 180.9 66.3791 181.897 66.1582L241.717 47.7135C242.049 47.603 242.492 47.4926 242.825 47.4926C244.597 47.4926 246.037 48.597 246.591 50.2537L277.831 150.872C278.163 151.866 278.052 152.97 277.498 153.854C277.055 154.737 276.169 155.51 275.172 155.731L201.615 178.483C201.283 178.594 200.84 178.704 200.508 178.704ZM242.936 49.7015C242.714 49.7015 242.603 49.7015 242.382 49.812L182.562 68.2567C182.118 68.3672 181.786 68.6985 181.565 69.1403L172.038 87.2537C171.816 87.6955 171.816 88.1373 171.927 88.5791L198.846 175.28C199.067 176.164 200.064 176.716 200.951 176.385L274.618 153.633C275.061 153.522 275.393 153.191 275.615 152.749C275.837 152.307 275.837 151.866 275.726 151.424L244.486 50.806C244.265 50.2537 243.6 49.7015 242.936 49.7015Z" fill="#0256B1" />
						<path d="M226.319 101.723C227.649 107.245 225.101 113.43 220.781 115.308C216.46 117.296 211.808 114.314 210.478 108.681C209.149 103.158 211.697 96.9733 216.017 95.0956C220.448 93.218 225.101 96.2001 226.319 101.723Z" fill="#0256B1" />
						<path d="M216.128 95.2062C214.134 96.0898 212.583 97.8569 211.586 99.9554C213.58 102.054 216.46 102.717 219.119 101.502C221.113 100.618 222.664 98.851 223.661 96.7525C221.667 94.6539 218.787 93.9913 216.128 95.2062Z" fill="#FFFCF3" />
						<path d="M181.231 67.8149C181.121 67.8149 181.121 67.8149 181.231 67.8149L170.929 87.1433C170.929 87.3642 170.818 87.4746 170.818 87.6955L183.336 83.8298C184.776 83.3881 185.552 81.8418 185.109 80.406L181.231 67.8149Z" fill="#FFFCF3" />
						<path d="M169.49 89.2414L169.712 87.5847C169.712 87.3638 169.823 87.1429 169.823 86.8116L169.933 86.5907L180.346 67.0415L180.457 66.931L181.787 65.8265L186.218 80.0743C186.55 81.0683 186.439 82.0623 185.996 83.0564C185.553 83.94 184.667 84.6026 183.781 84.934L169.49 89.2414ZM180.9 70.5758L172.814 85.8176L182.894 82.725C183.337 82.6146 183.67 82.2832 183.891 81.9519C184.113 81.5101 184.113 81.0683 184.002 80.737L180.9 70.5758Z" fill="#0256B1" />
						<path d="M229.2 82.9458C229.31 84.2712 228.424 85.3757 227.206 85.4861C225.876 85.5966 224.769 84.713 224.658 83.4981C224.547 82.1727 225.433 81.0682 226.652 80.9578C227.87 80.7369 229.089 81.6205 229.2 82.9458Z" fill="#0256B1" />
						<path d="M206.379 91.5611C206.49 92.8864 205.604 93.9909 204.385 94.1014C203.056 94.2118 201.948 93.3282 201.837 92.1133C201.727 90.7879 202.613 89.6835 203.831 89.573C205.05 89.3521 206.158 90.2357 206.379 91.5611Z" fill="#0256B1" />
						<path d="M104.464 22.3104H63.8083C62.7005 22.3104 61.7035 21.8686 60.9281 21.0955C60.1526 20.3224 59.8203 19.2179 59.8203 18.2239C59.8203 17.6716 59.9311 17.0089 60.0419 16.4567C60.9281 13.2537 63.476 10.8239 66.7993 10.1612C68.5718 9.82985 70.3442 10.0507 72.0059 10.8239C73.3352 4.6388 78.8741 0 85.41 0C91.8351 0 97.374 4.41791 98.8141 10.603C99.5896 10.4925 100.365 10.3821 101.14 10.4925C105.128 10.9343 108.23 14.2477 108.452 18.2239C108.452 19.3283 108.119 20.3224 107.344 21.0955C106.679 21.8686 105.572 22.3104 104.464 22.3104ZM68.461 11.9283C68.0179 11.9283 67.6855 11.9284 67.2424 12.0388C64.8053 12.4806 62.7005 14.3582 62.0359 16.788C61.9251 17.2298 61.8143 17.6716 61.8143 18.1134C61.8143 18.6657 61.9251 19.2179 62.3682 19.5492C62.7005 19.991 63.2544 20.2119 63.8083 20.2119H104.464C105.018 20.2119 105.572 19.991 105.904 19.5492C106.236 19.2179 106.458 18.6656 106.458 18.2239C106.347 15.2418 103.91 12.591 100.919 12.3701C100.033 12.2597 99.1465 12.3701 98.371 12.591L97.2632 12.9224L97.0417 11.8179C96.1555 6.07463 91.2812 1.87762 85.41 1.87762C79.428 1.87762 74.3322 6.40597 73.6675 12.3701L73.446 14.0269L72.1167 13.0328C71.1197 12.3701 69.7903 11.9283 68.461 11.9283Z" fill="#0256B1" />
						<path d="M381.962 21.8696H348.507C347.178 21.8696 345.849 21.3173 344.963 20.4337C344.076 19.5502 343.522 18.3352 343.633 17.1203C343.633 16.5681 343.744 15.9054 343.744 15.3532C344.409 11.7084 347.178 8.83674 350.723 7.84272C352.274 7.40093 353.936 7.40092 355.597 7.73226C357.813 3.20391 362.355 0.332275 367.561 0.332275C373.432 0.332275 378.75 4.30839 380.301 9.94121C384.067 10.1621 387.058 13.3651 387.058 17.1203C386.947 19.7711 384.732 21.8696 381.962 21.8696ZM353.271 9.49943C352.606 9.49943 351.942 9.60987 351.277 9.72032C348.397 10.4935 346.292 12.8129 345.738 15.6845C345.627 16.1263 345.627 16.5681 345.627 17.1203C345.627 17.783 345.96 18.4457 346.403 18.9979C346.957 19.5502 347.732 19.8815 348.618 19.8815H382.073C383.624 19.8815 385.064 18.6666 385.064 17.1203C385.064 14.2487 382.738 11.8188 379.858 11.8188C379.747 11.8188 379.747 11.8188 379.636 11.8188H378.75L378.528 11.0457C377.42 5.9651 372.768 2.20988 367.561 2.20988C362.909 2.20988 358.81 4.97108 357.148 9.27854L356.816 10.1621L355.93 9.83078C354.933 9.60988 354.157 9.49943 353.271 9.49943Z" fill="#0256B1" />
					</svg>
				</div>
				<div className='page-not-found__right'>
					<Title content="Страница не найдена" className='page-not-found__title' />
					<div className='page-not-found__text'>
						Уважаемый, как было показано выше, воображение изящно диссонирует
					</div>
					<Button
						text='На главную'
						callback={() => history.push('/')}
						className='page-not-found__button'
					/>
				</div>

			</div>
		</div>
	)
}
PageNotFound.propTypes = {
	title: PropTypes.string,
}
export default PageNotFound