import {
	changeProductToFoldersAction,
	deleteFolderAction,
	changeFolderAction,
	addFolderAction,
} from 'redux/actions/favoriteActions'

export default class FavoriteService {

	/**
	* Function to add favorites folder
	* @param {string} folderName folder name
	* @param {function} dispatchSaga redux method
	* @param {function} rejectCallback reject callback
	* @return {void}
	*/
	static addFolder(folderName, dispatchSaga, rejectCallback) {
		const action = addFolderAction(folderName)
		dispatchSaga(action.type, action.payload, rejectCallback)
	}

	/**
	* Function to delete favorites folder
	* @param {string} folderId folder id
	* @param {function} dispatchSaga redux method
	* @param {function} rejectCallback reject callback
	* @return {void}
	*/
	static deleteFolder(folderId, dispatchSaga, rejectCallback) {
		const action = deleteFolderAction(folderId)
		dispatchSaga(action.type, action.payload, rejectCallback)
	}

	/**
	* Function to delete favorites folder
	* @param {string} folderId folder id
	* @param {function} dispatchSaga redux method
	* @param {function} rejectCallback reject callback
	* @return {void}
	*/
	static changeFolder(params, dispatchSaga, rejectCallback) {
		const action = changeFolderAction(params)
		dispatchSaga(action.type, action.payload, rejectCallback)
	}

	/**
	* Function of adding a product to favorites
	* @param {object} params product id and folder id
	* @param {function} dispatchSaga redux method
	* @param {function} rejectCallback reject callback
	* @return {void}
	*/
	static changeProductToFolders(params, dispatchSaga, rejectCallback) {
		const action = changeProductToFoldersAction(params)
		dispatchSaga(action.type, action.payload, rejectCallback)
	}
}