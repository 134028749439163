import React, { forwardRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { useOnClickOutside } from 'hooks/useOnClickOutside'

import 'components/ui/Button/button.scss'
import './modal-action-for-uploaded-file.scss'

const ModalActionForUploadedFile = forwardRef(({ docUrl, className, closeModal }, ref) => {

	useOnClickOutside([ref], event => {
		if (ref.current && !ref.current.contains(event.target)) {
			closeModal()
		}
	})

	return (
		<div
			ref={ref}
			className={
				classNames(
					'modal-action-for-uploaded-file',
					className
				)
			}
		>
			<a
				href={docUrl}
				className='button'
				target="_blank"
				rel="noreferrer"
			>
				Открыть в новом окне
			</a>
			<a
				className='button'
				href={docUrl}
				download
			>
				Скачать
			</a>

		</div >
	)

})

ModalActionForUploadedFile.displayName = 'ModalActionForUploadedFile'

ModalActionForUploadedFile.propTypes = {
	docUrl: PropTypes.string,
	className: PropTypes.string,
	closeModal: PropTypes.func,
}

export default ModalActionForUploadedFile
