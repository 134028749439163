import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import './modal.scss'

const Modal= ({ className, classNameWrapper, children }) => {

	return (
		<div className={classNames('modal', className)}>
			<div className={classNames('modal__content', classNameWrapper)}>
				{children}
			</div>
		</div>
	)
}

Modal.propTypes = {
	className: PropTypes.string,
	classNameWrapper: PropTypes.string,
	children: PropTypes.element,
}

export default Modal
