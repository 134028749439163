import axios from 'axios'
import config from 'config'

import TokenService from 'services/TokenService'
import LocalStorageService from 'services/LocalStorageService'

export default class ApiService {

	/**
	* Makes a request to a remote server to receive data
	* @param {string} endpoint url endpoint.
	* @param {object} params Url endpoint request.
	* @param {string} method Url method request.
	* @param {string} headers Url headers request.
	* @returns {object} Remote server to receive data or instance new error
	*/
	static async request(endpoint, { params, data, method = 'GET', headers }, checkExpireToken = false) {
		
		try {

			if (!checkExpireToken) {

				await TokenService.checkExpireToken()

			}

			const tokenStorage = TokenService.getTokenStorage()
			const localStorage = LocalStorageService.get()

			const axiosConfig = {
				method: method,
				url: `/api/${endpoint}`,
				data: data,
				params: params,
				headers: {
					...(tokenStorage && tokenStorage.token ? { 'Authorization-Token': tokenStorage.token } : {}),
					...(localStorage && localStorage.agreement ? { 'Choosed-Agree': localStorage.agreement } : {}),
					...headers
				},
			}

			const result = await axios(axiosConfig)

			return result

		} catch (e) {

			throw e

		}
	}
}