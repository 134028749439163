import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './skeleton.scss'

const PaymentDocsListSkeleton = ({ rowCount, totalRow }) => {

	const renderTableRow = (key, totalRow) => {
		return (
			<tr key={key}>
				<td>
					<div className={classNames('skeleton skeleton--line skeleton__paymentDocsList skeleton__paymentDocsList--createdDate', totalRow && 'skeleton__paymentDocsList--total')}></div>
				</td>
				<td>
					<div className="skeleton skeleton--line skeleton__paymentDocsList skeleton__paymentDocsList--payDate"></div>
				</td>
				<td>
					<div className="skeleton skeleton--line skeleton__paymentDocsList skeleton__paymentDocsList--expiredDays"></div>
				</td>
				<td>
					<div className="skeleton skeleton--line skeleton__paymentDocsList skeleton__paymentDocsList--debetSum"></div>
				</td>
				<td>
					<div className="skeleton skeleton--line skeleton__paymentDocsList skeleton__paymentDocsList--expDebetSum"></div>
				</td>
				<td>
					<div className="skeleton skeleton--line skeleton__paymentDocsList skeleton__paymentDocsList--name"></div>
				</td>
			</tr>

		)
	}

	const rows = []

	while (rowCount) {
		rows.push(renderTableRow(rowCount))
		rowCount--
		if (rowCount === 0 && totalRow) {
			rows.push(renderTableRow(rowCount, totalRow))
		}
	}

	return rows

}

PaymentDocsListSkeleton.propTypes = {
	rowCount: PropTypes.number,
	totalRow: PropTypes.bool,
}

export default PaymentDocsListSkeleton