import React from 'react'
import PropTypes from 'prop-types'

const LogoPreloader = ({ className }) => {
	return (
		<svg
			className={className}
			width="92" height="60"
			viewBox="0 0 92 60" fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path d="M36.4509 16.3948L15.219 53.0712L23.2334 57.6855L44.4654 21.0092L36.4509 16.3948Z" fill="#EC6608" />
			<path d="M43.2668 50.7641H13.88C13.0684 50.7649 12.2708 50.5525 11.5676 50.1483C10.8645 49.7441 10.2806 49.1624 9.87443 48.4617C9.46829 47.7609 9.25437 46.9658 9.25418 46.1565C9.25398 45.3471 9.46751 44.5519 9.8733 43.851L25.9023 16.1616C26.3072 15.4583 26.8909 14.874 27.5946 14.4677C28.2983 14.0613 29.097 13.8474 29.9102 13.8474C30.7233 13.8474 31.5219 14.0613 32.2256 14.4677C32.9293 14.874 33.5131 15.4583 33.918 16.1616L53.9559 50.771L59.2981 60H69.9941L41.9336 11.5298C40.7152 9.42523 38.9626 7.67761 36.8523 6.46254C34.7419 5.24748 32.3481 4.60782 29.9113 4.60782C27.4745 4.60782 25.0805 5.24748 22.9702 6.46254C20.8598 7.67761 19.1074 9.42523 17.8889 11.5298L1.85992 39.2191C0.641784 41.3233 0.000312647 43.7101 1.14266e-07 46.1397C-0.000312418 48.5694 0.640492 50.9563 1.85808 53.0607C3.07568 55.1652 4.82721 56.913 6.93661 58.1287C9.04601 59.3443 11.4391 59.985 13.8753 59.9862H48.6068L43.2668 50.7641Z" fill="#003D7C" />
			<path d="M56.6296 0H45.9451L80.6765 59.9921H91.3633L56.6296 0Z" fill="#EC6608" />
		</svg>

	)

}

LogoPreloader.propTypes = {
	className: PropTypes.string,
}

export default LogoPreloader
