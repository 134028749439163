import {
	REQUEST_DELETE_BASKET_ITEM,
	REQUEST_GET_BASKET,
	REQUEST_CHANGE_BASKET_ITEM,
	REQUEST_ADD_BASKET_ITEM,
	REQUEST_DELETE_BASKET_All,
	LOADING_BASKET_FLAG_TO_DEFAULT
} from '../types/basketTypes'

export function fetchBasket() {
	return {
		type: REQUEST_GET_BASKET
	}
}

export function deleteBasketItem(id) {
	return {
		type: REQUEST_DELETE_BASKET_ITEM,
		payload: id
	}
}

export function addBasketItem(data) {
	return {
		type: REQUEST_ADD_BASKET_ITEM,
		payload: data
	}
}

export function changeQuantityBasketItem({ id, quantity, ...data }) {
	return {
		type: REQUEST_CHANGE_BASKET_ITEM,
		payload: {
			id, quantity, data
		}
	}
}

export function deleteAllBasket() {
	return {
		type: REQUEST_DELETE_BASKET_All,
		payload: []
	}
}

export function loadingBasketFlagToDefault() {
	return {
		type: LOADING_BASKET_FLAG_TO_DEFAULT
	}
}