import React, { useState } from 'react'
import PropTypes from 'prop-types'

import IconAvailability from 'assets/icons/IconAvailability'

import './availability-info.scss'

const AvailabilityInfo = ({ stores }) => {

	const [showModal, setShowModal] = useState(false)
	const toggleShowModal = () => {
		setShowModal(prev => {
			return !prev
		})
	}
	return (
		<div
			className="availability-info"
			onMouseEnter={() => toggleShowModal()}
			onMouseLeave={() => toggleShowModal()}
		>
			<IconAvailability />
			{showModal &&
				<div className="availability-info__modal">
					<div className="availability-info__title">Наличие на складах</div>
					{stores.map((store, key) => {
						return (
							<div className="availability-info__store" key={key}>
								<div className="availability-info__storeName">{store.name}</div>
								<div className="availability-info__storeValue">{`${store.free}/${store.quantity}`}</div>
							</div>

						)
					})}
				</div>
			}
		</div>
	)

}
AvailabilityInfo.propTypes = {
	stores: PropTypes.array,
}
export default AvailabilityInfo