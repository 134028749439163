const maskDate = (
	date,
	format = {
		variableDigits: 8,
		delimiters: { 2: '.', 4: '.' },
	}
) => {

	const numbers = date.replace(/\D/g, '')

	if (!numbers.length) {
		return ''
	}

	let formatted = ''

	for (let i = 0; i < numbers.length; i++) {
		formatted += (format.delimiters[i] || '') + numbers[i]
	}

	const totalDelimitersLength = Object.keys(format.delimiters).length

	return formatted.slice(
		0,
		totalDelimitersLength + format.variableDigits
	)
}
export default maskDate