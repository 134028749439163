import React, { useContext, useState, useRef, useEffect } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { REQUEST_FAILED } from 'redux/types/errorTypes'

import { AuthContext } from 'context/AuthContext'

import TokenService from 'services/TokenService'

import { useFetch } from 'hooks/useFetch'
import { useOnClickOutside } from 'hooks/useOnClickOutside'

import UserCounterpartyList from 'components/UserCounterpartyList/UserCounterpartyList'
import ModalNotificationSettings from 'components/Modals/NotificationSettings'
import AgreementsList from 'components/AgreementsList/AgreementsList'
import FinanceWidgets from 'components/FinanceWidgets/FinanceWidgets'
import Button from 'components/ui/Button/Button'
import IconArrow from 'assets/icons/IconArrow'
import IconLogout from 'assets/icons/IconLogout'

import './header-user.scss'

const HeaderUser = () => {

	const dispatch = useDispatch()

	const auth = useContext(AuthContext)
	const { user } = auth

	const { request } = useFetch()

	const changeUserBlock = useRef(null)
	const headerUserBlock = useRef(null)

	const [openChangeUser, setOpenChangeUser] = useState(false)
	const [openNotificationSettings, setOpenNotificationSettings] = useState(false)
	const [openCounterpartyOther, setOpenCounterpartyOther] = useState(false)
	const [visibleUserInfo, setVisibleUserInfo] = useState(true)
	const [сounterpartyAgreementList, setCounterpartyAgreementList] = useState(null)
	const [agreement, setAgreement] = useState(null)

	const [activeUser, setActiveUser] = useState(user)
	const [userFinances, setUserFinances] = useState(user.finances)

	useOnClickOutside([changeUserBlock], (event) => {
		if (!headerUserBlock.current.contains(event.target)) {
			setOpenChangeUser(false)
		}
	})

	const changeAuthParams = (selectUser) => {

		setCounterpartyAgreementList(selectUser.agreements)
		setOpenCounterpartyOther(false)

		if (selectUser.id === user.id) {
			setVisibleUserInfo(prev => !prev)
		} else {
			const [firstAgreement] = selectUser.agreements

			if (firstAgreement) {
				setAgreement(firstAgreement.xmlId)
			}

			setVisibleUserInfo(false)
		}

		if (activeUser.id !== selectUser.id) {
			setActiveUser(selectUser)
		}

	}

	const login = async () => {
		try {

			if (activeUser.login !== user.login) {

				const response = await request('user/change', { login: activeUser.login })
				TokenService.setTokenStorage(response.data)

			}

			auth.login(
				{
					login: activeUser.login,
					agreement: agreement
				}
			)

			setOpenChangeUser(false)
			setCounterpartyAgreementList(null)
			setVisibleUserInfo(true)

		} catch (error) {
			dispatch({ type: REQUEST_FAILED, payload: { message: error.message || 'Что-то пошло не так' } })
		}

	}

	useEffect(() => {

		setUserFinances(auth.user.finances)

	}, [auth.user.id])

	return (

		<div className="header-user" >
			<div
				ref={headerUserBlock}
				className="header-user__current"
				onClick={() => setOpenChangeUser(prev => !prev)}>
				<div className="header-user__name" title={user.name}>
					{user.name}
				</div>
				<IconArrow className={classNames('header-user__arrow', openChangeUser && 'header-user__arrow--open')} />
			</div>
			<CSSTransition
				in={openChangeUser}
				timeout={250}
				classNames={{
					enter: 'header-user__info--enter',
					enterActive: 'header-user__info--enterActive',
					exit: 'header-user__info--exit',
					exitActive: 'header-user__info--exitActive',
				}}
				unmountOnExit
				nodeRef={changeUserBlock}
			>
				<div className="header-user__info" ref={changeUserBlock}>
					<UserCounterpartyList
						changeAuthParams={changeAuthParams}
						activeUser={activeUser}
						openCounterpartyOther={openCounterpartyOther}
						toggleCounterpartyOther={value => setOpenCounterpartyOther(prev => !value ? value : !prev)}
					/>

					<Button 
						onClick={() => setOpenNotificationSettings(true)}
						text='Настройка уведомлений'
						className='header-user__notification'
					/>

					{visibleUserInfo ?
						<>
							<FinanceWidgets
								blockArrangement='column'
								layer={openCounterpartyOther}
								userFinances={userFinances}
							/>
							<div className={classNames('header-user__logout', openCounterpartyOther && 'header-user__logout--layerShadow')} onClick={auth.logout}>
								<div className="header-user__logoutText">Выйти</div>
								<IconLogout />
							</div>
						</> :
						сounterpartyAgreementList && сounterpartyAgreementList.length > 0 ?

							<div className={classNames('header-user__listAgreement', openCounterpartyOther && 'header-user__listAgreement--layerShadow')}>
								<AgreementsList
									items={сounterpartyAgreementList}
									currentCounterparty={activeUser.id === user.id ? true : false}
									currentItem={auth.agreement}
									onSelect={agreement => setAgreement(agreement)}
									layerShadow={openCounterpartyOther}
								/>
								<div
									className={classNames('header-user__login', openCounterpartyOther && 'header-user__login--layerShadow')}
									onClick={login}>
									Сохранить
								</div>
							</div> :
							<div className="header-user__listAgreement--empty">Нет активных соглашений, выберите другого пользователя</div>
					}
				</div>
			</CSSTransition>
			{
				openNotificationSettings &&
				<ModalNotificationSettings onClose={() => setOpenNotificationSettings(false)} />
			}
		</div>

	)

}

export default HeaderUser