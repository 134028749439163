import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import OrderList from 'components/OrderList/OrderList'

import './style.scss'

const Orders = ({ invisible }) => {

	return (
		<div className={classNames('container container__content', invisible && 'invisible')}>
			<div className='orders-page'>
				<div className="orders-page__orders">
					<OrderList
						title="Список заказов"
						useFilter
					/>
				</div>
			</div>
		</div>
	)
}

Orders.propTypes = {
	invisible: PropTypes.bool,
}
export default Orders