let config = {}
const prefix = 'REACT_APP_'

if (!Object.entries) {

	Object.entries = function (obj) {
		var ownProps = Object.keys(obj),
			i = ownProps.length,
			resArray = new Array(i) // preallocate the Array
		while (i--)
			resArray[i] = [ownProps[i], obj[ownProps[i]]]

		return resArray
	}

}

Object.entries(process.env).map(([key, value]) => {
	if (key.indexOf(prefix) !== -1) {
		config[key] = value
	}
})

export default config