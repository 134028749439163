import React from 'react'

import './skeleton.scss'

const RadioSkeleton = () => {

	return (
		<div className="skeleton skeleton--line skeleton__radioBtn"></div>
	)
}

export default RadioSkeleton