import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import FavoriteService from 'services/FavoriteService'
import useDispatchSaga from 'hooks/useDispatchSaga'

import Button from 'components/ui/Button/Button'
import Modal from 'components/ui/Modal'

import './style.scss'

const DeleteFavoriteFolder = ({ onClose , folderId }) => {

	const folders = useSelector(state => state.favorite.folders)
	const { dispatchSaga } = useDispatchSaga()

	const [process, setProcess] = useState(false)

	const deleteHandler = () => {
		setProcess(true)
		FavoriteService.deleteFolder(folderId, dispatchSaga, () => setProcess(false))
	}

	useEffect(() => {
		
		if(process){
			onClose()
		}

	}, [folders])
	process && 'modal-delete-favorite-folder--process'
	return (
		<Modal classNameWrapper="modal-delete-favorite-folder">
			<>
				<div
					className={classNames(
						'modal-delete-favorite-folder__title', 
						process && 'modal-delete-favorite-folder__title--process'
					)}
				>
					Вы точно хотите удалить список?
				</div>
				<div
					className={classNames(
						'modal-delete-favorite-folder__buttons', 
						process && 'modal-delete-favorite-folder__buttons--process'
					)}
				>
					<Button 
						className={classNames(
							'modal-delete-favorite-folder__button',
							'modal-delete-favorite-folder__button--yes'
						)}
						onClick={() => deleteHandler()}
						text='Да'
					/>
					<Button
						className={classNames(
							'modal-delete-favorite-folder__button',
							'modal-delete-favorite-folder__button--no'
						)}
						onClick={() => onClose()}
						text='Нет'

					/>
				</div>
			</>
		</Modal>
	)
}

DeleteFavoriteFolder.propTypes = {
	onClose: PropTypes.func.isRequired,
	folderId: PropTypes.number.isRequired,
}

export default DeleteFavoriteFolder