import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import Checkbox from 'components/ui/Checkbox/Checkbox'
import { arraySplitToChunks } from 'helpers'

import Title from 'components/ui/Title/Title'

import './style.scss'

const Section = ({ section, type, parentId, depth, changeSelectedSection }) => {

	return (
		<div className={classNames('price-list-tree__item', depth === 2 && 'price-list-tree__item--lvl2')}>
			<div className="price-list-tree__item__section">
				<Checkbox
					label={section.name}
					name={section.id}
					id={section.id}
					type={type}
					value={section.name || false}
					onChange={(e) => changeSelectedSection(parentId, section.id, e.target.checked)}
					checked={section.selected || false}
					className={
						classNames(
							'price-list-tree__item__sectionTitle',
							depth === 2 && 'price-list-tree__item__sectionTitle--bold',
							'checkbox--bold',
							!section.selected && section.children && section.children.find(children => children.selected) && 'checkbox--square'
						)
					}
				/>
			</div>

			{
				section.children &&
				<div className="price-list-tree__itemChildren">
					{section.children.map((item, key) =>
						<Section
							parentId={item.parent_id}
							type="second"
							key={key} section={item}
							changeSelectedSection={changeSelectedSection} />
					)}
				</div>
			}

		</div>

	)

}

const PriceListTree = ({ sectionTree, changeSelectedSection }) => {

	const [leftColumn, centerColumn, rightColumn] = arraySplitToChunks([...sectionTree], 3)

	return (
		sectionTree.length > 0 ?
			<div className="price-list-tree">
				<div className="price-list-tree__column">
					{

						leftColumn.map((section, key) => {
							return <Section
								changeSelectedSection={changeSelectedSection}
								key={key}
								depth={2}
								type="main"
								parentId={section.parent_id}
								section={section}
							/>
						})
					}
				</div>
				<div className="price-list-tree__column">
					{
						centerColumn.map((section, key) => {
							return <Section
								key={key}
								changeSelectedSection={changeSelectedSection}
								parentId={section.parent_id}
								depth={2}
								type="main"
								section={section}
							/>
						})
					}
				</div>
				<div className="price-list-tree__column">
					{
						rightColumn.map((section, key) => {
							return <Section
								key={key}
								changeSelectedSection={changeSelectedSection}
								parentId={section.parent_id}
								depth={2}
								type="main"
								section={section}
							/>
						})
					}
				</div>
			</div>
			:
			<div className="price-list-tree__notSelected">
				<div className="price-list-tree__wrap">
					<Title className="price-list-tree__title" content="Категория не выбрана" />
					<div className="price-list-tree__info">
						Выберите категорию, чтобы сформировать прайс-лист
						по всему разделу. Или нажмите на категорию, чтобы выбрать что-то конкретное.
					</div>
				</div>
			</div>
	)

}

PriceListTree.propTypes = {
	sectionTree: PropTypes.array,
	changeSelectedSection: PropTypes.func,
}
Section.propTypes = {
	section: PropTypes.object,
	type: PropTypes.string,
	depth: PropTypes.number,
	changeSelectedSection: PropTypes.func,
	parentId: PropTypes.string,
}
export default PriceListTree