import React from 'react'
import { useFormik } from 'formik'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import * as Yup from 'yup'

import { useFetch } from 'hooks/useFetch'

import Title from 'components/ui/Title/Title'
import Input from 'components/ui/Input/Input'
import Button from 'components/ui/Button/Button'

import './style.scss'
import CirclePreloader from 'assets/preloaders/Circle'
import IconArrow from 'assets/icons/IconArrow'

const ForgotPassFormSchema = Yup.object().shape({
	login: Yup.string().email('Значение поля должно быть email адресом')
		.required(' '),
})

const ForgotPassForm = ({ changeViewForm }) => {

	const { request } = useFetch()

	const formik = useFormik({
		validationSchema: ForgotPassFormSchema,
		initialValues: {
			login: '',
		},
		initialStatus: false,
		onSubmit: async values => {
			try {

				await request('user/forgotpassword', { login: values.login })
				formik.setStatus(true)

			} catch (error) {

				formik.setFieldError('login', error.message)

			}

		},
	})

	return (
		<form
			className="forgot-pass-form"
			onSubmit={formik.handleSubmit}
		>
			<Title
				content="Восстановить пароль"
				className="forgot-pass-form__title"
			/>
			<div className="forgot-pass-form__note">
				Укажите свой адрес электронной почты
			</div>
			<Input
				className={classNames('forgot-pass-form__input', formik.errors.login && formik.touched.login && 'forgot-pass-form__input--error')}
				placeholder="Адрес электронной почты"
				name="login"
				value={formik.values.login}
				onChange={formik.handleChange}
			/>

			<div className="forgot-pass-form__bottom">
				{
					formik.errors.login &&
					<div className="forgot-pass-form__message forgot-pass-form__message--error">
						{formik.errors.login}
					</div>
				}
				{
					formik.status &&
					<div className="forgot-pass-form__message forgot-pass-form__message--success">
						На указанный почтовый адрес было выслано письмо с дальшейшими инструкциями
					</div>
				}
				{

					!formik.status &&
					<>
						<div className="forgot-pass-form__back" onClick={() => changeViewForm('auth')}>
							<IconArrow className="forgot-pass-form__backIcon" />
							Назад
						</div>
						<Button
							type="submit"
							className="forgot-pass-form__submit"
							text={
								!formik.isSubmitting ?
									'Далее' :
									<CirclePreloader className="forgot-pass-form__preloader" />
							}
						/>
					</>

				}

			</div>

		</form>
	)
}

ForgotPassForm.propTypes = {
	changeViewForm: PropTypes.func
}

export default ForgotPassForm
