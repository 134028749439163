import {
	OPEN_SEARCH,
	CLOSE_SEARCH,
} from '../types/searchTypes'

const initialState = {
	isSearching: false
}

export const searchReducer = (state = initialState, action) => {
	switch (action.type) {
		case OPEN_SEARCH:
			return { ...state, isSearching: true }
		case CLOSE_SEARCH:
			return { ...state, isSearching: false }
		default: return state
	}
}
