import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import Button from 'components/ui/Button/Button'

import './style.scss'

const PaymentDocsListFilter = ({ filterParams, setFilterParams }) => {

	return (
		<div className="payment-docs-list-filter">
			<Button
				className={
					classNames(
						'payment-docs-list-filter__button',
						!filterParams.expired && 'payment-docs-list-filter__button--active'
					)
				}
				callback={() => setFilterParams(prevState => ({ ...prevState, expired: !prevState.expired }))}
				text="Все" />
			<Button
				className={
					classNames(
						'payment-docs-list-filter__button payment-docs-list-filter__button--last',
						filterParams.expired && 'payment-docs-list-filter__button--active'
					)
				}
				callback={() => setFilterParams(prevState => ({ ...prevState, expired: !prevState.expired }))}
				text="Просрочено" />
		</div>

	)
}

PaymentDocsListFilter.propTypes = {
	filterParams: PropTypes.object,
	setFilterParams: PropTypes.func
}

export default PaymentDocsListFilter