import React, { useState, useEffect, Fragment, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import LocalStorageService from 'services/LocalStorageService'

import { REQUEST_FAILED } from 'redux/types/errorTypes'

import { useFetch } from 'hooks/useFetch'

import { findItemToArrayRecursive } from 'helpers'

import IconArrow from 'assets/icons/IconArrow'
import CirclePreloader from 'assets/preloaders/Circle'

import './catalog-menu.scss'
const CatalogMenu = ({ sectionId, setBreadcrumbs, setFlagLoading, isFinishLoading, isDetail }) => {

	const history = useHistory()
	const dispatch = useDispatch()

	const localStorage = LocalStorageService.get()

	const { request, error } = useFetch()

	const [openSection, setOpenSection] = useState([])
	const [openSectionHistory, setOpenSectionHistory] = useState([])
	const [menuTree, setMenuTree] = useState([])
	const [loadingMenuTreeId, setLoadingMenuTreeId] = useState('')
	const [activeSectionId, setActiveSectionId] = useState(null)
	const [pathCurrentSection, setPathCurrentSection] = useState([])
	const [user, setUser] = useState(localStorage)
	const [lastSectionIdRequest, setLastSectionIdRequest] = useState(0)

	const sectionNameRefs = useRef([])

	const toggleOpenSection = (id, isSectionName = false, hasParent = false) => {

		const isOpen = openSection.find(i => i === id)

		if (!isOpen && !hasParent) {
			const [openFirstLvl] = openSection.filter((id) => menuTree.find(item => item.id === id))
			setOpenSection(prev => prev.filter(i => i !== openFirstLvl))
		}

		if (isSectionName && !isOpen) {
			setOpenSection(prev => [...prev, id])
		} else if (!isSectionName) {

			if (isOpen) {
				setOpenSection(prev => prev.filter(i => i !== id))
			} else {
				setOpenSection(prev => [...prev, id])
			}
		}
		setOpenSectionHistory(prev => [...prev, id])
	}

	const getSectionItems = async (event, item) => {
		const { id, parent_id } = item

		const targetIsNameSection = sectionNameRefs.current.includes(event.target)

		toggleOpenSection(id, targetIsNameSection, parent_id)

		const isOpen = openSection.find(i => i === item.id)
		const isOpenHistory = openSectionHistory.find(i => i === item.id)

		if (!isOpen && !isOpenHistory || targetIsNameSection || isDetail) {
			history.push(`/catalog/${id}/`)
		}
	}

	const getPathCurrentSection = (menuArray, id, initialState = []) => {

		const parentSection = findItemToArrayRecursive(menuArray, 'id', id)

		if (parentSection && parentSection.parent_id) {
			return getPathCurrentSection(menuArray, parentSection.parent_id, [...initialState, parentSection.id])
		}

		return [...initialState, parentSection.id]
	}

	const insertChildrenSection = (tree, parentId, insertChildren) => {

		return tree.map(item => {

			if (item.children) {

				item.children = insertChildrenSection(item.children, parentId, insertChildren)
			} else {
				if (item.id == parentId) {

					item = { ...item, children: insertChildren }
				}

			}

			return item

		})

	}

	const loadData = (sectionId = null) => {

		return request(
			'catalog/tree',
			{ sectionId: sectionId },
			{},
			'GET'
		)

	}

	useEffect(async () => {

		try {

			const response = await loadData()
			setMenuTree(response.data.tree)

		} catch (error) {

			dispatch({ type: REQUEST_FAILED, payload: { message: error.message || 'Что-то пошло не так' } })

		} finally {

			setFlagLoading('loadingMenu', true)

		}

	}, [])

	useEffect(async () => {

		if (sectionId && menuTree.length > 0 && sectionId !== lastSectionIdRequest) {

			setLastSectionIdRequest(sectionId)

			let currentSectionItem = findItemToArrayRecursive(menuTree, 'id', sectionId)
			let newMenuTree = null

			if (!currentSectionItem || currentSectionItem.has_children && !currentSectionItem.children) {

				try {

					setLoadingMenuTreeId(sectionId)

					const response = await loadData(sectionId)
					let { parent_id, rootId, tree } = response.data

					const changeTree = insertChildrenSection(
						menuTree.find(tree => tree.id == rootId).children,
						parent_id,
						tree
					)

					newMenuTree = menuTree.map(tree => tree.id == rootId ? { ...tree, children: changeTree } : tree)

					setMenuTree(newMenuTree)

				} catch (error) {

					dispatch({ type: REQUEST_FAILED, payload: { message: error.message } })

				} finally {
					setLoadingMenuTreeId('')
					setFlagLoading('loadingMenu', true)

				}

			}

			const currentPath = getPathCurrentSection(newMenuTree || menuTree, sectionId)

			setOpenSection(currentPath)
			setPathCurrentSection(currentPath)

			setActiveSectionId(sectionId)
		}

		const [firstSection] = menuTree

		if (!sectionId && firstSection) {
			history.push(`/catalog/${firstSection.id}/`)
		}
	}, [sectionId, menuTree])

	useEffect(() => {

		if (pathCurrentSection.length > 0) {

			const sortIds = pathCurrentSection.sort((a, b) => parseInt(a) - parseInt(b))
			let breadcrumbs = [{ id: 0, name: 'Каталог' }]

			breadcrumbs = [...breadcrumbs, ...sortIds.map(sectionId => {
				const { id, name } = findItemToArrayRecursive(menuTree, 'id', sectionId)
				return { id, name }
			})]

			setBreadcrumbs(breadcrumbs)
			if (openSection.length === 0) {
				setOpenSection(sortIds)
			}

		}

	}, [pathCurrentSection])

	useEffect(async () => {

		if (user.login !== localStorage.login || user.agreement !== localStorage.agreement) {
			try {
				setFlagLoading('loadingMenu', false)

				const response = await loadData()

				const menuTree = response.data.tree
				const [firstSection] = menuTree

				setMenuTree(menuTree)
				history.push(`/catalog/${firstSection.id}/`)
				setOpenSection([firstSection.id])

			} catch (error) {

				dispatch({ type: REQUEST_FAILED, payload: { message: error.message } })

			} finally {

				setFlagLoading('loadingMenu', true)
				setUser(localStorage)

			}

		}

	}, [localStorage.login, localStorage.agreement])

	const MenuItems = ({ items, className }) => {
		return (
			<div className={classNames('catalog-menu__subLvlItems', className)} >
				{
					items.map((item, key) => {
						const isOpen = openSection.find(i => i === item.id)
						return (
							<Fragment key={key}>

								<div
									className={classNames(
										'catalog-menu__subLvlItem',
										activeSectionId === item.id && 'catalog-menu__subLvlItem--active'
									)}
									onClick={(event) => getSectionItems(event, item)}
								>
									{item.has_children > 0 && loadingMenuTreeId.length === 0 &&
										<IconArrow
											className={classNames(
												'catalog-menu__iconArrow catalog-menu__iconArrowSubItem',
												isOpen && 'catalog-menu__iconArrowSubItem--open'
											)}
										/>
									}
									{
										loadingMenuTreeId === item.id && <CirclePreloader className="catalog-menu__preloader" />
									}

									<p className="catalog-menu__subLvlTitle">
										<span ref={el => sectionNameRefs.current[item.id] = el} className="catalog-menu__subLvlTitleText">{item.name}</span>
									</p>

								</div>

								{item.children && isOpen && <MenuItems items={item.children} />}

							</Fragment>
						)
					})
				}
			</div >
		)
	}

	MenuItems.propTypes = {
		items: PropTypes.array,
		className: PropTypes.string
	}

	return (

		isFinishLoading && !error &&
		<div className="catalog-menu">
			{
				menuTree.map((section, key) => {

					const { children, id, name } = section
					const isOpen = openSection.find(i => i === id)

					return (
						<div
							className={classNames(
								'catalog-menu__lvl1',
								children && isOpen && 'catalog-menu__lvl1--open',
								activeSectionId === id && 'catalog-menu__lvl1--active'
							)}

							key={key}
						>
							<div
								className="catalog-menu__lvl1__title"
								onClick={(event) => getSectionItems(event, section)}
								ref={el => sectionNameRefs.current[id] = el}
							>
								{name}
								{children && <IconArrow className={classNames('catalog-menu__iconArrow', isOpen && 'catalog-menu__iconArrow--open')} />}
							</div>

							{children && isOpen && <MenuItems items={children} className="catalog-menu__subLvlItems--mt21" />}
						</div >
					)
				})
			}
		</div>
	)
}
CatalogMenu.propTypes = {
	sectionId: PropTypes.string,
	setBreadcrumbs: PropTypes.func,
	setFlagLoading: PropTypes.func,
	isFinishLoading: PropTypes.bool,
	isDetail: PropTypes.bool,
}
export default CatalogMenu