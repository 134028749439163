import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import PaymentDocsList from 'components/PaymentDocsList'

import './style.scss'

const PaymentDocs = ({ invisible }) => {

	return (
		<div className={classNames('container container__content', invisible && 'invisible')}>
			<div className="payment-docs">
				<div className="payment-docs__content">
					<PaymentDocsList />
				</div>
			</div>
		</div>
	)

}

PaymentDocs.propTypes = {
	invisible: PropTypes.bool,
}
export default PaymentDocs