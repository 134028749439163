import React from 'react'
import PropTypes from 'prop-types'

const IconFavorite = ({ className, onClick }) => {
	return (
		<svg 
			className={className}
			onClick={onClick}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="inherit"
			xmlns="http://www.w3.org/2000/svg"
			stroke="inherit"
		>
			<path 
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12.0001 4.5L14.437 9.43691L19.8751 10.2334L15.9433 14.0741L16.8739 19.5L12.0001 16.9369L7.1263 19.5L8.0569 14.0741L4.12012 10.2334L9.56321 9.43691L12.0001 4.5Z"
				stroke="inherit" 
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)

}

IconFavorite.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func
}

export default IconFavorite
