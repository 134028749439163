import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import classNames from 'classnames'

import CatalogMenu from 'components/CatalogMenu/CatalogMenu'
import CatalogSection from 'components/CatalogSection/CatalogSection'
import ElementDetail from 'components/ElementDetail/ElementDetail'

import './index.scss'
import LogoPreloader from 'assets/preloaders/LogoPreloader'

const Catalog = ({ match, invisible }) => {

	const { sectionId, elementId } = match.params

	const [breadcrumbs, setBreadcrumbs] = useState([])
	const [loadingCatalogData, setLoadingCatalogData] = useState({
		loadingMenu: false,
		loadingProducts: false,
		loadingDetail: false,
	})

	const isLoadingProducts = loadingCatalogData.loadingMenu && loadingCatalogData.loadingProducts
	const isLoadingDetail = loadingCatalogData.loadingMenu && loadingCatalogData.loadingDetail
	const isLoadingMenu = loadingCatalogData.loadingMenu && (loadingCatalogData.loadingDetail || loadingCatalogData.loadingProducts)

	const setFlagLoading = (key, flag) => {
		setLoadingCatalogData(prev => ({ ...prev, [key]: flag }))
	}

	return (
		<div className={classNames('container container__content ', invisible && 'invisible')}>
			<div className="catalog">
				<CatalogMenu
					isFinishLoading={isLoadingMenu}
					sectionId={sectionId}
					setBreadcrumbs={setBreadcrumbs}
					setFlagLoading={setFlagLoading}
					isDetail={isLoadingDetail}
				/>
				<Switch>
					<Route
						strict
						exact
						path="/catalog/"
						render={() => <div className="catalog__preloader"><LogoPreloader /></div>}

					/>
					<Route
						strict
						exact
						path={`/catalog/:${sectionId}/`}
						render={() =>
							<CatalogSection
								isFinishLoading={isLoadingProducts}
								sectionId={sectionId}
								breadcrumbs={breadcrumbs}
								setFlagLoading={setFlagLoading}
								template='catalog'
							/>
						}
					/>
					<Route
						strict
						exact
						path={`/catalog/:${sectionId}/${elementId}/`}
						render={() =>
							<ElementDetail
								isFinishLoading={isLoadingDetail}
								elementId={elementId}
								breadcrumbs={breadcrumbs}
								setFlagLoading={setFlagLoading}
							/>
						}
					/>
				</Switch>
			</div>
		</div>
	)
}
Catalog.propTypes = {
	match: PropTypes.object,
	invisible: PropTypes.bool,
}
export default Catalog