import React from 'react'
import PropTypes from 'prop-types'

const UrgentIcon = ({ circleFill, pathFill }) => {
	return (
		<svg
			width="24" height="24"
			viewBox="0 0 24 24" fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<circle
				cx="12" cy="12"
				r="12" fill={circleFill || '#EDF3FA'} />
			<path d="M17.9796 8C17.7797 8 11.406 8 11.2062 8C10.6875 8 10.2516 8.38875 10.1921 8.90429L9.47558 15.1191C9.40578 15.7248 9.87968 16.2574 10.4897 16.2574H17.2631C17.7818 16.2574 18.2177 15.8686 18.2772 15.3531L18.9937 9.13835C19.0635 8.53263 18.5897 8 17.9796 8ZM15.4063 8.82027L15.2 10.2883H13.6704L13.8767 8.82027H15.4063ZM18.1788 9.04442L17.4623 15.2592C17.4506 15.3606 17.365 15.4371 17.2631 15.4371H10.4897C10.3693 15.4371 10.2767 15.332 10.2905 15.213L11.007 8.99827C11.0187 8.8968 11.1043 8.8203 11.2062 8.8203H13.0483L12.7924 10.6414C12.7578 10.8883 12.9497 11.1086 13.1986 11.1086H15.5565C15.761 11.1086 15.9342 10.958 15.9627 10.7556L16.2346 8.82027H17.9796C18.1 8.82027 18.1926 8.92543 18.1788 9.04442Z" fill={pathFill || '#0256B1'} />
			<path d="M8.28062 13.4712H5.41014C5.18363 13.4712 5 13.6548 5 13.8813C5 14.1078 5.18363 14.2915 5.41014 14.2915H8.28062C8.50713 14.2915 8.69076 14.1078 8.69076 13.8813C8.69076 13.6548 8.50715 13.4712 8.28062 13.4712Z" fill={pathFill || '#0256B1'} />
			<path d="M8.28122 11.7217H5.87498C5.64848 11.7217 5.46484 11.9053 5.46484 12.1318C5.46484 12.3583 5.64848 12.542 5.87498 12.542H8.28122C8.50772 12.542 8.69135 12.3583 8.69135 12.1318C8.69135 11.9053 8.50775 11.7217 8.28122 11.7217Z" fill={pathFill || '#0256B1'} />
			<path d="M8.28121 9.97168H6.31248C6.08598 9.97168 5.90234 10.1553 5.90234 10.3818C5.90234 10.6083 6.08598 10.792 6.31248 10.792H8.28121C8.50772 10.792 8.69135 10.6083 8.69135 10.3818C8.69135 10.1553 8.50775 9.97168 8.28121 9.97168Z" fill={pathFill || '#0256B1'} />
		</svg>
	)

}

UrgentIcon.propTypes = {
	circleFill: PropTypes.string,
	pathFill: PropTypes.string,
}

export default UrgentIcon
