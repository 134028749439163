import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './button.scss'

const Button = ({ callback, text, ...attr }) => {
	const { className, ...rest } = attr

	return (
		<button
			className={classNames(className, 'button')}
			onClick={callback}
			{...rest}
		> {text} </button>
	)
}

Button.propTypes = {
	callback: PropTypes.func,
	text: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.object,
	]),
	attr: PropTypes.object,
}
export default Button