import React, { forwardRef, useContext, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { AuthContext } from 'context/AuthContext'
import useDispatchSaga from 'hooks/useDispatchSaga'
import BasketService from 'services/BasketService'
import { priceFormatter } from 'helpers'

import IconBasket from 'assets/icons/IconBasket'
import IconDelete from 'assets/icons/IconDelete'
import CirclePreloader from 'assets/preloaders/Circle'

import './header-basket.scss'

const HeaderBasket = forwardRef(({ className, onOpen, onClose }, ref) => {

	const { dispatchSaga } = useDispatchSaga()
	const dispatch = useDispatch()

	const auth = useContext(AuthContext)

	const [userId, setUserId] = useState('')
	const [agreementId, setAgreementId] = useState('')

	const { basket, totalPrice, basketLoading } = useSelector(state => state.basket)

	const count = basket.length
	const isFixed = onClose

	useEffect(() => {

		if (userId.length === 0 && agreementId.length === 0) {

			BasketService.getBasket(dispatch)

		} else {

			BasketService.deleteAllBasket(dispatchSaga)

		}
		setUserId(auth.user.id)
		setAgreementId(auth.agreement)

	}, [auth.user.id, auth.agreement])

	return (

		<div
			className={classNames('header-basket', className, isFixed && 'header-basket--open')}
			ref={ref}
			onClick={() => {
				!isFixed && location.pathname !== '/checkout/' && count > 0 && onOpen()
			}}
		>
			<div className="header-basket__icon" >
				{count > 0 && <div className="header-basket__icon--count">{count}</div>}
				<IconBasket className={classNames('header-basket__icon--colorThemeMain', count === 0 && 'header-basket__icon--colorEmpty')} />
			</div>
			<div className={classNames('header-basket__total', isFixed ? 'header-basket__total--bold' : '')}>
				{basketLoading ? priceFormatter(totalPrice) : <CirclePreloader className="header-basket__iconPreloader" />}
			</div>
			{
				isFixed &&
				<div className="header-basket__close">
					<IconDelete
						className="header-basket__close--icon"
						onClick={() => onClose()} />
				</div>
			}

		</div >

	)

})

HeaderBasket.propTypes = {
	className: PropTypes.string,
	onOpen: PropTypes.func,
	onClose: PropTypes.func,
}
HeaderBasket.displayName = 'HeaderBasket'
export default HeaderBasket