import React from 'react'

import './skeleton.scss'

export default function ContactInfoSkeleton() {
	return (

		<div className="skeleton skeleton__contact">
			<div className="skeleton--line skeleton__contact--postcode">

			</div>
			<div className="skeleton--line skeleton__contact--address">

			</div>
			<div className="skeleton--line skeleton__contact--schedule-title">

			</div>
			<div className="skeleton--line skeleton__contact--schedule-info">
			</div>
		</div>

	)
}
