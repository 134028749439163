import {
	changeQuantityBasketItem,
	addBasketItem,
	deleteBasketItem,
	fetchBasket,
	deleteAllBasket,
	loadingBasketFlagToDefault
} from 'redux/actions/basketActions'

import { priceFormatter } from 'helpers'

export default class BasketService {

	/**
	* Function of adding an item to the cart
	* @param {array} basketProducts products in the basket
	* @param {object} item added product
	* @param {function} dispatch redux method
	* @return {void}
	*/
	static addToBasket(basketInProduct, item, dispatchSaga, rejectCallback) {
		if (basketInProduct) {

			const action = changeQuantityBasketItem(
				{ ...basketInProduct, quantity: basketInProduct.quantity + item.quantity }
			)
			dispatchSaga(action.type, action.payload, rejectCallback)

		} else {

			const action = addBasketItem(item)
			dispatchSaga(action.type, action.payload, rejectCallback)

		}
	}

	/**
	* Function of removing an item from the cart
	* @param {number} id id of the item in the cart
	* @param {function} dispatch redux method
	* @return {void}
	*/
	static deleteToBasket(id, dispatchSaga, rejectCallback) {

		const action = deleteBasketItem(id)
		dispatchSaga(action.type, action.payload, rejectCallback)

	}

	/**
	* Function for change the quantity of item in the cart
	* @param {object} item item in the cart
	* @param {function} dispatch redux method
	* @return {void}
	*/
	static changeQuantityBasketItem(item, dispatchSaga, rejectCallback) {

		const action = changeQuantityBasketItem(item)
		dispatchSaga(action.type, action.payload, rejectCallback)

	}

	/**
	* Function of formatting the total cost of items in the cart
	* @param {array} basketProducts items in the cart
	* @return {number} total cost of items in the cart
	*/
	static totalPrice(basketProducts) {
		return priceFormatter(basketProducts.reduce((total, arr) => total + parseFloat(arr.price * arr.quantity), 0))
	}

	/**
	* Function to get the user's basket
	* @param {function} dispatch redux method
	* @return {void}
	*/
	static getBasket(dispatch) {
		dispatch(fetchBasket())
	}

	/**
	* Function to remove all products from the cart
	* @param {function} dispatch redux method
	* @return {void}
	*/
	static deleteAllBasket(dispatchSaga, rejectCallback) {

		const action = deleteAllBasket()
		dispatchSaga(action.type, action.payload, rejectCallback)

	}

	/**
	* Function set the basket loading flag to the default state
	* @param {function} dispatch redux method
	* @return {void}
	*/
	static loadingBasketFlagToDefault(dispatch) {
		dispatch(loadingBasketFlagToDefault())
	}

}