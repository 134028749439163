import React from 'react'

import './style.scss'

const Footer = () => {

	return (
		<div className='footer'>
			<div className='footer__copyright'>
				Разработано в&nbsp;
				<a
					href="https://olprime.ru/"
					rel="noreferrer"
					target="_blank"
					className='footer__copyrightLink'
				>
					Olprime
				</a>
			</div>
		</div>

	)

}
export default Footer