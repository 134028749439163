import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { useFetch } from 'hooks/useFetch'
import LocalStorageService from 'services/LocalStorageService'
import { REQUEST_FAILED } from 'redux/types/errorTypes'

import ContactInfo from 'components/ContactInfo/ContactInfo'
import Map from 'components/ui/Map/Map'
import ContactInfoSkeleton from 'components/ui/skeletons/ContactInfoSkeleton'

import './style.scss'

const Contact = ({ invisible }) => {

	const [contact, setContact] = useState([])
	const [contactLoading, setContactLoading] = useState(false)
	const { request, error } = useFetch()
	const dispatch = useDispatch()
	const localStorage = LocalStorageService.get()

	useEffect(async () => {
		try {

			if (contactLoading) {
				setContactLoading(false)
				setContact([])
			}

			const response = await request('pages/contacts')

			const contact = response.data.list.map((item, key) => {

				return {
					...item,
					map: {
						element: `map-${key}`,
						placemarks: [item.map.placemarks],
						option: {
							...item.map.options,
							controls: ['zoomControl'],
							behaviors: ['drag', 'dblClickZoom', 'rightMouseButtonMagnifier', 'multiTouch']
						}
					}
				}
			})

			setContact(contact)
		} catch (error) {
			dispatch({ type: REQUEST_FAILED, payload: { message: error.message } })
		} finally {
			setContactLoading(true)
		}

	}, [localStorage.login, localStorage.agreement])

	return (
		<div className={classNames('container container__content', invisible && 'invisible')}>
			<div className="contact">
				{!error && contact.length > 0 ?
					contact.map((contact, key) => {
						const {
							postcode,
							name,
							schedule
						} = contact
						return (
							<div
								className="contact__item"
								key={key}>

								{postcode || name || schedule ?
									<ContactInfo
										postcode={postcode}
										address={name}
										schedule={schedule}
									/>
									:
									<ContactInfoSkeleton />
								}

								<Map
									options={contact.map || {}}
									mapID={`contact-map-${key}`}
								/>

							</div>
						)
					}) :
					contactLoading && <div className="contact__empty">Нет информации</div>

				}

			</div>
		</div>
	)
}

Contact.propTypes = {
	invisible: PropTypes.bool,
}
export default Contact