import React from 'react'

import './skeleton.scss'

const PaginationSkeleton = () => {

	return (
		<div className="skeleton skeleton--line skeleton__pagination"></div>
	)
}

export default PaginationSkeleton