import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import FavoriteService from 'services/FavoriteService'
import useDispatchSaga from 'hooks/useDispatchSaga'

import Button from 'components/ui/Button/Button'
import Input from 'components/ui/Input/Input'

import './favorite-new-folder.scss'

const FavoriteNewFolder = ({ classNameNewFolder, classNameAddFolder, callback }) => {

	const { folders } = useSelector(state => state.favorite)
	const { dispatchSaga } = useDispatchSaga()

	const [processAddNewFolder, setProcessAddNewFolder] = useState(false)
	const [showAddNewFolder, setShowAddNewFolder] = useState(false)
	const [newFolderName, setNewFolderName] = useState('')

	const addHandler = () => {
		setProcessAddNewFolder(true)
		FavoriteService.addFolder(newFolderName, dispatchSaga, () => setProcessAddNewFolder(false))
	}

	useEffect(() => {

		if(processAddNewFolder){
			setProcessAddNewFolder(false)
			setShowAddNewFolder(false)
			setNewFolderName('')
			
		}

	}, [folders])

	useEffect(() => callback && callback(showAddNewFolder), [showAddNewFolder])

	return (
		<div className={classNames('favorite-new-folder')}>
			{
				showAddNewFolder ?
					<div 
						className={classNames(
							'favorite-new-folder__add',
							folders.length > 0 && 'favorite-new-folder__add--notEmpty',
							processAddNewFolder && 'favorite-new-folder__add--proccess',
							classNameNewFolder
						)}
					>
						<div className='favorite-new-folder__addTitle'>
							Добавление нового списка
						</div>
						<Input
							className='favorite-new-folder__addInput'
							placeholder="Введите название списка"
							value={newFolderName}
							onChange={(e) => setNewFolderName(e.target.value)}
						/>
						<Button 
							text='Сохранить'
							className={classNames(
								'favorite-new-folder__button',
								'favorite-new-folder__button--save',
								newFolderName.length === 0 && 'favorite-new-folder__button--disable',
							)}
							onClick={() => newFolderName.length > 0 && addHandler()}
						/>
					</div>
					:
					<Button 
						text='Добавить список'
						className={classNames(
							'favorite-new-folder__button',
							'favorite-new-folder__button--add',
							classNameAddFolder
						)}
						onClick={() => setShowAddNewFolder(true)}
					/>
			}
		</div>
	)
}

FavoriteNewFolder.propTypes = {
	classNameNewFolder: PropTypes.string,
	classNameAddFolder: PropTypes.string,
	callback: PropTypes.func,
}

export default FavoriteNewFolder
