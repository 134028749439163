import React from 'react'
import PropTypes from 'prop-types'

const IconPencil = ({ className, onClick }) => {
	return (
		<svg
			className={className}
			onClick={onClick}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			stroke="#969FA8"
		>
			<path 
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.5556 4L20 8.44444L8.44444 20H4V15.5556L15.5556 4Z"
				stroke="inherit"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)

}

IconPencil.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func
}

export default IconPencil