import { useHistory } from 'react-router-dom'
import { Parser } from 'html-to-react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import IconArrow from 'assets/icons/IconArrow'

import './breadcrumbs.scss'

const Breadcrumbs = ({ root, path, className }) => {

	const history = useHistory()
	
	const htmlToReactParser = new Parser()

	return (
		<div className={classNames('breadcrumbs', className)}>
			{
				path.map((item, index) => {
					const lastItem = index + 1 === path.length
					const firstItem = index === 0
					const link = item.link ? item.link : item.id !== 0 ? `${root}${item.id}/` : root

					return (
						<div className="breadcrumbs__item" key={item.id}>
							<div
								className={
									classNames(
										'breadcrumbs__itemTitle',
										lastItem && !item.link && 'breadcrumbs__itemTitle--cursorDefault',
										firstItem && !item.link && 'breadcrumbs__itemTitle--cursorDefault'
									)
								}
								onClick={() => ((!lastItem && !firstItem) || item.link) && history.push(link)}

							>
								{htmlToReactParser.parse(item.name)}
							</div>
							{!lastItem && <IconArrow className="breadcrumbs__arrow" />}
						</div>
					)
				})
			}
		</div>
	)
}
Breadcrumbs.propTypes = {
	path: PropTypes.array.isRequired,
	root: PropTypes.string,
	className: PropTypes.string,
}
export default Breadcrumbs
