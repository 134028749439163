import React from 'react'
import PropTypes from 'prop-types'

import './skeleton.scss'

const RestsSkeleton = ({ height }) => {

	return (
		<div className="skeleton skeleton--line skeleton__rests" style={{ height }}></div>
	)
}
RestsSkeleton.propTypes = {
	height: PropTypes.number,
}
export default RestsSkeleton