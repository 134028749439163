/**
* Method for converting a number into a currency format
* @param {number} number number.
* @returns {parseFloat} returns a floating point number in currency format
*/
const priceFormatter = (number, toFixed = 2) => {

	const price = parseFloat(number).toFixed(toFixed)

	if (toFixed !== 0 && Number(price.slice(-toFixed)) === 0) {
		return `${price.slice(0, price.length - (toFixed + 1))
			.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} р.`
	}

	return `${price.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')} р.`
}

export default priceFormatter