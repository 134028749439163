import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { AuthContext } from 'context/AuthContext'

import { useDocumentTitle } from 'hooks/useDocumentTitle'

import AuthForm from 'components/AuthForm'
import CounterpartyForm from 'components/CounterpartyForm/CounterpartyForm'
import ForgotPassForm from 'components/ForgotPassForm'
import ChangePasswordForm from 'components/ChangePasswordForm'
import AuthPageContentBlock from 'components/AuthPageContentBlock'

import './style.scss'

const Auth = ({ title }) => {

	useDocumentTitle(title)

	const history = useHistory()

	const auth = useContext(AuthContext)

	const [viewForm, setViewForm] = useState('auth')

	const getParams = new URLSearchParams(history.location.search)

	const getParamsChangePassword = getParams.get('change_password')
	const getParamsUserCheckword = getParams.get('USER_CHECKWORD')
	const getParamsUserLogin = getParams.get('USER_LOGIN')

	const issetGetParamsChangePassword = getParamsChangePassword && getParamsChangePassword.length > 0
	const issetGetParamsUserCheckword = getParamsUserCheckword && getParamsUserCheckword.length > 0
	const issetGetParamsUserLogin = getParamsUserLogin && getParamsUserLogin.length > 0

	return (
		<div className="auth">
			<AuthPageContentBlock changeViewForm={type => setViewForm(type)} />
			{
				issetGetParamsChangePassword && issetGetParamsUserCheckword && issetGetParamsUserLogin ?
					<ChangePasswordForm userCheckword={getParamsUserCheckword} userLogin={getParamsUserLogin} /> :
					viewForm === 'forgotPass' ?
						<ForgotPassForm changeViewForm={type => setViewForm(type)} /> :
						!auth.token ?
							<AuthForm changeViewForm={type => setViewForm(type)} /> :
							<CounterpartyForm />
			}

		</div >
	)
}
Auth.propTypes = {
	title: PropTypes.string,
}
export default Auth