import React, { useState, useRef, useCallback, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import IconArrow from 'assets/icons/IconArrow'

import './select.scss'

const Select = ({ onChange, placeholder, items, defaultValue, className, disabled = false, icon, hidePlaceholder = true, setDetailOrdersId, setDetailOrders }) => {

	const [open, setOpen] = useState(false)
	const [selectValue, setSelectValue] = useState(defaultValue || placeholder)

	const selectTitle = useRef(null)

	useEffect(() => {

		items.map(item => item.name == selectValue ? item.selected = true : item.selected = false)

	}, [selectValue, items])

	const changeValue = (item) => {
		setSelectValue(item.name)
		if (onChange) {
			onChange(item)
			setDetailOrdersId && setDetailOrdersId([])
			setDetailOrders && setDetailOrders([])
		}

	}

	const toggleOpenSelect = useCallback(event => {

		if (event.target === selectTitle.current) {

			setOpen(prev => !prev)
		} else {
			setOpen(false)
		}

	}, [])

	useEffect(() => {

		window.addEventListener('click', toggleOpenSelect)

		return () => {
			window.removeEventListener('click', toggleOpenSelect)
		}

	}, [toggleOpenSelect])

	return (
		<div className={classNames('select', open && 'select--open', className)} >
			<div
				className="select__title"
				ref={selectTitle}
			>
				<div className={classNames('select__titleText', selectValue === placeholder && 'select__titleText--placeholder')}>
					{!hidePlaceholder && selectValue !== placeholder ? <><div className="select__titlePlaceholder">{placeholder}</div>{selectValue}</> : selectValue}
				</div>
				{
					icon ? icon :
						<IconArrow className={classNames('select__icon', open && !disabled ? 'select__icon--open' : '')} />
				}

			</div>
			{open && !disabled && <div className="select__options">
				{items.map((item, i) => {
					return (
						<div
							key={i}
							className={classNames('select__option', item.selected ? 'select__option--selected' : '')}
							onClick={() => changeValue(item)}
						>
							{item.name}
						</div>
					)
				})}
			</div>}
		</div>
	)
}

Select.propTypes = {
	placeholder: PropTypes.string,
	items: PropTypes.array.isRequired,
	onChange: PropTypes.func,
	defaultValue: PropTypes.oneOfType([
		PropTypes.string,
		PropTypes.number,
	]),
	className: PropTypes.string,
	disabled: PropTypes.bool,
	icon: PropTypes.object,
	hidePlaceholder: PropTypes.bool,
	setDetailOrdersId: PropTypes.func,
	setDetailOrders: PropTypes.func,
}

export default Select
