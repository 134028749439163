import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import LocalStorageService from 'services/LocalStorageService'

import { REQUEST_FAILED } from 'redux/types/errorTypes'

import { useFetch } from 'hooks/useFetch'

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'

import ProductsTable from 'components/ProductsTable/ProductsTable'
import Pagination from 'components/ui/Pagination/Pagination'

import './catalog-section.scss'
import LogoPreloader from 'assets/preloaders/LogoPreloader'
import IconArrow from 'assets/icons/IconArrow'

const CatalogSection = ({ sectionId, breadcrumbs, setFlagLoading, isFinishLoading }) => {

	const dispatch = useDispatch()
	const history = useHistory()

	const localStorage = LocalStorageService.get()

	const { request, error } = useFetch()

	const paginationSettingInitialState = {
		currentPage: 1,
		elementCount: null,
		pagesCount: null,
	}

	const [items, setItems] = useState([])
	const [localFinishLoading, setLocalFinishLoading] = useState(false)
	const [paginationSetting, setPaginationSetting] = useState(paginationSettingInitialState)
	const [filterAvailability, setFilterAvailability] = useState(false)
	const [user, setUser] = useState(localStorage)
	const [errorText, setErrorText] = useState(null)
	const [scrollPage, setScrollPage] = useState(0)

	//Загрузка данных и построение пагинации
	const loadDataWithPagination = async (newPage = false, inAvailability = false) => {
		try {

			if (localFinishLoading) {
				setLocalFinishLoading(false)
			}

			setItems([])
			const getParams = new URLSearchParams(history.location.search)
			const getParamsPage = getParams.get('page')

			const { currentPage } = paginationSetting
			const page = newPage || getParamsPage || currentPage
			const res = await request(
				'catalog/products',
				{
					sectionId: sectionId,
					navPage: page,
					...(inAvailability && { 'filter[availability]': inAvailability }),
				})
			const { list, navigation } = res.data

			setItems(list)

			if (filterAvailability !== inAvailability) {
				setFilterAvailability(inAvailability)
				LocalStorageService.set({ ...localStorage, inAvailability: inAvailability })
			}

			if (navigation.pagesCount > 1) {
				setPaginationSetting(navigation)
			}

			if (newPage) {
				history.push({
					search: `?page=${newPage}`
				})
			}

		} catch (error) {

			setErrorText(error.message)

		} finally {
			setLocalFinishLoading(true)
		}

	}
	const handleScroll = () => setScrollPage(window.pageYOffset)

	useEffect(() => {
		window.addEventListener('scroll', handleScroll, true)
		loadDataWithPagination(false, localStorage.inAvailability)
		return () => {
			window.removeEventListener('scroll', handleScroll)
		}
	}, [])

	//Загрузка данных при смене раздела или соглашения
	useEffect(() => {

		if (isFinishLoading || !isFinishLoading && (user.login !== localStorage.login || user.agreement !== localStorage.agreement)) {

			loadDataWithPagination(false, localStorage.inAvailability)
			setPaginationSetting(paginationSettingInitialState)
			setUser(localStorage)

		}

	}, [sectionId])

	// Вывод ошибки при полной загрузке дерева разделов
	useEffect(() => {

		if (isFinishLoading && errorText) {
			dispatch({ type: REQUEST_FAILED, payload: { message: errorText } })
		}

	}, [errorText, isFinishLoading])

	useEffect(() => {

		if (!isFinishLoading) {
			if (breadcrumbs.length > 0) {

				setFlagLoading('loadingProducts', true)

			}

		}

	}, [items, breadcrumbs])

	const currentSection = breadcrumbs[breadcrumbs.length - 1]

	return (
		<div className="catalog-section">
			{
				isFinishLoading && !error && currentSection && localFinishLoading ?
					<>
						{breadcrumbs.length > 0 &&

							<Breadcrumbs root='/catalog/' path={breadcrumbs} />

						}

						<ProductsTable
							products={items}
							sectionId={sectionId}
							title={currentSection && currentSection.name || ''}
							inAvailability={filterAvailability}
							filterProduct={(availability) => loadDataWithPagination(false, availability)}
						/>

						{
							paginationSetting.pagesCount > 1 &&
							<Pagination
								setting={paginationSetting}
								callback={page => loadDataWithPagination(page, localStorage.inAvailability)}
								className='catalog-section__pagination'
								visibleSkeleton={false}
							/>
						}
						{
							scrollPage > 80 &&
							<div
								className="catalog-section__toTop"
								onClick={() => window.scrollTo({
									top: 0,
									behavior: 'smooth'
								})}
							>
								<IconArrow className="catalog-section__toTopIcon" />
							</div>
						}

					</> :
					isFinishLoading && error ?
						<div className="catalog-section__error">Ошибка загрузки данных</div> :
						<div className="catalog__preloader">
							<LogoPreloader />
						</div>

			}

		</div >
	)
}
CatalogSection.propTypes = {
	sectionId: PropTypes.string,
	breadcrumbs: PropTypes.array,
	setFlagLoading: PropTypes.func,
	isFinishLoading: PropTypes.bool,
}
export default CatalogSection
