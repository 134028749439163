import React from 'react'
import PropTypes from 'prop-types'

const DefaultIcon = ({ circleFill, pathFill }) => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<circle
				cx="12" cy="12"
				r="12" fill={circleFill || '#EDF3FA'} />
			<path d="M15.2357 8.34507L17.8123 8.53222L18 5.96329L17.117 5.89916L17.0432 6.90987C15.6286 5.38406 13.6368 4.5 11.5224 4.5C7.37451 4.5 4 7.86451 4 12C4 16.1355 7.37457 19.5 11.5225 19.5C14.0306 19.5 16.3663 18.2561 17.7704 16.1725L17.0355 15.6802C15.7962 17.5193 13.7353 18.6173 11.5225 18.6173C7.86274 18.6173 4.88535 15.6488 4.88535 12C4.88535 8.35122 7.86271 5.38271 11.5224 5.38271C13.4045 5.38271 15.1764 6.17742 16.4267 7.54649L15.3 7.46467L15.2357 8.34507Z" fill={pathFill || '#0256B1'} />
			<path d="M7.82748 12.8287C7.82748 12.545 8.11213 12.3608 8.51655 12.1567C8.98088 11.9227 9.30543 11.6539 9.30543 11.0764C9.30543 10.3943 8.86602 9.97119 8.07713 9.97119C7.39806 9.97119 6.89376 10.2948 6.67405 10.5437L7.16839 11.0714C7.3481 10.8872 7.64774 10.6781 7.96729 10.6781C8.22691 10.6781 8.39669 10.8175 8.39669 11.0863C8.39669 11.5045 7.98225 11.6837 7.55785 11.8729C6.83884 12.1965 6.66406 12.6246 6.66406 13.5456H9.31046V12.8287H7.82748Z" fill={pathFill || '#0256B1'} />
			<path d="M11.8669 12.0868V10.0059H11.3177L9.66992 12.0868V12.7987H11.068V13.5404H11.8669V12.7987H12.3063V12.0868H11.8669ZM11.1579 12.0868H10.4638L11.1579 11.2006V12.0868Z" fill={pathFill || '#0256B1'} />
			<path d="M14.4694 10.8824C14.0849 10.8824 13.7504 11.0616 13.5606 11.3703V9.91162H12.7617V13.5458H13.5606V12.0473C13.6605 11.7685 13.9252 11.5844 14.1149 11.5844C14.3446 11.5844 14.4943 11.7387 14.4943 12.0772V13.5458H15.2933V11.8731C15.2932 11.4201 15.1734 10.8824 14.4694 10.8824Z" fill={pathFill || '#0256B1'} />
		</svg>
	)

}

DefaultIcon.propTypes = {
	circleFill: PropTypes.string,
	pathFill: PropTypes.string,
}

export default DefaultIcon
