import React, { useContext, useState, useRef } from 'react'
import { CSSTransition } from 'react-transition-group'
import PropTypes from 'prop-types'

import { AuthContext } from 'context/AuthContext'

import UserCounterparty from 'components/UserCounterparty/UserCounterparty'

import './user-counterparty-list.scss'

const UserCounterpartyList = (
	{
		changeAuthParams,
		activeUser,
		openCounterpartyOther,
		toggleCounterpartyOther
	}
) => {

	const auth = useContext(AuthContext)

	const [activeCounterparty, setActiveCounterparty] = useState(auth.user.children)

	const currentAgreement = activeUser.agreements.find(agreement => agreement.xmlId === auth.agreement)

	const userListBlock = useRef(null)

	const openAgreementsUser = (selectUser) => {

		if (activeUser.id !== selectUser.id) {

			const childrenUserOther = activeCounterparty.filter(item => item.id !== selectUser.id)

			activeCounterparty.forEach(item => item.children = [activeUser, ...childrenUserOther])

			const counterparty = [
				...activeCounterparty.filter(counterparty => counterparty.id !== selectUser.id),
				{
					id: activeUser.id,
					name: activeUser.name,
					login: activeUser.login,
					agreements: activeUser.agreements,
					children: [activeUser, ...childrenUserOther]

				}
			]
			setActiveCounterparty(counterparty)
		}

		changeAuthParams(selectUser)
	}

	return (
		<div className='user-counterparty-list'>
			<UserCounterparty
				currentAgreement={currentAgreement && auth.user.id === activeUser.id ? currentAgreement.name : 'Соглашение не выбрано'}
				user={activeUser}
				open={openCounterpartyOther}
				toggleList={toggleCounterpartyOther}
				changeAgreement={selectUser => openAgreementsUser(selectUser)}
				activeCounterparty={activeCounterparty}
			/>
			<CSSTransition
				in={openCounterpartyOther}
				timeout={500}
				classNames={{
					enter: 'user-counterparty-listOther--enter',
					enterActive: 'user-counterparty-listOther--enterActive',
					exit: 'user-counterparty-listOther--exit',
					exitActive: 'user-counterparty-listOther--exitActive',
				}}
				unmountOnExit
				nodeRef={userListBlock}
			>
				<div className="user-counterparty-list user-counterparty-listOther" ref={userListBlock}>
					{activeCounterparty.map(item => {
						return <UserCounterparty
							key={item.id}
							user={item}
							changeUser={selectUser => openAgreementsUser(selectUser)}
							activeCounterparty={activeCounterparty}
						/>
					})}
				</div>
			</CSSTransition>
		</div>
	)
}
UserCounterpartyList.propTypes = {
	changeAuthParams: PropTypes.func,
	activeUser: PropTypes.object,
	openCounterpartyOther: PropTypes.bool,
	toggleCounterpartyOther: PropTypes.func,
}
export default UserCounterpartyList 