import { useDispatch, useSelector } from 'react-redux'
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import classNames from 'classnames'

import { fetchFolderList } from 'redux/actions/favoriteActions'
import { AuthContext } from 'context/AuthContext'

import IconFavorite from 'assets/icons/IconFavorite'

import './header-favorite.scss'

const HeaderFavorite = () => {

	const folders = useSelector(state => state.favorite.folders)
	const auth = useContext(AuthContext)
	const dispatch = useDispatch()
	const history = useHistory()

	const toFavoriteCount = folders.reduce(function (acc, item) { return acc + item.count }, 0)

	useEffect(() => dispatch(fetchFolderList()), [auth.user.id, auth.agreement])

	return (
		<div
			onClick={() => history.push('/personal/favorite/')}
			className='header-favorite'
		>
			<IconFavorite
				className={classNames(
					'header-favorite__icon',
					toFavoriteCount > 0 && 'header-favorite__icon--notEmpty'
				)}
			/>
			{toFavoriteCount > 0 && <div className='header-favorite__count'>{toFavoriteCount}</div>}
			
		</div>
	)
}

export default HeaderFavorite