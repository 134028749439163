import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import { AuthContext } from 'context/AuthContext'

import OrderList from 'components/OrderList/OrderList'
import ManagerInfo from 'components/ManagerInfo/ManagerInfo'
import FinanceWidgets from 'components/FinanceWidgets/FinanceWidgets'

import './style.scss'

const MainPage = ({ invisible }) => {

	const auth = useContext(AuthContext)

	useEffect(() => {

		setManagerInfo(auth.user.manager)
		setUserFinances(auth.user.finances)

	}, [auth.user.id])

	const [managerInfo, setManagerInfo] = useState(auth.user.manager)
	const [userFinances, setUserFinances] = useState(auth.user.finances)

	const isManagerInfo = Object.keys(managerInfo).length !== 0

	return (
		<div className={classNames('container container__content', invisible && 'invisible')}>
			<div className="main-page">
				<div className="main-page__orders">
					<OrderList limit={4} title='Список заказов' />
				</div>
				<div className="main-page__widgets">
					{isManagerInfo && <ManagerInfo className="main-page__managerInfo" managerInfo={managerInfo} />}
					<FinanceWidgets blockArrangement={isManagerInfo ? 'tile' : 'row'} userFinances={userFinances} />
				</div>

			</div>
		</div>
	)
}

MainPage.propTypes = {
	invisible: PropTypes.bool,
}

export default MainPage