import React from 'react'
import PropTypes from 'prop-types'

const IconDecrease = ({ className, onClick }) => {
	return (
		<svg
			className={className}
			onClick={onClick}
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.6666 8L3.33325 8"
				stroke="#1D2023"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

IconDecrease.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,

}

export default IconDecrease