import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Title from 'components/ui/Title/Title'

import './manager-info.scss'

const ManagerInfo = ({ className, managerInfo }) => {

	const [lastName, ...otherName] = managerInfo.name.split(' ')
	const managerPhones = typeof managerInfo.phone === 'string' ? [managerInfo.phone] : managerInfo.phone

	return (
		<div className={classNames('manager-info', className)} >
			{
				<>
					<Title
						content="Ваш менеджер"
						type="h2"
						className="manager-info__title"
					/>

					<div className="manager-info__fio">
						<div className="manager-info__fio--last-name">
							{lastName}
						</div>
						<div className="manager-info__fio--other-name">
							{otherName.join(' ')}
						</div>
					</div>
					{
						managerPhones.map((phone, key, arr) =>
							<div
								className={`manager-info__phone manager-info--${arr.length - 1 !== key ? 'mb-4' : 'mb-15'}`}
								key={key}>{phone}</div>
						)
					}
					<div className="manager-info__email">
						<a className="manager-info__emailLink" href={`mailto:${managerInfo.email}`}>{managerInfo.email}</a>
					</div>

				</>
			}
		</div>
	)
}
ManagerInfo.propTypes = {
	className: PropTypes.string,
	managerInfo: PropTypes.object,
}
export default ManagerInfo