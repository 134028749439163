import ApiService from 'services/ApiService'

const storageName = 'tokenStorage'

class TokenService {

	static getTokenStorage() {
		return JSON.parse(localStorage.getItem(storageName))
	}

	static async getToken(pathRequest, login, password) {
		try {

			const response = await ApiService.request(pathRequest, { params: { 'login': login, 'password': password } })
			TokenService.setTokenStorage(response.data)

		} catch (error) {

			throw error

		}
	}

	static async checkExpireToken() {

		const tokenStorage = TokenService.getTokenStorage()

		if (tokenStorage && tokenStorage.expire - Math.floor(Date.now() / 1000) <= 600) {
			await TokenService.refreshToken()
		}
	}

	static async refreshToken() {
		try {

			const { refreshToken } = TokenService.getTokenStorage()
			const response = await ApiService.request('token/refresh', { params: { 'refreshtoken': refreshToken } }, true)

			TokenService.setTokenStorage(response.data)

		} catch (e) {
			if (e.code === 'invalid_grant') {
				TokenService.removeStorage()
			}
		}
	}

	static setTokenStorage(tokenData) { localStorage.setItem(storageName, JSON.stringify(tokenData)) }

	/**
	 * Remove localStorage info
	 * 
	 * @return {void}
	 * 
	*/
	static removeStorage() {
		localStorage.removeItem(storageName)
	}
}

export default TokenService