import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import IconLogo from 'assets/icons/IconLogo'

import './header-nav.scss'

const headerNav = () => {

	const history = useHistory()

	return (
		<div className='header-nav'>
			<IconLogo
				className="header-nav__logo"
				onClick={() => history.push('/')} />
			<div className="header-nav__links">

				<NavLink
					className="header-nav__link"
					activeClassName="header-nav__link--selected"
					to='/orders/'
				>
					Заказы
				</NavLink>

				<NavLink
					className="header-nav__link"
					activeClassName="header-nav__link--selected"
					to='/payment-docs/'
				>
					Документы к оплате
				</NavLink>

				<NavLink
					className="header-nav__link"
					activeClassName="header-nav__link--selected"
					to='/reconciliation/'
				>
					Акт сверки
				</NavLink>

				<NavLink
					className="header-nav__link"
					activeClassName="header-nav__link--selected"
					to='/price-list/'
				>
					Прайс-лист
				</NavLink>

				<NavLink
					className="header-nav__link"
					activeClassName="header-nav__link--selected"
					to='/delivery/'
				>
					Доставка
				</NavLink>

				<NavLink
					className="header-nav__link"
					activeClassName="header-nav__link--selected"
					to='/contact/'
				>
					Контакты
				</NavLink>

			</div>
		</div>
	)
}

export default headerNav