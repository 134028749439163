import React from 'react'
import { useHistory } from 'react-router'
import PropTypes from 'prop-types'

import IconLogo from 'assets/icons/IconLogo'

import './style.scss'

const AuthPageContentBlock = ({ changeViewForm }) => {

	const history = useHistory()

	const onClickAuthPage = () => {
		changeViewForm && changeViewForm('auth')
		history.push('/')
	}

	return (

		<div className="auth__contentBlock">
			<div className="auth__wrap">
				<div className="auth__logo" onClick={() => onClickAuthPage()} >
					<IconLogo />
				</div>
				<a className="auth__phone" href="tel: 8 800 250 16 20">
					8 800 250 16 20
				</a>
			</div>
			<div className="auth__aboutUs">
				<div className="auth__aboutUsText">
					<div className="auth__aboutUsTitle">
						В2В портал
					</div>
					<div className="auth__aboutUsParagraph">
						Онлайн платформа для корпоративных клиентов компании «Саксэс»  для удобного и простого оформления заказов через личный кабинет пользователя.
					</div>
				</div>
				<div className="auth__aboutUsText">
					<div className="auth__aboutUsTitle">
						Сервис 24/7
					</div>
					<div className="auth__aboutUsParagraph">
						Круглосуточный доступ к информации: формирование счета в один клик, точные складские остатки по товарам в режиме online, стоимость материала с учетом Ваши персональных цен, возможность резерва материала.
					</div>
				</div>
				<div className="auth__aboutUsText">
					<div className="auth__aboutUsTitle">
						Детализированная документация
					</div>
					<div className="auth__aboutUsParagraph">
						Возможность формирования всей документации самостоятельно: счета по заказам, УПД и акты сверок за любой период.
					</div>
				</div>
			</div>
		</div >

	)
}
AuthPageContentBlock.propTypes = {
	changeViewForm: PropTypes.func
}

export default AuthPageContentBlock
