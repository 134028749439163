import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import PrivateRoute from 'privateRoute'

import PaymentDocs from 'pages/PaymentDocs'
import Contact from 'pages/Contact'
import Delivery from 'pages/Delivery'
import Checkout from 'pages/Checkout'
import Reconciliation from 'pages/Reconciliation'
import Catalog from 'pages/Catalog'
import Orders from 'pages/Orders'
import Auth from 'pages/Auth'
import MainPage from 'pages/MainPage'
import PriceListPage from 'pages/PriceListPage'
import Page404 from 'pages/PageNotFound'
import Register from 'pages/Register'
import Favorite from 'pages/Personal/Favorite'

import Header from 'components/Header/Header'
import Footer from 'components/Footer'

export const routes = (token, agreement) => {

	if (!!token && !!agreement) {

		return (
			<>
				<PrivateRoute component={Header} />
				<Switch>

					<PrivateRoute
						strict
						exact
						path='/'
						component={MainPage}
						title="Главная страница | САКСЭС"
					/>

					<PrivateRoute
						exact
						path='/payment-docs/'
						component={PaymentDocs}
						title="Документы к оплате | САКСЭС"
					/>

					<PrivateRoute
						exact
						strict
						path='/orders/'
						component={Orders}
						title="Заказы | САКСЭС"
					/>

					<PrivateRoute
						exact
						strict
						path='/contact/'
						component={Contact}
						title="Контакты | САКСЭС"
					/>

					<PrivateRoute
						exact
						strict
						path='/delivery/'
						component={Delivery}
						title="Доставка | САКСЭС"
					/>

					<PrivateRoute
						exact
						strict
						path='/checkout/'
						component={Checkout}
						title="Оформление заказа | САКСЭС"
					/>

					<PrivateRoute
						path='/reconciliation/'
						component={Reconciliation}
						title="Акт сверки | САКСЭС"
					/>

					<PrivateRoute
						path="/catalog/:sectionId?/:elementId?/"
						component={Catalog}
						title="Каталог | САКСЭС"
					/>
					<PrivateRoute
						path="/price-list/"
						component={PriceListPage}
						title="Прайс-лист | САКСЭС"
					/>
					<PrivateRoute
						path="/404/"
						component={Page404}
						title="Страница не найдена | САКСЭС"
						notFound
					/>
					<PrivateRoute
						path="/personal/favorite"
						component={Favorite}
						title="Избранное | САКСЭС"
					/>
					<Redirect to='/404/' />
				</Switch>
				<PrivateRoute component={Footer} />
			</>

		)
	}

	if ((token || token === '') && agreement === '') {

		return (
			<Switch>
				<Route exact path='/register'>
					<Register title="Регистрация | САКСЭС" />
				</Route>
				<Route exact path='/'>
					<Auth title="Авторизация | САКСЭС"
					/>
				</Route>
				<Redirect to='/' />
			</Switch>
		)
	}
}