import React from 'react'
import PropTypes from 'prop-types'

import './skeleton.scss'

const BasketListSkeleton = ({ rowCount = 1 }) => {

	const renderTableRow = (key) => {

		return (
			<tr key={key}>
				<td>
					<div className="skeleton skeleton--line skeleton__basketList skeleton__basketList--article"></div>
				</td>
				<td>
					<div className="skeleton skeleton--line skeleton__basketList skeleton__basketList--name"></div>
				</td>
				<td>
					<div className="skeleton skeleton--line skeleton__basketList skeleton__basketList--availability"></div>
				</td>
				<td>
					<div className="skeleton skeleton--line skeleton__basketList skeleton__basketList--price"></div>
				</td>
				<td>
					<div className="skeleton skeleton--line skeleton__basketList skeleton__basketList--quantity"></div>
				</td>
				<td>
					<div className="skeleton skeleton--line skeleton__basketList skeleton__basketList--summ"></div>
				</td>
			</tr>

		)
	}

	const rows = []

	while (rowCount) {
		rows.push(renderTableRow(rowCount))
		rowCount--
	}

	return rows

}

BasketListSkeleton.propTypes = {
	rowCount: PropTypes.number
}

export default BasketListSkeleton