import config from 'config'

export default class MapService {

	constructor() {
		this.loadPromises = null
		this.mapObj = null
	}

	/**
	 * Get the yandex map script and insert it into the page
	 * @param {string} url url yandex api maps.
	 * @returns {Promise} Promise yandex map script
	 */
	static fetchScript(url) {
		return new Promise(function (resolve, reject) {
			var script = document.createElement('script')
			script.onload = resolve
			script.onerror = reject
			script.src = url
			const [beforeScript] = document.getElementsByTagName('script')
			beforeScript.parentNode.insertBefore(script, beforeScript)
		})
	}

	/**
	 * Creates a map and, if necessary, a placemarks
	 
	 * @param {object} option Map parameters.
	 * @param {Array} placemarks Array of placemarks coordinates.
	 * @param {string} mapID The id of the HTML element in which the map is placed.
	 * @returns {object} Instance of the yandex map
	 */
	static buildMap({ option, placemarks = [] }, mapID) {

		const map = new window.ymaps.Map(mapID, option)

		if (placemarks.length) {
			placemarks.map(placemark => this.buildPlacemark(map, placemark))
		}

		this.mapObj = map
		return map
	}

	/**
	* resize map
	*/
	static resizeMap() {
		if (this.mapObj) {
			this.mapObj.container.fitToViewport()
		}

	}

	/**
	* Creates a placemark on the map.
	* @param {object} map Instance of the yandex map
	* @param {object} placemark Placemark coordinates.
	*/
	static buildPlacemark(map, placemark) {

		const myPlacemark = new window.ymaps.Placemark(
			[placemark.lon, placemark.lat],
			{
				hintContent: 'number',
				balloonContent: '',
				iconContent: '',
			},
			{
				iconLayout: 'default#imageWithContent',
				iconImageHref: `data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iNDQiIHZpZXdCb3g9IjAgMCAzMiA0NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDBDNy4xNzc1NSAwIDAgNy4xNDg0NiAwIDE1LjkzNTFDMCAyNi44Mzk1IDE0LjMxODQgNDIuODQ3OSAxNC45MjgxIDQzLjUyNEMxNS41MDA3IDQ0LjE1OTIgMTYuNTAwNCA0NC4xNTgxIDE3LjA3MTkgNDMuNTI0QzE3LjY4MTYgNDIuODQ3OSAzMiAyNi44Mzk1IDMyIDE1LjkzNTFDMzEuOTk5OCA3LjE0ODQ2IDI0LjgyMjQgMCAxNiAwWk0xNiAyMy45NTI0QzExLjU2MTIgMjMuOTUyNCA3Ljk1MDA4IDIwLjM1NTkgNy45NTAwOCAxNS45MzUxQzcuOTUwMDggMTEuNTE0MyAxMS41NjEzIDcuOTE3NzggMTYgNy45MTc3OEMyMC40Mzg3IDcuOTE3NzggMjQuMDQ5OCAxMS41MTQzIDI0LjA0OTggMTUuOTM1MkMyNC4wNDk4IDIwLjM1NiAyMC40Mzg3IDIzLjk1MjQgMTYgMjMuOTUyNFoiIGZpbGw9IiMwMjU2QjEiLz4KPC9zdmc+Cg==`,
				iconImageSize: [32, 44],
				iconImageOffset: [-16, -44],
			}
		)
		map.geoObjects.add(myPlacemark)
	}

	/**
	* Creates a multi-route on the map.
	* @param {object} coord Coordinates MultiRoute.
	* @param {object} option MultiRoute parameters.
	* @param {boolean} editor Enabling edit mode.
	* @returns {object} Instance MultiRoute
	*/
	static buildRouter(coord, option, params, editor = false) {
		this.mapObj.geoObjects.removeAll()

		const multiRoute = new window.ymaps.multiRouter.MultiRoute(coord, option, params)

		if (editor) {
			multiRoute.editor.start()
		}
		this.mapObj.geoObjects.add(multiRoute)
		return multiRoute
	}

	/**
	* destroy a multi-route on the map.
	*/
	static destroyRouter() {
		this.mapObj.geoObjects.removeAll()
	}

	/**
	* Creates a dropdown bar with search suggestions and attaches to an HTML element
	* @param {string} elementSelector The id of the HTML element in which the map is placed.
	* @returns {object} Instance SuggestView
	*/
	static suggestView(elementSelector) {
		var MySuggestLayout = window.ymaps.templateLayoutFactory.createClass([
			'{% if state.open %}',
			'{% include "islands#suggestView" %}',
			'{% endif %}'
		].join(''))

		const suggestView = new window.ymaps.SuggestView(elementSelector, {
			layout: MySuggestLayout,
			provider: {
				suggest: function (request) {
					return window.ymaps.suggest(request)
				}
			}
		})
		suggestView.state.set('open', true)
		return suggestView
	}

	/**
	* Loads the script and calls the map building method
	* @param {object} mapOptions Map parameters.
	* @param {object} mapID The id of the HTML element in which the map is placed.
	* @returns {Promise} Returns the promise object that is verified by the API namespace
	*/
	static initMap(mapOptions, mapID) {

		return new Promise(function (resolve) {
			MapService.loadApi()
				.then(() => {
					window.ymaps.ready(() => {
						resolve(MapService.buildMap(mapOptions, mapID))
					})
				})
		})

	}

	/**
	* Loads the script yandex map
	* @returns {Promise} Returns the promise object that is verified by the API namespace
	*/
	static loadApi() {

		if (this.loadPromises) {
			return this.loadPromises
		}

		this.loadPromises = new Promise(function (resolve) {
			const url = `https://api-maps.yandex.ru/2.1?apikey=${config.REACT_APP_YANDEX_MAP_KEY}&lang=ru_RU`
			const res = MapService.fetchScript(url)
			res.then(() => {
				if (window.ymaps.ready()) {
					resolve()
				}
			})
		})

		return this.loadPromises
	}
}
