import {
	GET_BASKET,
	GET_BASKET_FAILED,
	DELETE_BASKET_ITEM,
	CHANGE_BASKET_ITEM,
	DELETE_BASKET_All,
	ADD_BASKET_ITEM,
	LOADING_BASKET_FLAG_TO_DEFAULT
} from '../types/basketTypes'

const initialState = {
	basket: [],
	totalPrice: 0,
	basketLoading: false,
	basketLoadingError: false,
}

export const basketReducer = (state = initialState, action) => {
	switch (action.type) {
		case GET_BASKET:
			return { ...state, basket: action.payload.list, totalPrice: action.payload.total, basketLoading: true, basketLoadingError: false }
		case GET_BASKET_FAILED:
			return { ...state, basketLoadingError: action.payload }
		case DELETE_BASKET_ITEM:

			return {
				...state,
				basket: state.basket.filter(item => item.id !== action.payload.itemId),
				totalPrice: action.payload.total
			}
		case DELETE_BASKET_All:
			return { ...state, basket: action.payload.basket, totalPrice: action.payload.total }
		case CHANGE_BASKET_ITEM:
			return {
				...state,
				basket: state.basket.map(item => item.id === action.payload.item.id ?
					action.payload.item :
					item
				),
				totalPrice: action.payload.total
			}
		case ADD_BASKET_ITEM:
			return { ...state, basket: [...state.basket, action.payload.item], totalPrice: action.payload.total }
		case LOADING_BASKET_FLAG_TO_DEFAULT:
			return { ...state, basketLoading: false }
		default: return state
	}
}
