import React, { useState, useEffect, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import { REQUEST_FAILED } from 'redux/types/errorTypes'
import { useFetch } from 'hooks/useFetch'
import { dateFormatter, priceFormatter } from 'helpers'
import MapService from 'services/MapService'

import SelectTransport from 'components/SelectTransport/SelectTransport'
import Map from 'components/ui/Map/Map'
import Input from 'components/ui/Input/Input'
import InputCalendar from 'components/ui/InputCalendar/InputCalendar'
import Select from 'components/ui/Select/Select'
import Button from 'components/ui/Button/Button'
import Title from 'components/ui/Title/Title'

import IconAvailability from 'assets/icons/IconAvailability'
import IconSearch from 'assets/icons/IconSearch'
import IconClock from 'assets/icons/IconClock'
import { DefaultIcon, UrgentIcon, ToTimeIcon, ActionIcon, IconNotDelivery } from 'assets/icons/delivery'
import LogoPreloader from 'assets/preloaders/LogoPreloader'

import './delivery-calc.scss'

const DeliveryCalc = ({ setShipment, children }) => {

	const deliveryInfo = {
		default: {
			name: 'Доставка в течение суток',
			icon: DefaultIcon,
		},
		action: {
			name: 'Акционная доставка',
			icon: ActionIcon
		},
		totime: {
			name: 'Точно ко времени',
			icon: ToTimeIcon
		},
		urgent: {
			name: 'Срочная доставка',
			icon: UrgentIcon
		},
	}

	const shippingInitialState = {
		storageId: '',
		storageId_1C: '',
		shipping_distance: '',
		shipping_address: '',
		shipping_date_selected: new Date(),
		shipping_time: '',
		shipping_type: '',
		shipping_car_default: '',
		shipping_car_urgent: '',
		shipping_car_totime: '',
		shipping_car_action: '',
	}

	const dispatch = useDispatch()
	const { request } = useFetch()
	const totalBasket = useSelector(state => state.basket.totalPrice)
	const history = useHistory()

	const selectTransportRef = useRef(null)

	const [shipping, setShipping] = useState(shippingInitialState)

	const [preloader, setPreloader] = useState(false)
	const [disallowDelivery, setDisallowDelivery] = useState(false)

	const [deliveryList, setDeliveryList] = useState([])
	const [activeDelivery, setActiveDelivery] = useState({})
	const [deliveryListDefault, setDeliveryListDefault] = useState([])
	const [deliveryDateTommorow, setDeliveryDateTommorow] = useState()
	const [timeList, setTimeList] = useState([])
	const [timeSelect, setTimeSelect] = useState('')

	const [showDeliveryCodeDescription, setShowDeliveryCodeDescription] = useState('')

	const [addressDelivery, setAddressDelivery] = useState('')
	const [distanceDelivery, setDistanceDelivery] = useState('')
	const [costDelivery, setCostDelivery] = useState(0)

	const [carsDelivery, setCarsDelivery] = useState([])
	const [carsDeliveryDefault, setCarsDeliveryDefault] = useState({})

	const [mapParams, setMapParams] = useState({})
	const [startCoord, setStartCoord] = useState([])
	const [finishCoord, setFinishCoord] = useState([])
	const [mapStyle, setMapStyle] = useState({})

	const [specialMachineId, setSpecialMachineId] = useState()

	const [suggestView, setSuggestView] = useState(null)

	const [carActiveId, setCarActiveId] = useState()

	const [storageCityId, setStorageCityId] = useState('')
	const [storage1C, setStorage1C] = useState('')

	let mapSetting = {
		'option': {
			'center': [],
			'zoom': '14',
			controls: [],

		},
		'multiRouter': {
			'option': [
				{
					'wayPointStartIconLayout': 'default#imageWithContent',
					'wayPointStartIconImageHref': `data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iNDQiIHZpZXdCb3g9IjAgMCAzMiA0NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDBDNy4xNzc1NSAwIDAgNy4xNDg0NiAwIDE1LjkzNTFDMCAyNi44Mzk1IDE0LjMxODQgNDIuODQ3OSAxNC45MjgxIDQzLjUyNEMxNS41MDA3IDQ0LjE1OTIgMTYuNTAwNCA0NC4xNTgxIDE3LjA3MTkgNDMuNTI0QzE3LjY4MTYgNDIuODQ3OSAzMiAyNi44Mzk1IDMyIDE1LjkzNTFDMzEuOTk5OCA3LjE0ODQ2IDI0LjgyMjQgMCAxNiAwWk0xNiAyMy45NTI0QzExLjU2MTIgMjMuOTUyNCA3Ljk1MDA4IDIwLjM1NTkgNy45NTAwOCAxNS45MzUxQzcuOTUwMDggMTEuNTE0MyAxMS41NjEzIDcuOTE3NzggMTYgNy45MTc3OEMyMC40Mzg3IDcuOTE3NzggMjQuMDQ5OCAxMS41MTQzIDI0LjA0OTggMTUuOTM1MkMyNC4wNDk4IDIwLjM1NiAyMC40Mzg3IDIzLjk1MjQgMTYgMjMuOTUyNFoiIGZpbGw9IiMwMjU2QjEiLz4KPC9zdmc+Cg==`,
					'wayPointStartIconImageSize': [32, 44],
					'wayPointStartIconImageOffset': [-16, -44],
					'wayPointStartDraggable': false,
					'wayPointFinishIconLayout': 'default#imageWithContent',
					'wayPointFinishIconImageHref': `data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iNDQiIHZpZXdCb3g9IjAgMCAzMiA0NCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTE2IDBDNy4xNzc1NSAwIDAgNy4xNDg0NiAwIDE1LjkzNTFDMCAyNi44Mzk1IDE0LjMxODQgNDIuODQ3OSAxNC45MjgxIDQzLjUyNEMxNS41MDA3IDQ0LjE1OTIgMTYuNTAwNCA0NC4xNTgxIDE3LjA3MTkgNDMuNTI0QzE3LjY4MTYgNDIuODQ3OSAzMiAyNi44Mzk1IDMyIDE1LjkzNTFDMzEuOTk5OCA3LjE0ODQ2IDI0LjgyMjQgMCAxNiAwWk0xNiAyMy45NTI0QzExLjU2MTIgMjMuOTUyNCA3Ljk1MDA4IDIwLjM1NTkgNy45NTAwOCAxNS45MzUxQzcuOTUwMDggMTEuNTE0MyAxMS41NjEzIDcuOTE3NzggMTYgNy45MTc3OEMyMC40Mzg3IDcuOTE3NzggMjQuMDQ5OCAxMS41MTQzIDI0LjA0OTggMTUuOTM1MkMyNC4wNDk4IDIwLjM1NiAyMC40Mzg3IDIzLjk1MjQgMTYgMjMuOTUyNFoiIGZpbGw9IiMwMjU2QjEiLz4KPC9zdmc+Cg==`,
					'wayPointFinishIconImageSize': [32, 44],
					'wayPointFinishIconImageOffset': [-16, -44],
					'routeActiveStrokeWidth': 3,
					'routeStrokeWidth': 0,
					'boundsAutoApply': true,
				}
			],
			'params': {
				'avoidTrafficJams': true
			},
			'editor': false,
			'startRouteCoodrs': []
		},
		'SuggestViewEl': 'ymapsSuggest'
	}

	const loadData = async () => {

		try {

			const response = await request(
				'delivery/getparams',
				{}
			)

			const { storage, machine: machines, restrictions_time, baseUrl, dates, special_machine_id, allow } = response.data

			setSpecialMachineId(special_machine_id)

			if (allow !== false) {
				const [day, month] = dates.tomorrow.split('.')
				const delivery = Object.entries(restrictions_time).map(([key, value]) => ({ code: key, ...value }))

				setDeliveryDateTommorow(new Date([new Date().getFullYear(), month, day].join('-')))

				setCarsDelivery(machines.map(machine => {
					return machine.id === special_machine_id ?
						{ ...machine, image: `${baseUrl}${machine.image}`, default_type: '0', action_type: '1' }
						:
						{ ...machine, image: `${baseUrl}${machine.image}` }
				}))

				setDeliveryList(delivery)

				setDeliveryListDefault(delivery)

				const [{ coords_lg, coords_lt, id, id_1c_city }] = storage

				mapSetting.option.center = [coords_lg, coords_lt]
				mapSetting.multiRouter.startRouteCoodrs = [coords_lg, coords_lt]
				setStartCoord([coords_lg, coords_lt])
				setMapParams(mapSetting)
				setStorageCityId(id)
				setStorage1C(id_1c_city)

				const defaultMachineId = machines.find(car => car.default_type === '1').id

				const actionMachine = machines.find(car => car.action_type === '1')
				let actionMachineId = ''
				if (actionMachine) {
					actionMachineId = actionMachine.id
				}

				const carsDeliveryDefaultId = {
					shipping_car_default: defaultMachineId,
					shipping_car_urgent: defaultMachineId,
					shipping_car_totime: defaultMachineId,
					shipping_car_action: actionMachineId,
				}

				setCarsDeliveryDefault(carsDeliveryDefaultId)

				changeShipping(
					{
						storageId: id,
						storageId_1C: id_1c_city,
					}
				)
			} else {

				setDisallowDelivery(true)

			}

		} catch (error) {

			dispatch({ type: REQUEST_FAILED, payload: { message: error.message } })

		}

	}

	const changeShipping = (params) => {
		if (params) {
			setShipping(prev => {
				return { ...prev, ...params }
			})
		} else {
			setShipping(shippingInitialState)
		}

	}

	const toggleShowModal = (eventType, DeliveryCode) => setShowDeliveryCodeDescription(eventType === 'mouseenter' ? DeliveryCode : '')

	const changeDelivery = (delivery) => {

		changeShipping(
			{
				shipping_type: delivery.code,
				...carsDeliveryDefault
			}
		)
	}

	const changeTrack = ({ suggestView, finishCoord }) => {

		const coords = {
			'referencePoints': [
				startCoord,
				finishCoord
			]
		}

		const multiRoute = MapService.buildRouter(
			{ ...coords },
			...mapParams.multiRouter.option,
			{ params: mapParams.multiRouter.params },
			mapParams.multiRouter.editor

		)

		multiRoute.model.events.add('requestsuccess', async () => {

			const myReverseGeocoder = await window.ymaps.geocode(multiRoute.model.getReferencePoints()[1])
			if (multiRoute.getActiveRoute()) {
				const address = myReverseGeocoder.geoObjects.get(0).properties.get('text')
				const distance = Math.ceil(multiRoute.getActiveRoute().properties.get('distance').value / 1000)

				setAddressDelivery(address)
				setDistanceDelivery(distance)

				if (address !== shipping.shipping_address) {
					changeShipping({ shipping_address: address, shipping_distance: distance.toString() })
				}

				suggestView.state.set({
					open: false,
				})
			} else {

				MapService.destroyRouter()
				setAddressDelivery('')
				setDistanceDelivery('')

			}
		})
	}

	useEffect(() => {

		if (finishCoord.length > 0) {
			changeTrack({
				suggestView,
				finishCoord
			})
		}
	}, [finishCoord])

	const onLoadMap = async (mapObj) => {
		const suggestView = await MapService.suggestView(mapParams.SuggestViewEl)
		setSuggestView(suggestView)

		suggestView.events.add('select', async (e) => {
			suggestView.state.set({
				panelClosed: true,
				items: []
			})
			const myGeocoder = await window.ymaps.geocode(e.originalEvent.item.value)
			mapObj.events.fire('click', {
				coords: myGeocoder.geoObjects.get(0).geometry.getCoordinates()

			})
		})

		mapObj.events.add('click', async (e) => {
			setFinishCoord(e.originalEvent.coords || e.get('coords'))
		})
	}

	useEffect(() => {

		loadData()

	}, [])

	useEffect(async () => {

		const { shipping_distance, shipping_address, shipping_date_selected, storageId } = shipping

		if (shipping_distance.length > 0 && shipping_address.length > 0) {
			try {
				setPreloader(true)
				const response = await request(
					'delivery/getprice',
					{},
					{
						params: {
							...{ ...shipping, ...carsDeliveryDefault, storageId: storageCityId, storageId_1C: storage1C, shipping_date_selected: dateFormatter(shipping_date_selected) },
							total_basket: totalBasket
						}
					},
					'POST'
				)

				const { preview_price, restricted, storage, machine: machines, baseUrl, id } = response.data

				setCarActiveId(id)

				setCarsDelivery(machines.map(machine => {
					return machine.id === specialMachineId ?
						{ ...machine, image: `${baseUrl}${machine.image}`, default_type: '0', action_type: '1' }
						:
						{ ...machine, image: `${baseUrl}${machine.image}` }
				}))

				const [{ coords_lg, coords_lt }] = storage

				setStartCoord([coords_lg, coords_lt])

				if (storage[0].id !== storageCityId) {

					const defaultMachineId = machines.find(car => car.default_type === '1').id

					const actionMachine = machines.find(car => car.action_type === '1')
					let actionMachineId = ''
					if (actionMachine) {
						actionMachineId = actionMachine.id
					}

					if (carActiveId) {
						const carsDeliveryDefaultId = {
							shipping_car_default: id,
							shipping_car_urgent: id,
							shipping_car_totime: id,
							shipping_car_action: actionMachineId,
						}

						setCarsDeliveryDefault(carsDeliveryDefaultId)
					} else {
						const carsDeliveryDefaultId = {
							shipping_car_default: defaultMachineId,
							shipping_car_urgent: defaultMachineId,
							shipping_car_totime: defaultMachineId,
							shipping_car_action: actionMachineId,
						}

						setCarsDeliveryDefault(carsDeliveryDefaultId)
					}

				}

				setStorageCityId(storage[0].id)

				const delivery = Object.entries(restricted).map(([key, value]) => {
					return {
						code: key,
						...value,
						price: preview_price[key] !== null ? preview_price[key] : '0',
						active: shipping.shipping_type === key ? true : false
					}
				})

				setDeliveryList(delivery)

			} catch (error) {

				dispatch({ type: REQUEST_FAILED, payload: { message: error.message } })

			} finally {

				setPreloader(false)

			}

		}
		if (storageId.length > 0 && JSON.stringify(deliveryList) !== JSON.stringify(deliveryListDefault)) {

			if (shipping_date_selected.length === 0 || (shipping_address.length === 0 && shipping_distance.length === 0)) {

				setDeliveryList(deliveryListDefault)
				setTimeList([])
			}
		}

	}, [totalBasket, shipping])

	useEffect(() => {
		if (deliveryList.length > 0) {

			const activeDelivery = deliveryList.find(delivery => delivery.active)

			if (activeDelivery) {
				setCostDelivery(activeDelivery.price)

				if (activeDelivery.time_variants && !activeDelivery.hide_time) {

					const timeVariants = activeDelivery.time_variants.map(time => { return { 'name': time } })
					setTimeList(timeVariants)

				} else {

					setTimeList([])
				}

				setActiveDelivery(activeDelivery)

			} else {

				setActiveDelivery({})
				setTimeList([])
			}

		}

	}, [deliveryList])

	useEffect(() => {

		if (deliveryDateTommorow && new Date().getHours() >= 15) {
			changeShipping({ shipping_date_selected: new Date(deliveryDateTommorow) })
		}
	}, [deliveryDateTommorow])

	useEffect(() => {

		if (Object.entries(mapParams).length !== 0) {

			if (activeDelivery && activeDelivery.code && activeDelivery.price >= 0) {

				const { shipping_address, shipping_date_selected } = shipping

				if (setShipment) {
					setShipment({
						date: dateFormatter(shipping_date_selected),
						address: shipping_address,
						truck: carsDelivery.find(car => car.id === shipping[`shipping_car_${activeDelivery.code}`]).name,
						time: timeList.length > 0 && timeSelect.length === 0 ? null : timeSelect,
						method: deliveryInfo[activeDelivery.code].name,
						cost: costDelivery
					})
				}

				setMapStyle({ width: selectTransportRef.current ? `calc(100% - ${selectTransportRef.current.offsetWidth}px)` : '100%' })
			} else {
				setMapStyle({})
				if (setShipment) {
					setShipment({ time: null })
				}

			}
			MapService.resizeMap()
		}

	}, [activeDelivery, timeSelect])

	useEffect(() => {

		setTimeSelect('')

	}, [timeList])

	useEffect(() => {

		setDeliveryList(deliveryList.map(delivery => {
			return { ...delivery, active: false }
		}))

	}, [shipping.shipping_date_selected])

	useEffect(() => {

		if (Object.keys(mapParams).length > 0) {
			changeTrack({
				suggestView,
				finishCoord
			})
		}

	}, [startCoord])

	const optionOnClick = (delivery, distanceDelivery) => {
		changeDelivery(delivery)
		changeShipping({ shipping_distance: String(distanceDelivery) })
	}

	const visibleDelivery = deliveryList.length > 0 ? deliveryList
		.filter(delivery => delivery.price === undefined || !delivery.disabled && (delivery.price > 0 || delivery.price === 0 && delivery.code === 'action'))
		.filter(delivery => delivery.code !== 'action')
		: 
		[]
	return (
		<div className="delivery-calc">
			{
				!disallowDelivery ?
					<>
						{
							children && children
						}
						<div className={classNames('delivery-calc__params', preloader && 'delivery-calc__opacity')}>

							<Input
								className="delivery-calc__input delivery-calc__input--address"
								value={addressDelivery}
								onChange={e => {
									return (
										setAddressDelivery(e.target.value),
										suggestView.state.set({
											open: true,
										})
									)
								}}
								onBlur={e => {
									if (e.target.value.length === 0) {
										changeShipping({ shipping_address: '', shipping_distance: '' })
										MapService.destroyRouter()
										setAddressDelivery('')
										setDistanceDelivery('')
									}
								}}

								placeholder="Адрес"
								id="ymapsSuggest"
							>
								<IconSearch className="delivery-calc__icon delivery-calc__icon--search" />
							</Input>

							<InputCalendar
								placeholder='Дата доставки'
								className="delivery-calc__input delivery-calc__input--date"
								classNamePlaceholder="delivery-calc__inputPlaceholderDate"
								callback={date => changeShipping({ shipping_date_selected: date, shipping_type: '', ...carsDeliveryDefault })}
								defaultDate={shipping.shipping_date_selected}
								minDate={new Date().getHours() < 15 ? new Date() : deliveryDateTommorow}
								dateDisabled={[0, 6]}
								expression='delivery'
							/>

							{
								timeList.length > 0 &&
								<div className="delivery-calc__select">

									<Select
										key={deliveryList.length > 0 && deliveryList.find(delivery => delivery.active) && deliveryList.find(delivery => delivery.active).code}
										hidePlaceholder={false}
										icon={<IconClock className="delivery-calc__icon delivery-calc__icon--clock" />}
										placeholder="Время доставки"
										items={timeList}
										onChange={time => setTimeSelect(time.name)}
									/>
								</div>
							}

						</div>
						<div className={classNames('delivery-calc__options', preloader && 'delivery-calc__opacity')}>
							{visibleDelivery.length > 0 ?

								visibleDelivery.map((delivery, key) => {

									return (

										<div
											className={classNames('delivery-calc__option', delivery.price === undefined && 'delivery-calc__option--disabled', delivery.active && 'delivery-calc__option--active')}
											onClick={() => delivery.price !== undefined && optionOnClick(delivery, distanceDelivery)}
											key={key}
										>

											<div className="delivery-calc__optionTop">
												<div className="delivery-calc__optionIcon">
													{React.createElement(deliveryInfo[delivery.code].icon, delivery.active && { circleFill: '#0256B1', pathFill: '#ffffff' })}
												</div>
												<div className="delivery-calc__optionName">
													{deliveryInfo[delivery.code].name}
												</div>
											</div>
											<div className="delivery-calc__optionBottom">

												<div
													className="delivery-calc__optionInfo"
													onMouseEnter={event => toggleShowModal(event.type, delivery.code)}
													onMouseLeave={event => toggleShowModal(event.type)}
												>
													<IconAvailability />
												</div>

												<div className={classNames('delivery-calc__optionPrice', delivery.price === undefined && 'delivery-calc__optionPrice--disabled')}>
													{
														delivery.price === undefined ?
															'Введите адрес и дату' :
															priceFormatter(delivery.price, 0)
													}
												</div>

												{showDeliveryCodeDescription === delivery.code && delivery.description &&
													<div className="delivery-calc__optionDesc">
														<div className="delivery-calc__optionDescWrap">

															<div className="delivery-calc__optionDescText">
																{delivery.description.type === 'text' ? delivery.description.value : delivery.description.value.text}
															</div>

															{delivery.description.type === 'html' &&
																<table className="delivery-calc__optionDescTable deliveryDescriptionTable">
																	<thead>
																		<tr>
																			{delivery.description.value.table.headers.map(item =>
																				<td key={item} className="deliveryDescriptionTable__cell">
																					{item}
																				</td>
																			)}
																		</tr>

																	</thead>
																	<tbody>
																		{delivery.description.value.table.rows.map(row =>
																			<tr key={row}>
																				{row.map(cell =>
																					<td key={cell} className="deliveryDescriptionTable__item">
																						{cell}
																					</td>
																				)}
																			</tr>
																		)}
																	</tbody>
																</table>
															}
														</div>
													</div>
												}
											</div>

										</div>

									)
								}) :
								shipping.storageId_1C.length > 0 &&
								<div>Нет ни одной доставки, доступной к выбору. Измените параметры доставки</div>

							}

						</div>
						<div className={classNames('delivery-calc__content', preloader && 'delivery-calc__opacity')}>
							{
								Object.entries(activeDelivery).length > 0 && activeDelivery.price >= 0 && carActiveId &&
								<SelectTransport
									ref={selectTransportRef}
									key={deliveryList.find(delivery => delivery.active === true)?.code}
									className="delivery-calc__selectTransport"
									cars={carsDelivery.filter(car => car[`${deliveryList.find(delivery => delivery.active === true)?.code}_type`] === '1') || []}
									carsToShippingType={shipping.shipping_type}
									carActiveId={carActiveId}
									onChange={car => {

										let paramsCarRequest = {}

										Object.entries(car).map(([key, value]) => {
											if (key.endsWith('_type') && value === '1') {
												paramsCarRequest[`shipping_car_${key.substr(0, key.length - 5)}`] = car.id
											}
										})

										setCarsDeliveryDefault(paramsCarRequest)

										changeShipping(paramsCarRequest)

									}}
								/>
							}
							<Map
								style={mapStyle}
								mapID="delivery"
								options={mapParams}
								onLoadMap={(e) => onLoadMap(e)}
								setAddress={(e) => setAddressDelivery(e)}
								setDistance={(e) => setDistanceDelivery(e)}
							/>
						</div>
						<div className={classNames('delivery-calc__result', preloader && 'delivery-calc__opacity')}>
							<Input
								className="delivery-calc__input delivery-calc__input--distance"
								classNamePlaceholder='delivery-calc__inputPlaceholder'
								value={distanceDelivery && `${distanceDelivery} км`}
								onChange={e => setDistanceDelivery(e.target.value)}
								placeholderVisible="Расстояние до пункта назначения"
								disabled
							/>
							<Input
								className="delivery-calc__input delivery-calc__input--cost"
								classNamePlaceholder='delivery-calc__inputPlaceholder'
								value={priceFormatter(costDelivery, 0)}
								placeholderVisible="Стоимость доставки"
								disabled
							/>
						</div>
						{preloader && <LogoPreloader className="delivery-calc__preloader" />}
					</> :
					<div className="delivery-calc__notDelivery">
						<IconNotDelivery className="delivery-calc__notDeliveryIcon" />
						<div className="delivery-calc__notDeliveryText" >
							<Title content="Упс..." className="delivery-calc__notDeliveryTitle" />
							<div className="delivery-calc__notDeliveryNote">В вашем городе доставка пока недосупна</div>
							<Button
								text="На главную"
								className="delivery-calc__toHome"
								onClick={() => history.push('/')}
							/>
						</div>
					</div>

			}
		</div>

	)
}
DeliveryCalc.propTypes = {
	setShipment: PropTypes.func,
	children: PropTypes.node || PropTypes.undefined
}
export default DeliveryCalc
