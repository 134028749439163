import React, { useRef, useImperativeHandle, useState } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './input.scss'
const Input = React.forwardRef(({ className, classNamePlaceholder, placeholderVisible, children: icon, ...attr }, ref) => {

	const input = useRef(null)
	const [isHoverInput, setIsHoverInput] = useState(false)

	useImperativeHandle(ref, () => ({
		getInputNode: () => {
			return input.current
		}
	}))

	return (
		<div
			className={classNames('input', className)}
			onClick={() => input.current.focus()}
			onMouseEnter={() => setIsHoverInput(true)}
			onMouseLeave={() => setIsHoverInput(false)}
		>
			{
				placeholderVisible &&
				<div className={classNames('placeholder', classNamePlaceholder)}>
					{placeholderVisible}
				</div>
			}
			<input
				ref={input}
				className={classNames('input__control', isHoverInput && 'input__control--hover')}
				{...attr}
			/>
			{icon}
		</div>

	)
})

Input.propTypes = {
	className: PropTypes.string,
	classNamePlaceholder: PropTypes.string,
	placeholderVisible: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.object,
		PropTypes.array,
		PropTypes.node,
	]),
	attr: PropTypes.object,

}

Input.displayName = 'Input'

export default Input