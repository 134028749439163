import React, { useEffect, useState } from 'react'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useFetch } from 'hooks/useFetch'

import { REQUEST_FAILED } from 'redux/types/errorTypes'

import OrderParams from 'components/OrderParams/OrderParams'
import DeliveryCalc from 'components/DeliveryCalc/DeliveryCalc'
import Input from 'components/ui/Input/Input'
import Button from 'components/ui/Button/Button'
import Title from 'components/ui/Title/Title'

import './style.scss'

const Order = ({ basket, setOrderResult }) => {

	const { request, error } = useFetch()
	const dispatch = useDispatch()

	const [delivery, setDelivery] = useState([])
	const [activeDelivery, setActiveDelivery] = useState({})
	const [deliveryLoading, setDeliveryLoading] = useState(false)
	const [activeStore, setActiveStore] = useState(null)

	const shipmentInitialState = {
		truck: null,
		address: null,
		date: null,
		time: null,
		cost: null
	}

	const [shipment, setShipment] = useState(shipmentInitialState)

	const [comment, setComment] = useState('')

	const orderCreate = async () => {
		try {
			const deliveryParams = {
				comment: comment,
				delivery: {
					id: activeDelivery.id,
					value: {
						...(activeDelivery.is_pickup ? { store: activeStore.id } : { ...shipment })
					}
				}

			}

			const response = await request('order/make', {}, { ...deliveryParams }, 'POST')
			setOrderResult(response.data)

		} catch (error) {
			dispatch({ type: REQUEST_FAILED, payload: { createdAt: Date.now(), message: error.message || 'Ошибка создания заказа' } })
		}

	}

	const changeActiveStore = (store) => {

		if (activeStore.id !== store.id) {
			setActiveStore(store)
		}

	}

	useEffect(async () => {

		try {

			const res = await request('checkout')

			const { delivery } = res.data

			const [firstDelivery] = delivery
			const [firstStore] = firstDelivery.params

			setDelivery(delivery)
			setActiveDelivery(firstDelivery)
			setActiveStore(firstStore)
			setDeliveryLoading(true)

		} catch (error) {

			dispatch({ type: REQUEST_FAILED, payload: { createdAt: Date.now(), message: error.message || 'Ошибка загрузки данных' } })

		}

	}, [])

	const submitDisabled = Object.keys(activeDelivery).length !== 0 && !activeDelivery.is_pickup && Array.of(...Object.values(shipment)).findIndex(val => val === null) !== -1

	return (
		<div className="order">
			{
				delivery.length > 0 && Object.keys(activeDelivery).length !== 0 && basket.basket.length > 0 ?
					<>
						<Title
							content="Оформление заказа"
							className="order__title"
						/>
						<OrderParams
							title="Способ доставки"
							content={delivery}
							name="delivery"
							onChange={item => setActiveDelivery(item)}
						/>
						{
							activeDelivery.is_pickup ?
								<OrderParams
									title="Выберите склад"
									content={activeDelivery.params}
									name="store"
									onChange={item => changeActiveStore(item)}
								/> :
								<DeliveryCalc setShipment={setShipment} />

						}
						<Input
							className="order__inputComment"
							value={comment}
							onChange={e => setComment(e.target.value)}
							placeholder="Комментарий к заказу"
						/>
						<Button
							className={classNames('order__submit', submitDisabled && 'order__submit--disabled')}
							text="Оформить заказ"
							callback={orderCreate}
							disabled={submitDisabled}
						/>

					</>
					:
					!error && !deliveryLoading ?
						<div>Загрузка</div>
						:
						!deliveryLoading && error &&
						<div> Ошибка загрузки данных</div>

			}

		</div >

	)

}
Order.propTypes = {
	basket: PropTypes.object,
	setOrderResult: PropTypes.func,
}
export default Order