import { useDispatch, useSelector } from 'react-redux'
import React, { useEffect, useState } from 'react'

import LocalStorageService from 'services/LocalStorageService'
import { REQUEST_FAILED } from 'redux/types/errorTypes'
import { useFetch } from 'hooks/useFetch'

import FavoriteFolders from 'components/FavoriteFolders/FavoriteFolders'
import ProductsTable from 'components/ProductsTable/ProductsTable'
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'

import Pagination from 'components/ui/Pagination/Pagination'

import './style.scss'
import classNames from 'classnames'

const Favorite = ({ invisible }) => {

	console.log('invisible >>>>>', invisible)

	const {folders, favoriteLoading} = useSelector(state => state.favorite)
	const { request } = useFetch()
	const dispatch = useDispatch()

	const localStorage = LocalStorageService.get()
	const favoriteDataInitial = {
		products: [],
		navigation: null
	}

	const [favoriteData, setFavoriteData] = useState(favoriteDataInitial)
	const [filterAvailability, setFilterAvailability] = useState(false)
	const [currentFolderId, setCurrentFolderId] = useState(null)
	const [preloader, setPreloader] = useState(true)
	
	const loadDataWithPagination = async (page, inAvailability = false) => {
		
		try {

			setFavoriteData(favoriteDataInitial)
			setPreloader(true)

			const res = await request(
				'favorities/list/product',
				{
					element_id: currentFolderId,
					navPage: page,
					...(inAvailability && { 'filter[availability]': inAvailability }),
				}
			)

			if (filterAvailability !== inAvailability) {
				setFilterAvailability(inAvailability)
				LocalStorageService.set({ ...localStorage, inAvailabilityFavorite: inAvailability })
			}

			setFavoriteData({
				products: res.data.list,
				navigation: res.data.navigation
			})

		} catch (error) {
			dispatch({ type: REQUEST_FAILED, payload: { createdAt: Date.now(), message: error.message || 'Ошибка загрузки данных' } })
		} finally {
			setPreloader(false)
		}

	}

	useEffect(() => {

		if(folders.length > 0 && currentFolderId && folders.findIndex(folder => folder.id === currentFolderId) !== -1 && folders.find(folder => folder.id === currentFolderId).count === 0){
			setFavoriteData(favoriteDataInitial)
		}	

		if(folders.length > 0 && (!currentFolderId || folders.findIndex(folder => folder.id === currentFolderId) === -1)){
			const [firstFolder] = folders
			setCurrentFolderId(firstFolder.id)
		}

		if(folders.length === 0 && currentFolderId){
			setFavoriteData(favoriteDataInitial)
		}

	}, [folders, currentFolderId])

	useEffect(() => {

		if(currentFolderId){
			loadDataWithPagination(1, localStorage.inAvailabilityFavorite)
		}

	}, [currentFolderId])
	
	const breadcrumbsPath = [
		{
			id: 1,
			name: 'Главная',
			link: '/',
		},
		{
			id: 2,
			name: 'Каталог',
			link: '/catalog/',
		},
		{
			id: 3,
			name: 'Избранное',
		},
	]
	
	const {products, navigation} = favoriteData
	const currentName = (folders.length > 0 && folders.findIndex(folder => folder.id === currentFolderId) !== -1 && currentFolderId) ? folders.find(folder => folder.id === currentFolderId).name : ''

	return (
		<div className={classNames('container container__content', invisible && 'invisible')}>
			<div className='favorite-page'>
					
				<FavoriteFolders
					setCurrentFolderId={setCurrentFolderId}
					className='favorite-page__folders'
					currentFolderId={currentFolderId}
				/>
				<div className='favorite-page__list'>
					<Breadcrumbs path={breadcrumbsPath} />
					{
						favoriteLoading && folders.length === 0 ?
							<div className="favorite-page__empty">Нет ни одного списка избранного</div> : 
							preloader && products.length === 0 ?
								<div className="favorite-page__loading">Загрузка</div> :
								<>
									{
										<div className='favorite-page__table'>
											<ProductsTable
												title={currentName}
												products={products}
												inAvailability={filterAvailability}
												filterProduct={availability => loadDataWithPagination(1, availability)}
											/>
										</div>
									}
									{
										navigation && navigation.pagesCount > 1 &&
										<Pagination
											setting={navigation}
											callback={page => loadDataWithPagination(page, localStorage.inAvailabilityFavorite)}
											className='favorite-page__pagination'
											visibleSkeleton={false}
										/>
									}
								</>
					}
				</div>
			</div>
		</div>
	)
}

Favorite.propTypes = {
	invisible: Favorite.bool,
}

export default Favorite
