import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useFormik } from 'formik'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import { phoneFormatter } from 'helpers'

import { useFetch } from 'hooks/useFetch'

import Title from 'components/ui/Title/Title'
import Input from 'components/ui/Input/Input'
import Button from 'components/ui/Button/Button'

import './style.scss'
import CirclePreloader from 'assets/preloaders/Circle'
import Select from 'components/ui/Select/Select'

const registerFormSchema = Yup.object().shape({
	name: Yup.string().required(' '),
	phone: Yup.string().min(18, ' ')
		.required(' '),
	email: Yup.string().email('Значение поля должно быть email адресом')
		.required(' '),
	companyName: Yup.string().required(' '),
	INN: Yup.string().required(' '),
	city: Yup.string().required(' '),
})

const RegisterForm = ({ changeViewForm }) => {

	const { request } = useFetch()

	const history = useHistory()

	const [cityItems, setCityItems] = useState([])

	const formik = useFormik({
		validationSchema: registerFormSchema,
		initialValues: {
			name: '',
			phone: '',
			email: '',
			email_to: '',
			companyName: '',
			INN: '',
			city: '',
		},
		initialStatus: false,
		onSubmit: async values => {
			try {

				const fields = Object.entries(values).reduce((a, [key, value]) => {
					a[key.toUpperCase()] = value
					return a
				}, {})

				await request('user/register', {}, { fields }, 'POST')
				formik.setStatus(true)

				window.dataLayer.push({
					'event': 'GAEvent',
					'eventCategory': 'form',
					'eventAction': 'success',
					'eventLabel': 'zayavka_registration_b2b'
				})

				setTimeout(() => {

					setCityItems(prev => prev.map(city => {
						return {
							...city,
							selected: false
						}
					}))

					formik.handleReset()

				}, 5000)

			} catch (error) {

				formik.setFieldError('server', ...error.message)

			}

		},
	})

	useEffect(async () => {

		const response = await request('pages/registration/getcities', {})
		setCityItems(response.data.list)

	}, [])

	return (
		<form
			className="register-form"
			onSubmit={formik.handleSubmit}
		>
			<Title
				content="Регистрация"
				className="register-form__title"
			/>
			<div className="register-form__note">
				Есть корпоративный аккаунт?
				<div className="register-form__noteLink" onClick={() => history.push('/')}>
					Войти
				</div>
			</div>
			<Input
				className={
					classNames(
						'register-form__input',
						formik.errors.name && formik.touched.name && 'register-form__input--error'
					)
				}
				placeholder="ФИО"
				name="name"
				value={formik.values.name}
				onChange={formik.handleChange}
			/>

			<Input
				className={
					classNames(
						'register-form__input',
						formik.errors.phone && formik.touched.phone && 'register-form__input--error'
					)
				}
				placeholder="Телефон"
				name="phone"
				value={formik.values.phone}
				onChange={(e) => {
					formik.setFieldValue(
						e.target.name,
						phoneFormatter(e.target.value)
					)
				}}
				onBlur={(e) => {
					if (e.target.value.length < 18) {
						formik.setFieldValue(
							e.target.name,
							''
						)
					}
				}}
			/>

			<Input
				className={
					classNames(
						'register-form__input',
						formik.errors.email && formik.touched.email && 'register-form__input--error'
					)
				}
				placeholder="Email"
				name="email"
				value={formik.values.email}
				onChange={formik.handleChange}
			/>

			<Input
				className={
					classNames(
						'register-form__input',
						formik.errors.companyName && formik.touched.companyName && 'register-form__input--error'
					)
				}
				placeholder="Компания"
				name="companyName"
				value={formik.values.companyName}
				onChange={formik.handleChange}
			/>

			<Input
				className={
					classNames(
						'register-form__input',
						formik.errors.INN && formik.touched.INN && 'register-form__input--error'
					)
				}
				placeholder="ИНН"
				name="INN"
				value={formik.values.INN}
				onChange={formik.handleChange}
			/>

			<Select
				key={formik.values.city}
				placeholder="Город"
				name="city"
				className={
					classNames(
						'register-form__select',
						formik.errors.city && formik.touched.city && 'register-form__select--error'
					)
				}
				items={cityItems}
				defaultValue={formik.values.city}
				onChange={item => {

					formik.setFieldValue(
						'city',
						item.name
					)
					formik.setFieldValue(
						'email_to',
						item.email
					)

				}}
			/>

			<div className="register-form__bottom">
				{
					formik.errors.email &&
					<div className="register-form__message register-form__message--error">
						{formik.errors.email}
					</div>
				}
				{
					formik.errors.server &&
					<div className="register-form__message register-form__message--error">
						{formik.errors.server}
					</div>
				}
				{
					formik.status &&
					<div className="register-form__message register-form__message--success">
						Ваша заявка отправлена на рассмотрение, с Вами свяжется менеждер
					</div>
				}
				{
					!formik.status &&
					<Button
						type="submit"
						className="register-form__submit"
						text={
							!formik.isSubmitting ?
								'Отправить заявку' :
								<CirclePreloader className="register-form__preloader" />
						}
					/>
				}

			</div>

		</form>
	)
}

RegisterForm.propTypes = {
	changeViewForm: PropTypes.func,
}

export default RegisterForm