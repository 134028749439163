import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import BasketService from 'services/BasketService'

import CheckoutSuccess from 'components/CheckoutSuccess'
import Basket from 'components/Basket'
import Order from 'components/Order'

const Checkout = ({ invisible }) => {

	const history = useHistory()
	const dispatch = useDispatch()
	const basket = useSelector(state => state.basket)

	const [orderResult, setOrderResult] = useState({})

	useEffect(() => {
		if (history.action === 'PUSH') {
			BasketService.loadingBasketFlagToDefault(dispatch)
			BasketService.getBasket(dispatch)
		}

	}, [])

	useEffect(() => {
		const isSuccessOrderProcessing = Object.keys(orderResult).length !== 0
		if (isSuccessOrderProcessing) {
			BasketService.getBasket(dispatch)
		}

	}, [orderResult])

	return (
		<div className={classNames('container container__content', invisible && 'invisible')}>
			<div className="сheckout">
				{
					Object.keys(orderResult).length === 0
						?
						<>
							<Basket />
							<Order basket={basket} setOrderResult={setOrderResult} />
						</> :
						<CheckoutSuccess orderId={orderResult.id} />
				}

			</div>
		</div>
	)
}

Checkout.propTypes = {
	invisible: PropTypes.bool,
}
export default Checkout