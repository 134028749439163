import React from 'react'
import PropTypes from 'prop-types'

import './contactInfo.scss'

const ContactInfo = ({ postcode, address, schedule }) => (
	<div className="contact__info">
		<div className="contact__postcode">
			{postcode}
		</div>

		<div className="contact__address">
			{address}
		</div>

		{schedule &&
			<div className="contact__schedule">
				<div className="contact__scheduleTitle">Время работы</div>
				{schedule.map((item, key) => (
					<div
						className="contact__scheduleItem"
						key={key}>
						<div className="contact__scheduleDays">{item.days}</div>
						<div className="contact__scheduleTime">{item.time}</div>
					</div>
				))}
			</div>
		}
	</div>
)

ContactInfo.propTypes = {
	postcode: PropTypes.string,
	address: PropTypes.string,
	schedule: PropTypes.array,
}

export default ContactInfo
