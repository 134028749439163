import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { priceFormatter } from 'helpers'

import { useFetch } from 'hooks/useFetch'
import { useOnClickOutside } from 'hooks/useOnClickOutside'

import { REQUEST_FAILED } from 'redux/types/errorTypes'

import ConfirmNotification from 'components/ui/ConfirmNotification/ConfirmNotification'
import IconRefresh from 'assets/icons/IconRefresh'
import IconCancelOrder from 'assets/icons/IconCancelOrder'
import CirclePreloader from 'assets/preloaders/Circle'

const OrderDetail = ({ order, canceled, changeOrderStatus }) => {

	const dispatch = useDispatch()
	const history = useHistory()

	const [showDeleteNotification, setShowDeleteNotification] = useState(false)
	const [showRepeatNotification, setShowRepeatNotification] = useState(false)
	const [showPreloader, setShowPreloader] = useState(false)

	const deleteNotification = useRef(null)
	const repeatNotification = useRef(null)

	const { request } = useFetch()

	const tableHead = {
		name: 'Наименование',
		quantity: 'Кол-во',
		measure: 'Ед.изм.',
		price: 'Цена',
		sum: 'Сумма',
	}

	useOnClickOutside([deleteNotification, repeatNotification], event => {

		if (repeatNotification.current && !repeatNotification.current.contains(event.target)) {

			setShowRepeatNotification(false)
		}

		if (deleteNotification.current && !deleteNotification.current.contains(event.target)) {

			setShowDeleteNotification(false)
		}

	})

	const cancelOrder = async () => {
		try {

			setShowPreloader(true)
			const res = await request('order/cancel', { id: order.id }, {}, 'PUT')
			changeOrderStatus({ id: order.id, ...res.data })
			setShowPreloader(false)
			setShowDeleteNotification(false)

		} catch (error) {

			dispatch({ type: REQUEST_FAILED, payload: { createdAt: Date.now(), message: error.message } })
		}
	}

	const repeatOrder = async () => {
		try {

			setShowPreloader(true)
			await request('order/repeat', { id: order.id }, {}, 'POST')
			history.push('/checkout/')

		} catch (error) {
			dispatch({ type: REQUEST_FAILED, payload: { createdAt: Date.now(), message: error.message } })
		}
	}

	return (
		<>
			<table className="table">
				<thead className="table__head">
					<tr className="table__row">
						{Object.keys(tableHead).map(index => (
							<td
								className={`table__cell table__cell--head order-list__tableHeadСellDetail order-list__tableHeadСellDetail--${index}`}
								key={index}
							>
								{tableHead[index]}
							</td>
						))}
						{/* {
							<td className="table__cell order-list__tableHeadСellDetail--icon">
								<div
									title="повторить заказ"
									className="order-list__actionDetail order-list__actionDetail"
									onClick={() => setShowRepeatNotification(true)}
								>
									{
										showPreloader && showRepeatNotification ?
											<CirclePreloader width="14px" height="14px" /> :
											<IconRefresh />
									}

								</div>
								{
									showRepeatNotification &&
									<ConfirmNotification
										ref={repeatNotification}
										className='order-list__notification'
										notification={`Текущие товары в корзине будут удалены`}
										confirmCallback={repeatOrder}
										cancelCallback={() => setShowRepeatNotification(false)}
										disableButton={showPreloader}
									/>
								}
								{!canceled &&
									<div
										title="отменить заказ"
										className="order-list__actionDetail order-list__actionDetail--ml20"
										onClick={() => setShowDeleteNotification(true)}
									>
										{
											showPreloader && showDeleteNotification ?
												<CirclePreloader width="14px" height="14px" /> :
												<IconCancelOrder className="order-list__arrowCancel" />
										}

									</div>
								}

								{showDeleteNotification &&
									<ConfirmNotification
										ref={deleteNotification}
										className='order-list__notification'
										notification={`Вы точно хотите отменить заказ №${order.id}?`}
										confirmCallback={cancelOrder}
										cancelCallback={() => setShowDeleteNotification(false)}
										disableButton={showPreloader}
									/>
								}
							</td>
						} */}

					</tr>
				</thead>
				<tbody className="table__body">
					{order.products.map((item, key) => {
						return (
							<tr
								className="table__row"
								key={key}>
								{Object.keys(tableHead).map((index, i, arr) => {
									return <td
										className={
											classNames(
												'table__cell order-list__tableBodyСell order-list__tableBodyСellDetail',
												order.products.length === key + 1 && !order.delivery.address && !order.comment && 'order-list__tableBodyСellDetail--notBorder',
												index === 'measure' && 'order-list__tableBodyСellDetail--measure'
											)
										}
										key={index}
										colSpan={arr.length === i + 1 ? 2 : 1}>
										{
											index === 'price' || index === 'sum' ?
												priceFormatter(item[index]) :
												item[index]
										}
									</td>
								})}
							</tr>
						)
					})}
				</tbody>
			</table>
			{
				order.delivery.address &&
				<div className="order-list__address">
					<div className="order-list__addressTitle">Доставка:</div>
					<div className="order-list__addressText">
						{`${order.delivery.address} - ${order.delivery.price > 0 ? `${priceFormatter(order.delivery.price)}` : 'доставка включена в стоимость заказа'}`}
					</div>
				</div>
			}
			{
				order.comment &&
				<div className="order-list__comment">
					<div className="order-list__commentTitle">Комментарий:</div>
					<div className="order-list__commentText">{order.comment}</div>
				</div>
			}

		</>

	)
}

OrderDetail.propTypes = {
	order: PropTypes.object.isRequired,
	canceled: PropTypes.bool,
	changeOrderStatus: PropTypes.func,
}
export default OrderDetail