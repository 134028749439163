import React, { useState, useEffect, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import { useFetch } from 'hooks/useFetch'

import { priceFormatter } from 'helpers'

import { AuthContext } from 'context/AuthContext'

import { REQUEST_FAILED } from 'redux/types/errorTypes'

import PaymentDocsListSkeleton from 'components/ui/skeletons/PaymentDocsListSkeleton'
import PaymentDocsListFilter from './PaymentDocsListFilter'
import Pagination from 'components/ui/Pagination/Pagination'
import Title from 'components/ui/Title/Title'

import 'scss/common.blocks/table.scss'
import './style.scss'

const PaymentDocsTable = () => {

	let tableHead = {
		createdDate: 'Дата заказа',
		payDate: 'Дата погашения',
		expiredDays: 'Дней просрочки',
		debetSum: 'Сумма долга',
		expDebetSum: 'Сумма просрочки',
		name: 'Название'
	}

	const dispatch = useDispatch()
	const history = useHistory()
	const auth = useContext(AuthContext)

	const { request, error } = useFetch()

	const [paginationSetting, setPaginationSetting] = useState({
		currentPage: 1,
		elementCount: null,
		pagesCount: null,
	})

	const [filterParams, setFilterParams] = useState({
		expired: false,
	})

	const [paymentDocsList, setPaymentDocsList] = useState([])
	const [paymentDocsTotal, setPaymentDocsTotal] = useState({})
	const [paymentDocsLoading, setPaymentDocsLoading] = useState(false)

	const loadData = async (newPage = false, filter = {}) => {

		try {

			const getParams = new URLSearchParams(history.location.search)
			const getParamsPage = getParams.get('page')

			const { currentPage } = paginationSetting
			const page = newPage || getParamsPage || currentPage

			if (paymentDocsLoading) {
				setPaymentDocsLoading(false)

			}

			const { expired } = filter

			const res = await request(
				'reference/debet',
				{
					navPage: page,
					...(expired && { 'filter[expired]': expired }),
				}
			)

			const { list, total, navigation } = res.data

			setPaymentDocsList(list)
			setPaymentDocsTotal(total)

			setPaymentDocsLoading(true)

			if (navigation.pagesCount > 1) {
				setPaginationSetting(navigation)
			}

			if (newPage) {
				history.push({
					search: `?page=${newPage}`
				})
			}

		} catch (error) {
			dispatch({ type: REQUEST_FAILED, payload: { createdAt: Date.now(), message: error.message || 'Ошибка загрузки данных' } })
		}
	}

	useEffect(() => {

		loadData()

	}, [])

	useEffect(() => {

		if (paymentDocsLoading) {
			loadData()
		}

	}, [auth.user.login])

	useEffect(() => {

		if (paymentDocsLoading) {
			loadData(false, filterParams)
		}

	}, [filterParams])

	return (
		<>
			<div className="payment-docs-list">
				<div className="payment-docs-list__topBlock">
					<Title content='Документы к оплате' type="h2" />
					<PaymentDocsListFilter filterParams={filterParams} setFilterParams={setFilterParams} />
				</div>
				<table className="table payment-docs-list__table">
					<thead className="table__head">
						<tr className="table__row">
							{Object.keys(tableHead).map(index => (
								<td
									className={`table__cell table__cell--head payment-docs-list__tableCell--${index}`}
									key={index}
								>
									{tableHead[index]}
								</td>
							))}
						</tr>
					</thead>

					{paymentDocsList.length > 0 && paymentDocsLoading ?
						<>
							<tbody className="table__body">
								{paymentDocsList.map(item => {
									return (
										<tr
											className="table__row"
											key={item.UALIAS_0}
										>
											<td className='table__cell'>
												{item.createdDate}
											</td>
											<td className='table__cell'>
												{item.payDate}
											</td>
											<td className='table__cell'>
												{item.expiredDays}
											</td>
											<td className='table__cell'>
												{priceFormatter(item.debetSum)}
											</td>
											<td className='table__cell'>
												{priceFormatter(item.expDebetSum)}
											</td>
											<td className='table__cell'>
												{item.name}
											</td>
										</tr>
									)
								})}
							</tbody>
							<tfoot className="table__foot">
								<tr className="table__row">
									<td
										className="table__cell table__cell--foot"
										colSpan="3">Итого</td>
									<td
										className="table__cell table__cell--foot"
										colSpan="1">{priceFormatter(paymentDocsTotal.debet)}</td>
									<td
										className="table__cell table__cell--foot"
										colSpan="2">{priceFormatter(paymentDocsTotal.expdebet)}</td>
								</tr>
							</tfoot>
						</>
						:
						<tbody className="table__body">
							{
								!error && !paymentDocsLoading
									?
									<PaymentDocsListSkeleton rowCount={paymentDocsList.length || 4} totalRow={paymentDocsList.length !== 0} />
									:
									<tr className="table__row">
										{
											paymentDocsList.length === 0 && paymentDocsLoading
												?
												<td className='table__cell payment-docs-list__tableCell--somethingWrong' colSpan='6'>
													Нет ни одной записи
												</td>
												:
												<td className='table__cell payment-docs-list__tableCell--somethingWrong' colSpan='6'>
													Ошибка загрузки данных
												</td>

										}
									</tr>

							}
						</tbody>
					}
				</table >
			</div>
			{
				paginationSetting.pagesCount > 1 && paymentDocsList.length > 0 &&
				<Pagination
					setting={paginationSetting}
					callback={page => loadData(page, filterParams)}
					className='payment-docs-list__pagination'
				/>
			}
		</>

	)

}

export default PaymentDocsTable