import React from 'react'
import PropTypes from 'prop-types'

const IconTrash = ({ className, onClick }) => {
	return (
		<svg
			className={className}
			onClick={onClick}
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			stroke="#969FA8"
		>
			<path 
				d="M6.5 7.20001H17.5V18C17.5 19.1046 16.6046 20 15.5 20H8.5C7.39543 20 6.5 19.1046 6.5 18V7.20001Z"
				stroke="inherit"
				strokeWidth="2"
				strokeLinejoin="round"
			/>
			<path 
				d="M5 7.20001H19"
				stroke="inherit"
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path 
				d="M9 5.6C9 4.71634 9.71634 4 10.6 4H13.4C14.2837 4 15 4.71634 15 5.6V5.6V7.2H9V5.6V5.6Z"
				stroke="inherit"
				strokeWidth="2"
				strokeLinejoin="round"
			/>
		</svg>

	)

}

IconTrash.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func
}

export default IconTrash
