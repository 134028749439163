import React from 'react'

import './skeleton.scss'

const MapSkeleton = () => {

	return (
		<div className="skeleton skeleton__loadMap">Загрузка карты</div>
	)
}

export default MapSkeleton