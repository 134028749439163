import React from 'react'
import PropTypes from 'prop-types'

const IconSuccess = ({ className }) => {
	return (
		<svg
			className={className} width="64"
			height="64"
			viewBox="0 0 64 64"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">

			<path
				fillRule="evenodd" clipRule="evenodd"
				d="M45.64 23.7246C46.0178 24.1275 45.9974 24.7604 45.5944 25.1381L29.2791 40.4337L28.5952 41.0749L27.9112 40.4337L19.7536 32.7859C19.3506 32.4082 19.3302 31.7754 19.708 31.3724C20.0857 30.9695 20.7185 30.9491 21.1214 31.3268L28.5952 38.3335L44.2266 23.679C44.6295 23.3013 45.2623 23.3217 45.64 23.7246Z" fill="#37AA00" />
			<circle
				cx="32" cy="32"
				r="31.25" stroke="#37AA00"
				strokeWidth="1.5" />
		</svg>
	)
}

IconSuccess.propTypes = {
	className: PropTypes.string,

}

export default IconSuccess