import React, { useState, useRef, useContext } from 'react'
import { useHistory } from 'react-router'
import { useFormik } from 'formik'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import TokenService from 'services/TokenService'

import { AuthContext } from 'context/AuthContext'

import Title from 'components/ui/Title/Title'
import Input from 'components/ui/Input/Input'
import Button from 'components/ui/Button/Button'

import IconPassword from 'assets/icons/IconPassword'
import CirclePreloader from 'assets/preloaders/Circle'

import './style.scss'

const authFormSchema = Yup.object().shape({
	login: Yup.string().required(' '),
	password: Yup.string().required(' '),
})

const AuthForm = ({ changeViewForm }) => {

	const auth = useContext(AuthContext)

	const history = useHistory()

	const [inputPasswordType, setInputPasswordType] = useState('password')

	const inputPasswordRef = useRef(null)

	const changeInputType = () => {
		const inputNode = inputPasswordRef.current.getInputNode()
		setInputPasswordType(inputNode.type.length > 4 ? 'text' : 'password')
	}

	const formik = useFormik({
		validationSchema: authFormSchema,
		initialValues: {
			login: '',
			password: '',
		},
		initialStatus: false,
		onSubmit: async values => {
			try {

				const { login, password } = values

				await TokenService.getToken('token/get', login, password)

				auth.login({
					login, failedCallback: () => formik.setSubmitting(false)
				})

			} catch (error) {

				formik.setFieldError('server', 'Пользователь не найден. Проверьте правильность введенных данных')

			}

		},
	})

	return (
		<form
			className="auth-form"
			onSubmit={formik.handleSubmit}
		>
			<Title
				content="Войти"
				className="auth-form__title"
			/>

			<div className="auth-form__note">
				Ещё нет корпоративного аккаунта?
				<div className="auth-form__noteLink" onClick={() => history.push('/register')}>
					Отправить заявку
				</div>
			</div>

			<Input
				className={
					classNames(
						'auth-form__input auth-form__input--mb30 auth-form__inputLogin',
						formik.errors.login && formik.touched.login && 'auth-form__input--error'
					)
				}

				placeholder="Логин"
				name="login"
				value={formik.values.login}
				onChange={formik.handleChange}
			/>

			<Input
				className={
					classNames(
						'auth-form__input auth-form__inputPassword',
						formik.errors.password && formik.touched.password && 'auth-form__input--error'
					)
				}
				ref={inputPasswordRef}
				placeholder="Пароль"
				type={inputPasswordType}
				name="password"
				value={formik.values.password}
				onChange={formik.handleChange}
			>
				<IconPassword
					className={
						classNames(
							'auth-form__inputIconPassword',
							inputPasswordType === 'text' && 'auth-form__inputIconPassword--active'
						)
					}
					onClick={() => changeInputType()} />
			</Input>
			<div
				className="auth-form__forgotPass"
				onClick={() => changeViewForm('forgotPass')}
			>
				Забыли пароль?
			</div>
			<div className="auth-form__bottom">
				{
					formik.errors.server &&
					<div className="auth-form__message auth-form__message--error">
						{formik.errors.server}
					</div>
				}
				{

					<Button
						type="submit"
						className="auth-form__submit"
						text={
							!formik.isSubmitting ?
								'Далее' :
								<CirclePreloader className="auth-form__preloader" />
						}
					/>

				}

			</div>

		</form>
	)
}

AuthForm.propTypes = {
	changeViewForm: PropTypes.func,
}

export default AuthForm