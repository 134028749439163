import React, { forwardRef, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import SimpleBar from 'simplebar-react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { priceFormatter } from 'helpers'
import useDispatchSaga from 'hooks/useDispatchSaga'
import { useOnClickOutside } from 'hooks/useOnClickOutside'
import BasketService from 'services/BasketService'

import SwitchQuantity from 'components/SwitchQuantity/SwitchQuantity'
import HeaderBasket from 'components/HeaderBasket/HeaderBasket'

import IconDelete from 'assets/icons/IconDelete'
import CirclePreloader from 'assets/preloaders/Circle'

import 'simplebar/dist/simplebar.min.css'
import './basket-fixed.scss'

const BasketFixed = forwardRef(({ refBasketHeader, className, onClose }, ref) => {

	const history = useHistory()
	const { dispatchSaga } = useDispatchSaga()

	const { basket } = useSelector(state => state.basket)

	const [productDeletingId, setProductDeletingId] = useState('')
	const [productDeletingAll, setProductDeletingAll] = useState(false)
	const [productChangeQuantityId, setProductChangeQuantityId] = useState('')

	const changeQuantityProduct = (product) => {

		if (product.quantity.length !== 0) {

			setProductChangeQuantityId(product.id)
			BasketService.changeQuantityBasketItem(product, dispatchSaga, () => setProductChangeQuantityId(''))

		}

	}

	const deleteProduct = (id) => {

		setProductDeletingId(id)
		BasketService.deleteToBasket(id, dispatchSaga, () => setProductDeletingId(''))

	}

	const clearBasket = () => {

		setProductDeletingAll(true)
		BasketService.deleteAllBasket(dispatchSaga, () => setProductDeletingAll(false))

	}

	const toCheckout = () => {
		history.push('/checkout/')
		onClose()
	}

	useOnClickOutside([ref], event => {

		if (!refBasketHeader.current.contains(event.target)) {
			onClose()
		}
	})

	useEffect(() => {

		// Отрабатывает при удалении товара из корзины
		if (productDeletingId.length > 0) {
			setProductDeletingId('')
		}

		// Отрабатывает при изменениии кол-ва товара в корзине
		if (productChangeQuantityId.length > 0) {
			setProductChangeQuantityId('')
		}

		// Отрабатывает при полной отчистке корзины
		if (basket.length === 0) {
			setProductDeletingAll(false)
			onClose()
		}

	}, [basket])
	useEffect(() => {
		history.listen(() => {
			onClose()
		})

	}, [])

	return (
		<div className={classNames('basket-fixed', className)} ref={ref}>
			<HeaderBasket
				className="basket-fixed__top"
				onClose={onClose}
			/>
			<SimpleBar style={{ height: 'calc(100% - 161px)' }}>
				<div className="basket-fixed__items">
					{basket.map((item, key) => {
						return (
							<div
								className="basket-fixed__item"
								key={key}>
								<div className="basket-fixed__itemName" onClick={() => history.push(`/catalog/${item.sectionId}/${item.product_id}/`)}>
									{item.name}
								</div>
								<div className="basket-fixed__itemParams">
									<SwitchQuantity
										debounced
										className="basket-fixed__switchQuantity"
										item={item}
										processing={productChangeQuantityId === item.id}
										callback={item => changeQuantityProduct(item)}
									>
									</SwitchQuantity>
									<div className="basket-fixed__itemPrice">
										{priceFormatter(item.quantity * item.price)}
									</div>
									<div className="basket-fixed__delete" >
										{
											productDeletingId === item.id ?
												<CirclePreloader className="basket-fixed__preloader" /> :
												<IconDelete
													className="basket-fixed__delete--icon"
													onClick={() => deleteProduct(item.id)}
												/>

										}

									</div>
								</div>
							</div>
						)
					})}

				</div>
			</SimpleBar>

			<div
				className="basket-fixed__button basket-fixed__button--clear"
				onClick={() => clearBasket()}
			>
				{
					productDeletingAll ?
						<CirclePreloader /> :
						'Очистить корзину'
				}

			</div>
			<div
				className="basket-fixed__button basket-fixed__button--toСheckout"
				onClick={() => toCheckout()}
			>
				Оформить заказ
			</div>
		</div>

	)

})

BasketFixed.propTypes = {
	refBasketHeader: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
	className: PropTypes.string,
	onClose: PropTypes.func,
}
BasketFixed.displayName = 'BasketFixed'

export default BasketFixed