/**
 * Class to manage token info
 * 
*/
const storageName = 'userData'

class LocalStorageService {

	/**
	 * Get localStorage info
	 * 
	 * @return {object}
	*/
	static get() {
		return JSON.parse(localStorage.getItem(storageName))
	}

	/**
	 * Set localStorage info
	 * 
	 * @param {object} localStorage
	 * 
	 * @return {void}
	 * 
	*/

	static set({ login, productCatalogPricesView, agreement, inAvailability, inAvailabilityFavorite }) {

		localStorage.setItem(storageName, JSON.stringify({
			login,
			agreement: agreement,
			productCatalogPricesView: productCatalogPricesView,
			inAvailability: inAvailability,
			inAvailabilityFavorite: inAvailabilityFavorite
		}))

	}

	/**
	 * Remove localStorage info
	 * 
	 * @return {void}
	 * 
	*/
	static remove() {
		localStorage.removeItem(storageName)
	}
}

export default LocalStorageService