import {
	SET_PAGE_NOT_FOUND,
} from '../types/appTypes'

const initialState = {
	pageNotFound: false,
}

export const appReducer = (state = initialState, action) => {
	switch (action.type) {
		case SET_PAGE_NOT_FOUND:
			return { ...state, pageNotFound: action.payload }
		default: return state
	}
}
