import React from 'react'

const IconAvailability = () => {
	return (
		<svg
			width="2" height="9"
			viewBox="0 0 2 9" fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<ellipse
				rx="0.75" ry="0.791668"
				transform="matrix(-1 0 0 1 1 1.59712)" fill="#0256B1" />
			<rect
				width="1.5" height="5.27778"
				rx="0.75" transform="matrix(-1 0 0 1 1.75 3.44443)"
				fill="#0256B1" />
		</svg>
	)
}

export default IconAvailability