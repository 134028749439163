const cleanPhone = (phone, prefix) => {
	return (phone || '').replace(prefix, '').replace(/\D/g, '')
}

const phoneFormatter = (
	phone,
	format = {
		prefix: '+7 ',
		variableDigits: 10,
		delimiters: { 0: '(', 3: ') ', 6: '-', 8: '-' },
	}
) => {

	const numbers = cleanPhone(phone, format.prefix)

	if (!numbers.length) {
		return ''
	}

	let formatted = format.prefix

	for (const [key, value] of Object.entries({ ...numbers })) {
		formatted += (format.delimiters[key] || '') + value
	}

	const totalDelimitersLength = Object.keys(format.delimiters).reduce(
		(prev, key) => (prev += format.delimiters[key].length),
		0
	)

	return formatted.slice(
		0,
		totalDelimitersLength + format.prefix.length + format.variableDigits
	)
}

export default phoneFormatter