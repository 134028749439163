import React, { forwardRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Parser } from 'html-to-react'

import Radio from 'components/ui/Radio/Radio'
import RadioSkeleton from 'components/ui/skeletons/RadioSkeleton'

import './select-transport.scss'
const SelectTransport = forwardRef(({ className, cars, onChange, carActiveId }, ref) => {

	const htmlToReactParser = new Parser()

	const [activeCar, setActiveCar] = useState({})
	const [carsList, setCarsList] = useState([])

	useEffect(() => {

		setCarsList(cars)

	}, [cars])

	useEffect(() => {
		const [first] = cars
		const carActive = carsList.find(car => car.id === carActiveId) || first
		carsList.length > 0 && setActiveCar(carActive)

	}, [carActiveId, cars, carsList])

	return (
		<div className={classNames('select-transport', className)} ref={ref}>
			<div className="select-transport__title">Выберите необходимый тип транспорта</div>
			<div className="select-transport__controls">
				{
					carsList.length > 0 &&
					carsList.map((car, i) => {
						return (
							car.name ?
								<Radio
									key={i}
									className='select-transport__control'
									label={car.name}
									value={car.id}
									name="transport"
									id={`radio-${car.id}`}
									checked={car.id === activeCar.id}
									callback={() => {
										setActiveCar(car)
										onChange(car)
									}}
								/> :
								<RadioSkeleton key={i} />
						)
					})}
			</div>
			{activeCar &&
				<>
					<img
						className="select-transport__image"
						src={activeCar.image}
					/>
					{
						activeCar.property && activeCar.property.length > 0 &&
						<div className="select-transport__params">
							{
								activeCar.property.map((property, key) => {
									return (
										<div key={key} className="select-transport__param">
											<div className="select-transport__paramValue">
												{property.value}
											</div>
											<div className="select-transport__paramName">
												{htmlToReactParser.parse(property.name)}
											</div>
										</div>
									)
								})
							}
						</div>
					}
				</>

			}

		</div>
	)

})

SelectTransport.propTypes = {
	className: PropTypes.string,
	carsToShippingType: PropTypes.string,
	cars: PropTypes.array,
	carActiveId: PropTypes.string,
	onChange: PropTypes.func,
}
SelectTransport.displayName = 'SelectTransport'
export default SelectTransport