import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import './radio.scss'

const Radio = ({ className, label, callback, ...rest }) => (
	<div className={classNames('radio', className)}>
		<input
			className="radio__control"
			type="radio"
			onChange={callback}
			{...rest}
		/>
		<label
			className="radio__text"
			htmlFor={rest.id}>{label}</label>
	</div>
)

Radio.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string.isRequired,
	callback: PropTypes.func,
	rest: PropTypes.object,

}

export default Radio
