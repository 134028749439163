import {
	REQUEST_CHANGE_PRODUCT_TO_FOLDERS,
	REQUEST_GET_FOLDER_LIST,
	REQUEST_DELETE_FOLDER,
	REQUEST_CHANGE_FOLDER,
	REQUEST_ADD_FOLDER
} from '../types/favoriteTypes'

export function fetchFolderList() {
	return {
		type: REQUEST_GET_FOLDER_LIST
	}
}

export function addFolderAction(folderName) {
	return {
		type: REQUEST_ADD_FOLDER,
		payload: {
			name: folderName
		}
	}
}

export function deleteFolderAction(folderId) {
	return {
		type: REQUEST_DELETE_FOLDER,
		payload: {
			element_id: folderId
		}
	}
}

export function changeFolderAction(params) {
	return {
		type: REQUEST_CHANGE_FOLDER,
		payload: params
	}
}

export function changeProductToFoldersAction(params) {
	return {
		type: REQUEST_CHANGE_PRODUCT_TO_FOLDERS,
		payload: params
	}
}