import React, { useContext, useState, useEffect } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { AuthContext } from 'context/AuthContext'
import { useFetch } from 'hooks/useFetch'

import Checkbox from 'components/ui/Checkbox/Checkbox'
import Button from 'components/ui/Button/Button'
import Modal from 'components/ui/Modal'

import './notifications-settings.scss'

const ModalNotificationSettings = ({ onClose }) => {

	const auth = useContext(AuthContext)
	const { request } = useFetch()

	const { user, setUser } = auth

	const [checkboxList, setCheckboxList] = useState(null)
	const [responseMessage, setResponseMessage] = useState('')

	const checkboxListInitial = user.notifications.map(notification => {
		return {
			id: notification.id,
			name: notification.name,
			type: notification.type,
			checked: notification.checked
		}
	})

	const update = async () => {
		
		const checkedList = checkboxList.filter(item => item.type !== 'DISABLE' && item.checked)
		const data = checkedList.length > 0 ? checkedList.map(item => item.id) : [checkboxList.find(item => item.type === 'DISABLE').id]

		const response = await request('personal/user/notifications', {}, { checkedList: data }, 'PUT')
		setResponseMessage(response.data.message)
		
	}

	useEffect(() => setCheckboxList(checkboxListInitial), [user])

	useEffect(() => {

		if (responseMessage.length > 0) {
			setTimeout(() => {
				setUser(prevState => {
					return { ...prevState, notifications: checkboxList }
				})
				setResponseMessage('')
				onClose()
			}, 2000)
		}

	}, [responseMessage])

	return (
		<Modal classNameWrapper="notification-settings" onClose={() => onClose()}>
			<>
				<div className='notification-settings__title'>
					Настройки оповещений
				</div>
				
				<div className='notification-settings__items'>
					{
						checkboxList && checkboxList.filter(notification => notification.type !== 'DISABLE').map((notification, key) => {
							return <Checkbox
								key={key}
								id={notification.type}
								label={notification.name}
								value={notification.type}
								checked={notification.checked}
								type='main'
								className='notification-settings__item'
								onChange={e => {

									setCheckboxList(prevState => {
										return prevState.map(prevItem => {
											return prevItem.type === notification.type ? { ...prevItem, checked: e.target.checked } : prevItem
										})
									})
									
								}}
							/>
						})
					}
				</div>
				<div className='notification-settings__buttons'>
					{
						responseMessage.length > 0 &&
						<div className='notification-settings__message'>{responseMessage}</div>
					}
					<Button 
						onClick={() => update()}
						className={classNames('notification-settings__button', 'notification-settings__button--save')}
						text='Сохранить'
					/>
					<Button 
						onClick={() => {
							setCheckboxList(checkboxListInitial)
							onClose()
						}}
						className={classNames('notification-settings__button', 'notification-settings__button--cancel')}
						text='Закрыть'
					/>
				</div>
				
			</>
		</Modal>
	)
}

ModalNotificationSettings.propTypes = {
	onClose: PropTypes.func,
}

export default ModalNotificationSettings