import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import PropTypes from 'prop-types'

import { REQUEST_FAILED } from 'redux/types/errorTypes'

import { useFetch } from 'hooks/useFetch'

import ProductsTable from 'components/ProductsTable/ProductsTable'
import Pagination from 'components/ui/Pagination/Pagination'

import 'simplebar/dist/simplebar.min.css'
import './search-elements.scss'

const SearchElements = ({ query, title, clearSearchResult }) => {

	const dispatch = useDispatch()
	const history = useHistory()

	const { request } = useFetch()

	const paginationSettingInitialState = {
		currentPage: 1,
		elementCount: null,
		pagesCount: null,
	}

	const [items, setItems] = useState([])
	const [filterAvailability, setFilterAvailability] = useState(false)
	const [preloader, setPreloader] = useState(true)

	const [paginationSetting, setPaginationSetting] = useState(paginationSettingInitialState)

	const loadDataWithPagination = async (newPage = false, inAvailability = false) => {

		try {

			if (items.length > 0) {
				setItems([])
				setPreloader(true)
			}

			const getParams = new URLSearchParams(history.location.search)
			const getParamsPage = getParams.get('page')

			const { currentPage } = paginationSetting
			const page = newPage || getParamsPage || currentPage

			const res = await request(
				'catalog/search',
				{
					query: query,
					navPage: page,
					...(inAvailability && { 'filter[availability]': inAvailability }),
				}
			)

			const { list, navigation } = res.data

			setItems(list)

			if (filterAvailability !== inAvailability) {
				setFilterAvailability(inAvailability)
			}

			if (newPage) {
				history.push({

					search: `?query=${query}&page=${newPage}`
				})
			}

			if (navigation.pagesCount > 1) {
				setPaginationSetting(navigation)
			}

		} catch (error) {
			dispatch({ type: REQUEST_FAILED, payload: { createdAt: Date.now(), message: error.message || 'Ошибка загрузки данных' } })
		} finally {
			setPreloader(false)
		}

	}

	useEffect(() => {

		if (query.length > 0 && title === query) {
			setPaginationSetting(paginationSettingInitialState)

			loadDataWithPagination(false, localStorage.inAvailability)
		}

	}, [query])

	useEffect(() => {
		if (query !== title) {
			setPreloader(true)
		}

	}, [query, title])

	return (

		<div className="search-elements">
			{
				preloader && items.length === 0 ?
					<div className="search-elements__loading">Загрузка</div> :
					items.length > 0 ?
						<>
							<ProductsTable
								title={`Результат по запросу «${title}»`}
								products={items}
								inAvailability={filterAvailability}
								filterProduct={availability => loadDataWithPagination(false, availability)}
								isSearch
								clearSearchResult={clearSearchResult}
							/>
							{

								paginationSetting.pagesCount > 1 &&
								<Pagination
									setting={paginationSetting}
									callback={page => loadDataWithPagination(page, localStorage.inAvailability)}
									className='search-elements__pagination'
								/>
							}
						</> : <div className="search-elements__empty">{`По запросу «${title}» ничего не найдено`}</div>
			}

		</div>
	)
}
SearchElements.propTypes = {
	query: PropTypes.string,
	title: PropTypes.string,
	clearSearchResult: PropTypes.func,
}
export default SearchElements
