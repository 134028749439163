import React from 'react'
import PropTypes from 'prop-types'

const IconNotDelivery = ({ className }) => {
	return (
		<svg
			className={className}
			width="460" height="460"
			viewBox="0 0 460 460" fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path d="M377.587 346.564C377.26 346.056 376.991 345.513 376.787 344.945C376.318 343.896 375.683 342.35 374.947 340.428C373.438 336.582 371.534 331.228 369.427 325.266C367.32 319.304 365.379 313.987 363.824 310.178C363.042 308.274 362.398 306.737 361.938 305.679C361.664 305.145 361.454 304.58 361.312 303.996C361.675 304.479 361.983 305 362.232 305.55C362.775 306.572 363.493 308.08 364.339 309.966C366.032 313.738 368.093 319.065 370.172 325.008C372.251 330.952 374.036 336.186 375.444 340.234L377.072 344.834C377.303 345.392 377.476 345.971 377.587 346.564Z" fill="#E0E0E0" />
			<path d="M368.745 408.94C368.559 408.779 368.392 408.597 368.248 408.397L366.933 406.75C365.792 405.306 364.173 403.181 362.259 400.54C358.386 395.241 353.271 387.752 347.732 379.426C342.194 371.101 337.143 363.566 333.39 358.193L328.918 351.845C328.431 351.155 327.998 350.585 327.713 350.115C327.428 349.646 327.308 349.499 327.336 349.481C327.363 349.462 327.52 349.646 327.833 350.023L329.148 351.67C330.289 353.105 331.908 355.231 333.822 357.88C337.695 363.179 342.81 370.659 348.358 378.994C353.905 387.329 358.938 394.855 362.691 400.218L367.163 406.566L368.368 408.305C368.635 408.701 368.773 408.922 368.745 408.94Z" fill="#E0E0E0" />
			<path
				opacity="0.07" d="M56.2151 146.411L43.9258 209.693L137.815 227.926L150.104 164.644L56.2151 146.411Z"
				fill="#0256B1" />
			<path
				d="M150.255 165.462C149.933 165.721 149.584 165.946 149.216 166.134L146.161 167.918L134.818 174.358L97.1619 195.316L96.8583 195.482L96.6283 195.215L96.0671 194.562L67.8599 161.202L59.3867 151.036L57.1143 148.276C56.8315 147.966 56.5817 147.627 56.3691 147.264C56.6854 147.546 56.9778 147.854 57.2431 148.184L59.6443 150.871L68.3291 160.862L96.7295 194.037L97.2907 194.69L96.7479 194.589L134.523 173.862L145.996 167.679L149.133 166.042C149.488 165.813 149.863 165.619 150.255 165.462Z" fill="#0256B1"
				fillOpacity="0.15" />
			<path
				d="M44.0782 210.514C43.9218 210.321 52.671 203.026 63.6098 194.203C74.5486 185.38 83.537 178.379 83.6842 178.563C83.8314 178.747 75.0914 186.052 64.1526 194.874C53.2138 203.697 44.2254 210.68 44.0782 210.514Z" fill="#0256B1"
				fillOpacity="0.15" />
			<path
				d="M115.046 183.834C115.257 183.724 120.566 193.697 126.886 206.089C133.206 218.482 138.174 228.638 137.926 228.749C137.678 228.859 132.406 218.896 126.076 206.485C119.747 194.074 114.834 183.945 115.046 183.834Z" fill="#0256B1"
				fillOpacity="0.15" />
			<path
				opacity="0.07" d="M187.947 133.722L128.598 139.932L123.713 98.6977L183.062 92.4785L187.947 133.722Z"
				fill="#0256B1" />
			<path
				d="M183.31 92.9202C183.171 93.1401 183.007 93.3438 182.823 93.5274L181.351 95.1926L175.831 101.255L157.477 121.146L157.33 121.311L157.136 121.183L156.676 120.888C147.642 114.927 139.454 109.545 133.474 105.607L126.473 100.915L124.633 99.6178C124.406 99.4794 124.194 99.3192 123.998 99.1394C124.24 99.2464 124.471 99.3757 124.688 99.5258L126.629 100.74C128.359 101.835 130.769 103.381 133.722 105.276L157.008 120.419L157.468 120.713H157.127C164.34 113.004 170.844 105.993 175.601 100.943L181.231 95.0086C181.875 94.3462 182.39 93.8126 182.786 93.417C182.948 93.238 183.123 93.0719 183.31 92.9202Z" fill="#0256B1"
				fillOpacity="0.15" />
			<path
				d="M128.847 140.41C128.718 140.328 132.527 134.2 137.32 126.739C142.113 119.278 146.115 113.298 146.244 113.39C146.373 113.482 142.564 119.591 137.771 127.061C132.978 134.532 128.967 140.493 128.847 140.41Z" fill="#0256B1"
				fillOpacity="0.15" />
			<path
				d="M165.912 110.805C166.022 110.704 171.092 115.847 177.246 122.305C183.401 128.763 188.286 134.09 188.194 134.191C188.102 134.293 183.015 129.15 176.86 122.691C170.705 116.233 165.802 110.915 165.912 110.805Z" fill="#0256B1"
				fillOpacity="0.15" />
			<path
				opacity="0.07" d="M105.708 83.7385L72.9102 61.0237L88.4029 37.9961L121.201 60.7017L105.708 83.7385Z"
				fill="#0256B1" />
			<path
				d="M121.118 61.0513C120.951 61.1039 120.778 61.1379 120.603 61.1525L119.131 61.3549L113.703 62.0265L95.7263 64.1057H95.5791V63.9677C95.5791 63.8481 95.5147 63.7285 95.4779 63.6089L90.3259 45.7701C89.7003 43.5069 89.1851 41.6669 88.8263 40.3421L88.4307 38.8701C88.3753 38.6997 88.3383 38.5239 88.3203 38.3457C88.3961 38.5056 88.4577 38.6718 88.5043 38.8425C88.6147 39.2105 88.7711 39.6981 88.9551 40.3053C89.3507 41.6117 89.8751 43.4425 90.5743 45.6873C91.9175 50.2873 93.7575 56.5709 95.7815 63.5169L95.8827 63.8665L95.6987 63.7377L113.676 61.7597L119.122 61.2077L120.603 61.0789C120.774 61.0574 120.946 61.0482 121.118 61.0513Z" fill="#0256B1"
				fillOpacity="0.15" />
			<path
				d="M72.8281 61.3733C72.8281 61.2813 77.4281 59.8277 83.0769 58.1441C88.7257 56.4605 93.3993 55.1633 93.4269 55.2553C93.4545 55.3473 88.8269 56.8101 83.1689 58.4937C77.5109 60.1773 72.8557 61.4745 72.8281 61.3733Z" fill="#0256B1"
				fillOpacity="0.15" />
			<path
				d="M104.522 62.5601C104.623 62.5601 104.945 67.3717 105.258 73.3241C105.571 79.2765 105.727 84.1157 105.626 84.1249C105.525 84.1341 105.194 79.3133 104.89 73.3609C104.586 67.4085 104.421 62.5601 104.522 62.5601Z" fill="#0256B1"
				fillOpacity="0.15" />
			<path
				opacity="0.07" d="M369.345 113.961C369.358 108.334 368.073 102.781 365.59 97.7319C363.106 92.6832 359.491 88.2757 355.026 84.8523C350.561 81.4289 345.366 79.082 339.845 77.9942C334.325 76.9064 328.628 77.107 323.198 78.5805C317.768 80.054 312.751 82.7605 308.538 86.4896C304.325 90.2186 301.028 94.8695 298.906 100.08C296.784 105.291 295.893 110.922 296.302 116.533C296.711 122.145 298.409 127.586 301.265 132.434L301.366 132.572C302.765 134.937 304.522 136.63 306.297 139.114L327.522 167.238C328.139 168.052 328.936 168.712 329.85 169.166C330.765 169.621 331.772 169.857 332.793 169.857C333.815 169.857 334.822 169.621 335.737 169.166C336.651 168.712 337.448 168.052 338.065 167.238L358.949 139.574C360.848 137.328 362.62 134.978 364.257 132.536L364.322 132.48C367.623 126.868 369.357 120.472 369.345 113.961ZM332.775 129.886C329.591 129.886 326.478 128.942 323.83 127.173C321.183 125.403 319.119 122.889 317.9 119.947C316.682 117.005 316.363 113.768 316.984 110.645C317.606 107.522 319.139 104.653 321.391 102.402C323.642 100.15 326.511 98.6165 329.634 97.9953C332.757 97.3741 335.994 97.6929 338.936 98.9115C341.878 100.13 344.392 102.194 346.162 104.841C347.931 107.489 348.875 110.602 348.875 113.786C348.875 118.056 347.179 122.151 344.159 125.17C341.14 128.19 337.045 129.886 332.775 129.886Z"
				fill="#0256B1" />
			<path
				opacity="0.07" d="M397.31 176.723C397.33 173.681 396.646 170.675 395.312 167.941C393.977 165.207 392.029 162.819 389.618 160.963C387.207 159.107 384.4 157.834 381.415 157.244C378.431 156.653 375.35 156.761 372.414 157.558C369.478 158.356 366.767 159.822 364.492 161.842C362.216 163.861 360.439 166.38 359.299 169.201C358.159 172.021 357.687 175.067 357.919 178.101C358.152 181.134 359.083 184.073 360.639 186.686L360.695 186.76C361.449 188.03 362.397 188.95 363.353 190.284L374.807 205.454C375.138 205.894 375.567 206.251 376.059 206.497C376.552 206.743 377.095 206.871 377.645 206.871C378.196 206.871 378.739 206.743 379.232 206.497C379.724 206.251 380.153 205.894 380.484 205.454L391.744 190.532C392.772 189.323 393.73 188.058 394.615 186.742V186.686C396.388 183.666 397.319 180.225 397.31 176.723ZM377.586 185.306C375.87 185.305 374.194 184.794 372.768 183.84C371.343 182.885 370.232 181.53 369.577 179.944C368.922 178.359 368.751 176.615 369.087 174.933C369.423 173.25 370.25 171.705 371.464 170.493C372.677 169.281 374.223 168.455 375.906 168.121C377.588 167.787 379.332 167.96 380.917 168.616C382.502 169.273 383.856 170.385 384.809 171.812C385.762 173.238 386.27 174.915 386.27 176.631C386.27 177.771 386.046 178.9 385.609 179.953C385.173 181.006 384.533 181.963 383.726 182.769C382.92 183.574 381.962 184.213 380.908 184.649C379.855 185.084 378.726 185.308 377.586 185.306Z"
				fill="#0256B1" />
			<path d="M433.661 426.668C433.661 426.806 342.47 426.908 230.009 426.908C117.549 426.908 26.3398 426.806 26.3398 426.668C26.3398 426.53 117.512 426.429 230.009 426.429C342.507 426.429 433.661 426.54 433.661 426.668Z" fill="#9CB7D1" />
			<path d="M174.201 109.425L180.255 195.169C180.734 201.916 183.754 208.231 188.705 212.841C193.656 217.451 200.17 220.012 206.935 220.009C210.596 220.009 214.219 219.258 217.578 217.801C220.937 216.344 223.962 214.212 226.463 211.538C228.964 208.864 230.89 205.705 232.12 202.256C233.35 198.807 233.859 195.142 233.615 191.489C233.081 183.328 232.593 176.649 232.593 176.649C232.593 176.649 253.358 173.42 253.615 152.665C253.763 140.705 251.693 113.473 249.935 92.6349C249.355 85.8399 246.245 79.5103 241.22 74.8986C236.196 70.2868 229.624 67.7287 222.805 67.7305H210.228C205.099 68.0296 200.082 69.3549 195.474 71.6277C190.866 73.9005 186.761 77.0747 183.401 80.9624C180.042 84.8501 177.497 89.3723 175.917 94.2612C174.336 99.1501 173.753 104.306 174.201 109.425Z" fill="#FFBE9D" />
			<path d="M232.612 176.391C232.612 176.391 219.493 177.459 205.555 168.7C205.555 168.7 211.949 183.641 232.658 181.773L232.612 176.391Z" fill="#EB996E" />
			<path d="M243.451 117.76C243.459 118.533 243.164 119.279 242.629 119.838C242.094 120.396 241.363 120.724 240.59 120.75C240.213 120.78 239.834 120.734 239.475 120.616C239.117 120.497 238.785 120.308 238.5 120.06C238.216 119.811 237.984 119.508 237.818 119.168C237.652 118.829 237.556 118.459 237.535 118.082C237.528 117.31 237.821 116.564 238.353 116.004C238.886 115.444 239.615 115.114 240.387 115.083C240.765 115.053 241.145 115.099 241.505 115.218C241.865 115.337 242.198 115.527 242.483 115.776C242.769 116.026 243.001 116.33 243.167 116.67C243.334 117.011 243.43 117.382 243.451 117.76Z" fill="#263238" />
			<path d="M236.51 108.63C236.978 108.374 238.614 110.633 241.65 111.59C244.686 112.547 247.396 111.739 247.62 112.216C247.725 112.433 247.14 113.029 245.963 113.388C244.358 113.879 242.64 113.861 241.045 113.338C239.468 112.843 238.091 111.856 237.114 110.522C236.388 109.499 236.284 108.732 236.51 108.63Z" fill="#263238" />
			<path d="M212.328 117.76C212.336 118.533 212.041 119.279 211.506 119.837C210.971 120.396 210.239 120.724 209.467 120.75C209.09 120.78 208.711 120.734 208.352 120.616C207.993 120.497 207.662 120.308 207.377 120.059C207.093 119.811 206.861 119.508 206.695 119.168C206.529 118.829 206.433 118.459 206.412 118.082C206.405 117.308 206.699 116.561 207.234 116.001C207.768 115.441 208.5 115.111 209.273 115.083C209.651 115.054 210.03 115.101 210.389 115.22C210.747 115.34 211.079 115.53 211.363 115.779C211.648 116.029 211.88 116.332 212.045 116.672C212.211 117.012 212.307 117.382 212.328 117.76Z" fill="#263238" />
			<path d="M200.62 112.384C200.844 111.9 203.514 112.752 206.525 111.72C209.537 110.688 211.235 108.42 211.702 108.68C211.915 108.792 211.788 109.564 211.061 110.598C210.056 111.942 208.666 112.949 207.076 113.485C205.511 114.011 203.821 114.034 202.241 113.553C201.046 113.152 200.508 112.597 200.62 112.384Z" fill="#263238" />
			<path d="M226.551 137.743C226.551 137.559 228.519 137.218 231.73 136.823C232.54 136.731 233.313 136.574 233.451 136.013C233.521 135.173 233.332 134.331 232.908 133.603C232.147 131.634 231.344 129.567 230.497 127.402C227.158 118.57 224.729 111.311 225.088 111.182C225.447 111.053 228.446 118.101 231.795 126.923C232.598 129.107 233.368 131.189 234.104 133.17C234.408 134.09 234.941 135.139 234.518 136.363C234.402 136.65 234.222 136.907 233.99 137.113C233.759 137.319 233.483 137.468 233.184 137.549C232.735 137.677 232.271 137.742 231.804 137.743C228.565 137.908 226.56 137.927 226.551 137.743Z" fill="#263238" />
			<path d="M227.825 147.711C227.328 147.844 226.431 144.532 222.933 142.926C219.436 141.32 215.979 142.637 215.829 142.182C215.718 141.983 216.446 141.321 217.936 140.874C219.888 140.322 221.973 140.475 223.823 141.306C225.6 142.106 226.996 143.567 227.715 145.378C228.22 146.767 228.061 147.667 227.825 147.711Z" fill="#263238" />
			<path d="M215.794 101.586C215.481 102.506 212.298 102.046 208.554 102.506C204.809 102.966 201.782 103.978 201.286 103.196C201.065 102.828 201.589 102.01 202.813 101.172C204.44 100.144 206.279 99.5001 208.192 99.2882C210.104 99.0762 212.04 99.3021 213.853 99.9488C215.279 100.501 215.932 101.2 215.794 101.586Z" fill="#263238" />
			<path d="M245.153 104.264C244.591 104.99 242.393 104.264 239.752 104.199C237.112 104.135 234.876 104.687 234.361 103.932C234.131 103.564 234.481 102.838 235.465 102.148C236.778 101.325 238.306 100.912 239.854 100.961C241.402 101.011 242.901 101.52 244.159 102.424C245.088 103.16 245.401 103.96 245.153 104.264Z" fill="#263238" />
			<path d="M181.239 125C180.88 124.844 166.979 120.787 167.439 134.973C167.899 149.16 182.039 145.783 182.058 145.369C182.076 144.955 181.239 125 181.239 125Z" fill="#FFBE9D" />
			<path d="M177.504 140.134C177.439 140.134 177.264 140.318 176.85 140.521C176.272 140.788 175.614 140.825 175.01 140.622C173.492 140.116 172.158 137.779 172.002 135.222C171.931 134.007 172.129 132.791 172.582 131.661C172.705 131.209 172.937 130.794 173.258 130.453C173.579 130.111 173.978 129.853 174.422 129.702C174.704 129.618 175.006 129.635 175.277 129.751C175.547 129.867 175.769 130.074 175.903 130.336C176.105 130.723 176.022 131.008 176.096 131.036C176.17 131.063 176.4 130.787 176.262 130.198C176.17 129.851 175.962 129.546 175.673 129.334C175.475 129.191 175.252 129.09 175.015 129.034C174.778 128.979 174.533 128.971 174.293 129.012C173.714 129.147 173.18 129.427 172.741 129.826C172.301 130.226 171.971 130.731 171.781 131.293C171.222 132.543 170.972 133.91 171.054 135.277C171.238 138.138 172.784 140.797 174.854 141.294C175.239 141.393 175.642 141.403 176.032 141.325C176.423 141.247 176.79 141.082 177.108 140.843C177.485 140.484 177.559 140.162 177.504 140.134Z" fill="#EB996E" />
			<path d="M173.31 124.522C175.472 123.998 179.216 125.994 180.136 128.018C181.056 130.042 181.259 132.342 182.179 134.375C183.099 136.408 185.178 138.23 187.349 137.715C188.083 137.485 188.743 137.066 189.261 136.498C189.78 135.931 190.139 135.237 190.302 134.486C190.615 132.97 190.631 131.407 190.348 129.886C189.511 123.262 188.122 116.693 187.855 110.014C187.588 103.334 188.536 96.4067 192.041 90.6935C195.546 84.9803 201.977 80.8219 208.601 81.3647C213.008 81.7143 217.056 84.0143 221.435 84.6307C224.044 84.9758 226.698 84.7218 229.194 83.8881C231.691 83.0544 233.964 81.6629 235.842 79.8191C236.321 79.3499 238.529 77.8871 238.529 77.8871C238.529 77.5467 238.207 74.5843 237.038 73.4987C228.344 65.4671 212.686 63.2223 201.204 66.1387C189.723 69.0551 178.959 77.1787 174.018 87.8875C169.078 98.5963 170.55 113.648 173.273 124.504" fill="#263238" />
			<path d="M250.129 89.4792C250.129 89.4792 195.683 71.9992 172.131 119.066C172.131 119.066 168.607 107.281 168.607 100.961C168.607 96.7379 171.128 71.6036 196.999 63.6916C196.999 63.6916 235.961 48.8244 250.129 89.4792Z" fill="#004EA3" />
			<path d="M203.319 90.6568C204.286 92.2164 205.603 93.53 207.164 94.4941C208.726 95.4582 210.49 96.0463 212.318 96.2122C214.146 96.3782 215.987 96.1173 217.697 95.4501C219.406 94.783 220.938 93.7279 222.17 92.368C222.711 93.7216 223.663 94.8718 224.891 95.6573C226.119 96.4429 227.562 96.8244 229.018 96.7483C230.474 96.6722 231.869 96.1423 233.009 95.2331C234.148 94.3238 234.975 93.0806 235.372 91.678C236.848 93.3231 238.653 94.6396 240.671 95.542C242.741 96.3056 245.363 96.0112 246.716 94.2816C248.068 92.552 247.7 89.8196 246.338 88.0348C244.851 86.3471 242.948 85.0784 240.818 84.3548C234.364 81.5163 227.271 80.4432 220.266 81.2452C213.32 82.1652 206.594 85.5508 202.252 91.0524" fill="#263238" />
			<path d="M174.404 113.555C182.473 101.2 193.605 90.9879 206.126 83.1954C218.647 75.4031 239.338 67.6475 253.598 63.8755C257.885 62.7347 262.347 61.7411 266.717 62.4955C271.087 63.2499 275.356 66.1755 276.432 70.4535C277.794 75.8355 268.244 84.1338 263.764 87.3906C259.284 90.6474 250.13 95.4406 250.13 95.4406C250.13 95.4406 250.258 85.4678 234.287 83.4162C212.575 80.5734 174.404 113.555 174.404 113.555Z" fill="#0256B1" />
			<path d="M219.062 72.8639C219.062 72.8639 226.9 68.5215 234.784 67.5095C233.468 66.4861 232.031 65.6272 230.506 64.9519C226.227 65.6593 222.089 67.0509 218.252 69.0735L219.062 72.8639Z" fill="#D8DBE3" />
			<path d="M263.248 194.543L245.98 190.734L238.951 188.977L233.1 179.906C233.1 179.906 230.34 196.126 219.861 204.856C218.442 206.017 216.779 206.842 214.996 207.269C213.214 207.696 211.357 207.714 209.566 207.322C207.202 206.825 204.424 206.218 202.363 205.666C201.921 205.546 201.443 205.39 200.928 205.206C193.352 202.393 187.04 196.95 183.144 189.87C180.826 185.748 178.866 181.516 179.538 179.906L171.34 196.88L123.04 219.126L95.6152 286.12L148.764 301.834L150.42 357.034L140.41 426.622L290.766 426.724L285.43 271.897" fill="#0256B1" />
			<path d="M224.379 254.481C224.073 254.505 223.765 254.505 223.459 254.481L220.782 254.417L211.223 254.076H211.039V253.92C210.882 249.771 210.689 244.858 210.478 239.605C210.229 232.033 210.008 225.188 209.834 220.156C209.778 217.709 209.732 215.712 209.696 214.259C209.696 213.605 209.696 213.072 209.696 212.658C209.672 212.472 209.672 212.283 209.696 212.097C209.738 212.278 209.76 212.463 209.76 212.649C209.76 213.072 209.824 213.569 209.861 214.249C209.935 215.703 210.036 217.699 210.156 220.137C210.358 225.17 210.634 232.015 210.938 239.577C211.122 244.839 211.287 249.752 211.434 253.902L211.232 253.708L220.791 254.214L223.459 254.389C223.768 254.386 224.076 254.417 224.379 254.481Z" fill="#00448E" />
			<path d="M216.2 232.116C216.411 231.968 216.661 231.885 216.918 231.877C217.515 231.838 218.111 231.972 218.634 232.265C219.156 232.557 219.583 232.994 219.862 233.523C220.124 234.04 220.218 234.626 220.129 235.198C220.031 235.846 219.746 236.453 219.31 236.943C218.874 237.432 218.305 237.785 217.672 237.958C217.032 238.092 216.367 238.047 215.75 237.828C215.133 237.609 214.589 237.224 214.176 236.716C213.842 236.306 213.608 235.824 213.493 235.309C213.378 234.793 213.385 234.257 213.514 233.744C213.657 233.046 214.002 232.406 214.508 231.904C214.857 231.564 215.115 231.454 215.124 231.481C215.133 231.509 214.94 231.674 214.646 232.033C214.238 232.537 213.963 233.135 213.845 233.772C213.752 234.231 213.763 234.705 213.877 235.158C213.992 235.612 214.208 236.035 214.508 236.394C214.876 236.828 215.353 237.155 215.89 237.343C216.427 237.531 217.004 237.571 217.562 237.461C218.105 237.308 218.593 237.003 218.97 236.583C219.346 236.163 219.595 235.644 219.687 235.087C219.764 234.631 219.712 234.163 219.538 233.734C219.365 233.305 219.075 232.933 218.703 232.659C218.198 232.256 217.573 232.032 216.927 232.024C216.449 232.061 216.2 232.153 216.2 232.116Z" fill="#00448E" />
			<path d="M214.571 212.676C214.784 212.49 215.046 212.368 215.325 212.327C216.089 212.165 216.886 212.283 217.57 212.658C218.056 212.906 218.471 213.273 218.775 213.725C219.129 214.255 219.306 214.883 219.281 215.519C219.252 216.245 219.005 216.945 218.572 217.528C218.138 218.11 217.539 218.549 216.853 218.785C216.151 218.965 215.411 218.933 214.727 218.694C214.043 218.455 213.445 218.02 213.007 217.442C212.619 216.944 212.385 216.342 212.335 215.712C212.307 215.17 212.395 214.627 212.593 214.121C212.878 213.401 213.384 212.789 214.037 212.373C214.265 212.203 214.536 212.1 214.819 212.078C214.819 212.078 214.553 212.216 214.148 212.529C213.579 212.967 213.15 213.562 212.915 214.24C212.73 214.726 212.674 215.252 212.751 215.766C212.829 216.28 213.037 216.765 213.357 217.175C213.745 217.671 214.269 218.044 214.865 218.248C215.461 218.452 216.104 218.479 216.715 218.325C217.307 218.115 217.824 217.735 218.202 217.233C218.58 216.731 218.802 216.128 218.84 215.501C218.864 214.985 218.743 214.473 218.491 214.023C218.239 213.572 217.866 213.201 217.414 212.952C216.795 212.588 216.076 212.433 215.362 212.511C214.856 212.575 214.589 212.713 214.571 212.676Z" fill="#00448E" />
			<path d="M209.676 286.929C209.676 286.929 209.749 286.883 209.906 286.828L210.596 286.561C211.212 286.35 212.086 285.963 213.199 285.448C216.308 283.881 219.162 281.852 221.663 279.431C225.341 275.81 228.591 271.778 231.351 267.416C234.802 262.114 237.953 256.622 240.79 250.967C245.892 240.659 250.368 230.054 254.195 219.208C255.694 215.05 256.863 211.673 257.681 209.346L258.601 206.66C258.703 206.384 258.795 206.154 258.859 205.97C258.885 205.89 258.919 205.813 258.96 205.74C258.953 205.824 258.935 205.908 258.905 205.988C258.85 206.172 258.776 206.402 258.693 206.696C258.482 207.349 258.197 208.251 257.838 209.456C257.074 211.802 255.952 215.197 254.48 219.365C250.752 230.24 246.321 240.861 241.213 251.16C238.369 256.831 235.199 262.333 231.719 267.637C228.927 272.023 225.629 276.065 221.893 279.68C219.352 282.097 216.446 284.099 213.282 285.614C212.417 286.011 211.529 286.358 210.623 286.653L209.915 286.874C209.839 286.905 209.758 286.923 209.676 286.929Z" fill="#00448E" />
			<path d="M241.352 268.557C241.748 268.557 250.902 267.932 250.902 267.932C250.902 267.932 247.121 274.611 243.183 277.132H235.17L241.352 268.557Z" fill="#D8DBE3" />
			<path d="M208.076 307.16C208.233 307.01 208.406 306.877 208.591 306.765L210.137 305.698C211.471 304.778 213.394 303.398 215.703 301.622C220.303 298.098 226.43 292.836 232.981 286.819C236.256 283.811 239.421 280.977 242.125 278.254C244.553 275.866 246.806 273.308 248.869 270.6C250.253 268.772 251.484 266.834 252.549 264.804C252.954 264.095 253.202 263.516 253.395 263.129C253.483 262.926 253.591 262.731 253.717 262.55C253.658 262.761 253.581 262.967 253.487 263.166C253.322 263.562 253.092 264.16 252.715 264.886C251.704 266.963 250.506 268.943 249.136 270.802C247.1 273.557 244.858 276.152 242.429 278.567C239.715 281.327 236.569 284.17 233.293 287.169C226.743 293.186 220.607 298.43 215.905 301.889C213.559 303.628 211.6 304.952 210.238 305.845C209.548 306.286 209.005 306.608 208.637 306.838C208.463 306.966 208.275 307.074 208.076 307.16Z" fill="#00448E" />
			<path d="M182.279 409.4C182.279 409.4 182.279 409.317 182.39 409.179C182.5 409.041 182.62 408.82 182.767 408.572C183.107 408.047 183.622 407.275 184.312 406.299C185.692 404.367 187.827 401.644 190.642 398.443C196.309 392.085 204.249 383.41 212.989 373.805L235.52 349.25L242.374 341.89L244.214 339.894L244.71 339.388L244.894 339.222C244.894 339.222 244.848 339.296 244.747 339.415L244.287 339.958L242.447 342.019L235.704 349.517L213.348 374.099L190.9 398.645C188.057 401.81 185.886 404.487 184.46 406.382C183.751 407.302 183.208 408.084 182.85 408.599L182.436 409.179C182.39 409.257 182.337 409.331 182.279 409.4Z" fill="#00448E" />
			<path d="M262.053 194.12C262.844 195.307 280.618 197.285 284.97 201.627C289.322 205.97 332.81 239.163 332.81 239.163L301.815 280.876L281.814 276.469L262.053 194.12Z" fill="#0256B1" />
			<path
				opacity="0.33" d="M327.537 235.115L292.789 278.889L301.814 280.876L332.772 239.117L327.537 235.115Z"
				fill="#002752" />
			<path d="M271.518 237.544C271.363 237.069 271.243 236.583 271.16 236.09C271.058 235.621 270.939 235.041 270.856 234.361C270.773 233.68 270.635 232.907 270.589 232.042C270.543 231.177 270.451 230.202 270.451 229.218C270.451 228.233 270.451 227.12 270.534 225.979C270.713 223.499 271.097 221.037 271.684 218.62C272.31 216.214 273.138 213.866 274.159 211.6C274.646 210.57 275.079 209.576 275.603 208.693C276.128 207.809 276.578 206.991 277.048 206.264C277.517 205.537 277.968 204.911 278.372 204.35C278.707 203.869 279.066 203.405 279.449 202.961C279.75 202.562 280.086 202.189 280.452 201.848C280.2 202.279 279.914 202.688 279.596 203.072C279.311 203.458 278.961 203.927 278.593 204.507C278.225 205.086 277.756 205.703 277.333 206.448C276.91 207.193 276.413 207.984 275.953 208.886C275.493 209.788 275.033 210.726 274.564 211.775C273.595 214.032 272.801 216.36 272.19 218.739C271.617 221.126 271.226 223.552 271.022 225.998C270.957 227.129 270.874 228.206 270.902 229.209C270.93 230.211 270.902 231.15 270.976 231.969C271.049 232.787 271.104 233.597 271.169 234.278C271.233 234.959 271.316 235.538 271.39 236.007C271.482 236.514 271.525 237.029 271.518 237.544Z" fill="#00448E" />
			<path d="M285.43 271.842C284.005 269.079 282.725 266.245 281.593 263.35C280.312 260.521 279.176 257.628 278.189 254.684C279.614 257.444 280.898 260.275 282.035 263.166C283.319 265.996 284.452 268.892 285.43 271.842Z" fill="#00448E" />
			<path d="M148.763 241.537C148.689 241.537 147.944 240.203 146.537 238.133C144.7 235.436 142.598 232.931 140.262 230.653C137.915 228.393 135.351 226.368 132.608 224.609C130.492 223.265 129.103 222.566 129.139 222.493C129.492 222.59 129.832 222.729 130.151 222.907C131.052 223.334 131.93 223.807 132.783 224.323C135.613 226.01 138.237 228.02 140.603 230.313C142.96 232.612 145.047 235.174 146.822 237.949C147.493 239.016 147.999 239.899 148.312 240.534C148.503 240.848 148.654 241.185 148.763 241.537Z" fill="#00448E" />
			<path
				opacity="0.33" d="M95.6152 286.092L97.7588 280.876L156.547 296.884L154.219 303.425L95.6152 286.092Z"
				fill="#002752" />
			<path d="M252.559 265.475H274.942V274.675L246.303 274.491C246.303 274.491 250.728 269.165 252.559 265.475Z" fill="#D8DBE3" />
			<g opacity="0.2">
				<path
					opacity="0.33" d="M172.721 198.904C173.53 206.08 174.414 213.486 177.965 219.779C181.516 226.072 188.462 231.03 195.61 229.972C200.376 229.264 204.415 226.081 207.662 222.511C210.91 218.942 213.633 214.903 217.175 211.637C219.317 213.944 221.712 216.002 224.314 217.773C226.943 219.554 230.1 220.388 233.266 220.138C236.385 219.76 238.105 218.518 239.09 215.538C235.759 216.568 233.928 216.412 230.607 215.777C226.94 215.074 223.497 213.496 220.57 211.177C218.62 209.64 217.911 207.331 215.436 207.497C213.146 207.626 211.351 209.438 209.843 211.177C207.407 213.965 204.735 216.539 201.857 218.868C199.097 221.048 196.429 222.548 192.915 222.447C187.128 222.318 182.39 217.773 179.197 212.943C176.251 208.49 174.074 203.573 172.757 198.398"
					fill="black" />
			</g>
			<path d="M240.468 189.952C240.521 190.12 240.555 190.293 240.57 190.468C240.57 190.808 240.68 191.305 240.744 191.949C240.882 193.237 241.02 195.104 241.076 197.469C241.2 203.517 240.623 209.558 239.355 215.473V215.62V215.768H239.217C235.947 216.534 232.533 216.442 229.309 215.501C226.672 214.796 224.181 213.631 221.949 212.06C220.438 211.033 219.084 209.794 217.928 208.38C217.618 207.998 217.329 207.599 217.064 207.184C216.961 207.043 216.871 206.892 216.797 206.733C218.298 208.696 220.098 210.41 222.133 211.812C224.353 213.311 226.81 214.425 229.401 215.105C232.556 215.996 235.885 216.076 239.079 215.335L238.904 215.51V215.363C240.157 209.478 240.774 203.476 240.744 197.46C240.744 195.15 240.643 193.283 240.57 191.986C240.57 191.388 240.505 190.9 240.478 190.504C240.448 190.322 240.445 190.136 240.468 189.952Z" fill="#00448E" />
			<path d="M212.52 208.104C212.447 208.3 212.348 208.486 212.225 208.656C211.917 209.166 211.579 209.658 211.213 210.128C209.84 211.842 208.326 213.439 206.687 214.903C205.666 215.823 204.516 216.844 203.274 217.893C202.648 218.417 202.023 218.969 201.323 219.521C200.603 220.064 199.844 220.553 199.051 220.984C197.348 221.923 195.468 222.496 193.531 222.667C191.418 222.847 189.3 222.378 187.459 221.324C185.667 220.257 184.061 218.905 182.703 217.322C181.415 215.888 180.217 214.377 179.115 212.796C177.257 210.113 175.687 207.242 174.432 204.231C173.591 202.206 172.902 200.121 172.371 197.993C172.178 197.239 172.058 196.65 171.985 196.245C171.933 196.043 171.903 195.837 171.893 195.629C171.977 195.82 172.039 196.021 172.077 196.227C172.187 196.622 172.334 197.202 172.546 197.947C173.149 200.043 173.884 202.098 174.745 204.102C176.034 207.067 177.621 209.894 179.483 212.538C180.58 214.097 181.772 215.588 183.052 217C184.366 218.542 185.919 219.862 187.652 220.91C189.413 221.916 191.437 222.365 193.457 222.198C195.339 222.036 197.167 221.492 198.83 220.597C199.611 220.184 200.358 219.71 201.066 219.181C201.737 218.665 202.381 218.104 203.007 217.589C204.258 216.549 205.417 215.574 206.448 214.654C208.092 213.236 209.63 211.699 211.048 210.054C211.535 209.465 211.894 208.978 212.133 208.647C212.246 208.455 212.375 208.273 212.52 208.104Z" fill="#00448E" />
			<path d="M151.137 377.715L192.5 354.5L180.5 333.988H140.189L147.062 301.273L105.965 289.11L95.6798 345.138C94.0974 370.052 114.08 390.163 138.165 383.622C142.782 382.373 147.164 380.378 151.137 377.715Z" fill="#FFBE9D" />
			<path d="M131.422 351.596C131.303 351.331 131.225 351.048 131.192 350.759C131.056 349.984 130.979 349.2 130.962 348.413C130.928 345.795 131.514 343.207 132.674 340.86C133.849 338.519 135.568 336.493 137.688 334.953C138.319 334.485 138.99 334.073 139.693 333.721C139.945 333.573 140.218 333.465 140.503 333.399C140.503 333.481 139.426 334.006 137.89 335.239C135.878 336.81 134.243 338.811 133.103 341.095C131.963 343.379 131.348 345.888 131.303 348.441C131.257 350.373 131.56 351.587 131.422 351.596Z" fill="#EB996E" />
			<path d="M301.814 280.876C302.228 280.306 349.066 308.844 349.066 308.844L364.08 377.246L385.691 385.177C385.691 385.177 392.434 309.056 387.301 297.206C382.167 285.356 330.123 242.724 330.123 242.724L301.814 280.876Z" fill="#FFBE9D" />
			<path d="M359.296 303.6C358.253 305.168 356.865 306.477 355.239 307.427C353.697 308.513 351.929 309.236 350.068 309.543C351.749 308.79 353.389 307.949 354.981 307.022C356.482 305.963 357.923 304.821 359.296 303.6Z" fill="#EB996E" />
			<path d="M245.482 281.023L249.153 346.674L324.004 341.614L321.741 292.744L310.839 278.07L245.482 281.023Z" fill="#EC6608" />
			<path d="M228.473 348.137C228.693 349.398 235.051 408.857 235.051 408.857L346.453 401.12L365.304 398.59L356.61 343.224L343.298 340.032L228.473 348.137Z" fill="#EC6608" />
			<path d="M343.298 340.023L228.473 348.137C228.693 349.398 235.051 408.857 235.051 408.857L351.035 400.789L343.298 340.023Z" fill="#E45E00" />
			<path d="M312.78 342.369L249.153 346.674L245.482 281.023L310.839 278.07L312.78 342.369Z" fill="#C25000" />
			<path d="M269.211 279.956L270.554 300.187L273.958 298.236L275.844 300.187L278.19 298.236L281.815 300.187V279.385L269.211 279.956Z" fill="#9E4100" />
			<path d="M295.807 343.749L291.455 294.474L333.987 306.194L338.09 340.207L295.807 343.749Z" fill="#91C2FF" />
			<path d="M333.96 306.204C333.885 306.33 333.795 306.447 333.693 306.553L332.875 307.519L329.747 311.089L318.881 323.279L318.716 323.463L318.541 323.288L312.8 317.216L297.648 301.208L293.094 296.314L291.87 294.97C291.719 294.822 291.586 294.655 291.475 294.474C291.643 294.601 291.8 294.743 291.944 294.897L293.232 296.185L297.887 300.969L313.141 316.885L318.918 322.966H318.578L329.618 310.886L332.847 307.409L333.721 306.489C333.791 306.386 333.871 306.29 333.96 306.204Z" fill="#5283BF" />
			<path d="M310.141 315.33C310.261 315.394 307.225 321.209 303.37 328.32C299.515 335.432 296.295 341.145 296.175 341.081C296.056 341.016 299.092 335.202 302.947 328.09C306.801 320.979 310.04 315.265 310.141 315.33Z" fill="#5283BF" />
			<path d="M337.097 340.207C336.986 340.271 334.042 335.561 330.519 329.691C326.995 323.821 324.226 318.991 324.346 318.918C324.465 318.844 327.4 323.564 330.924 329.443C334.447 335.321 337.216 340.142 337.097 340.207Z" fill="#263238" />
			<path d="M318.651 341.826L315.873 310.445L342.958 317.915L345.571 339.572L318.651 341.826Z" fill="#2388F5" />
			<path
				opacity="0.4" d="M318.651 341.826L315.873 310.445L342.958 317.915L345.571 339.572L318.651 341.826Z"
				fill="white" />
			<path d="M342.958 317.915C342.765 318.223 342.54 318.51 342.287 318.771C341.781 319.369 341.118 320.132 340.318 321.062L333.418 328.845L333.252 329.038L333.078 328.854L329.398 324.99C325.616 321.025 322.212 317.409 319.765 314.778L316.895 311.641C316.523 311.272 316.185 310.872 315.883 310.445C316.291 310.769 316.673 311.126 317.024 311.512L320.014 314.539C322.525 317.115 325.966 320.694 329.747 324.659L333.427 328.532H333.041L340.06 320.859L342.14 318.66C342.388 318.387 342.663 318.137 342.958 317.915Z" fill="#4A86C7" />
			<path d="M327.777 323.729C327.888 323.794 325.992 327.511 323.536 332.009C322.126 334.784 320.573 337.484 318.881 340.096C320.146 337.264 321.559 334.501 323.113 331.816C324.523 329.04 326.08 326.341 327.777 323.729Z" fill="#4A86C7" />
			<path d="M344.946 339.572C343.391 337.443 341.965 335.221 340.677 332.92C339.248 330.701 337.96 328.393 336.822 326.011C339.792 330.369 342.505 334.897 344.946 339.572Z" fill="#4A86C7" />
			<path d="M335.91 316.59V319.111L339.866 319.571L340.078 317.648L335.91 316.59Z" fill="white" />
			<path d="M321.945 303.793V307.473L329.416 308.706L329.83 305.891L321.945 303.793Z" fill="white" />
			<path
				opacity="0.33" d="M292.789 362.094L292.421 343.749L277.545 344.752L279.137 363.391L292.789 362.094Z"
				fill="#9E4100" />
			<path d="M341.302 371.732L316.326 374.101L318.422 396.192L343.398 393.823L341.302 371.732Z" fill="#FAFAFA" />
			<path d="M343.361 393.852C343.331 393.712 343.309 393.571 343.297 393.429C343.297 393.125 343.214 392.729 343.159 392.233C343.049 391.165 342.883 389.647 342.681 387.743C342.285 383.861 341.761 378.377 341.061 371.781L341.282 371.965L316.323 374.366C316.267 374.421 316.727 373.879 316.534 374.109V374.274V374.467V374.835L316.608 375.59L316.746 377.08C316.847 378.065 316.939 379.04 317.031 380.015C317.206 381.956 317.39 383.851 317.565 385.71C317.905 389.39 318.236 392.932 318.531 396.179L318.301 395.995L336.323 394.395L341.439 393.962L342.809 393.861C342.972 393.848 343.135 393.848 343.297 393.861C343.155 393.894 343.01 393.916 342.865 393.925L341.54 394.082L336.48 394.615L318.347 396.455H318.135V396.244C317.813 392.987 317.473 389.473 317.114 385.774C316.942 383.934 316.761 382.036 316.571 380.079C316.479 379.104 316.387 378.129 316.295 377.135C316.249 376.648 316.203 376.151 316.148 375.654L316.083 374.9V374.532V374.339V374.173C315.89 374.403 316.341 373.851 316.304 373.906L341.273 371.579H341.475V371.781C342.064 378.433 342.552 383.971 342.901 387.89C343.058 389.776 343.177 391.276 343.269 392.343C343.269 392.831 343.334 393.217 343.352 393.511C343.364 393.624 343.367 393.738 343.361 393.852Z" fill="#8B3900" />
			<path d="M339.314 377.053C339.314 377.191 334.962 377.752 329.562 378.322C326.311 378.75 323.041 379.014 319.764 379.114C319.764 378.985 324.115 378.414 329.516 377.844C334.916 377.274 339.304 376.924 339.314 377.053Z" fill="#8B3900" />
			<path d="M339.168 380.806C338.3 381.119 337.404 381.344 336.491 381.478C334.817 381.809 332.489 382.196 329.904 382.499C327.319 382.803 324.954 382.968 323.252 383.024C322.334 383.106 321.41 383.106 320.492 383.024C321.402 382.844 322.325 382.739 323.252 382.711C324.945 382.554 327.282 382.343 329.858 382.039C332.434 381.736 334.761 381.404 336.445 381.119C337.344 380.947 338.254 380.843 339.168 380.806Z" fill="#8B3900" />
			<path d="M340.224 389.013C339.353 389.323 338.453 389.547 337.538 389.684C335.863 390.015 333.536 390.402 330.951 390.705C328.365 391.009 326.01 391.175 324.299 391.23C323.381 391.319 322.457 391.319 321.539 391.23C322.449 391.05 323.372 390.945 324.299 390.917C325.992 390.77 328.338 390.549 330.914 390.245C333.49 389.942 335.817 389.611 337.501 389.325C338.4 389.153 339.31 389.049 340.224 389.013Z" fill="#8B3900" />
			<path d="M338.965 384.808C335.962 385.487 332.925 386.003 329.866 386.354C326.82 386.871 323.749 387.227 320.666 387.421C323.671 386.753 326.708 386.237 329.765 385.876C332.811 385.364 335.882 385.008 338.965 384.808Z" fill="#8B3900" />
			<path d="M361.017 419.023C361.017 419.023 381.579 405.416 383.161 398.461C384.744 391.506 385.691 385.176 385.691 385.176L363.722 376.078L365.902 383.99C365.902 383.99 358.027 388.672 357.217 394.349C356.684 398.13 346.499 404.607 346.499 404.607C345.652 403.583 344.574 402.775 343.354 402.249C342.134 401.722 340.806 401.493 339.48 401.58L330.924 402.187L336.711 415.224L361.017 419.023Z" fill="#FFBE9D" />
			<path d="M369.049 400.375C368.751 400.557 368.424 400.691 368.083 400.77C367.439 400.982 366.483 401.194 365.323 401.562C364.015 402.027 362.804 402.732 361.754 403.641C360.43 404.77 358.948 405.699 357.356 406.401C354.411 407.655 351.14 407.93 348.027 407.183C347.48 407.077 346.955 406.881 346.473 406.603C346.275 406.491 346.115 406.323 346.013 406.12C345.911 405.917 345.873 405.688 345.902 405.462C345.918 405.322 345.968 405.189 346.048 405.073C346.128 404.957 346.236 404.863 346.362 404.8C346.546 404.736 346.647 404.8 346.647 404.8C346.647 404.8 346.546 404.8 346.408 404.901C346.312 404.971 346.233 405.062 346.177 405.168C346.121 405.273 346.09 405.389 346.086 405.508C346.069 405.698 346.111 405.889 346.207 406.053C346.302 406.218 346.446 406.349 346.62 406.428C347.09 406.659 347.592 406.818 348.11 406.898C351.154 407.517 354.315 407.197 357.172 405.978C358.728 405.285 360.178 404.374 361.478 403.273C362.58 402.358 363.848 401.664 365.213 401.23C366.391 400.899 367.347 400.743 367.973 400.577C368.326 400.481 368.686 400.414 369.049 400.375Z" fill="#EB996E" />
			<path d="M354.808 410.927C354.808 411.001 353.75 410.394 352.002 409.667C349.723 408.735 347.321 408.137 344.871 407.891C344.154 407.818 343.446 407.799 342.765 407.781H342.636L342.562 407.67C341.497 406.003 340.213 404.487 338.744 403.162C337.631 402.16 336.904 401.644 336.904 401.589C336.904 401.534 337.125 401.663 337.475 401.884C337.971 402.205 338.45 402.552 338.91 402.923C340.478 404.202 341.835 405.72 342.93 407.422L342.737 407.312C343.436 407.312 344.154 407.312 344.89 407.422C347.388 407.663 349.829 408.319 352.112 409.363C352.799 409.676 353.466 410.033 354.108 410.43C354.366 410.559 354.602 410.726 354.808 410.927Z" fill="#EB996E" />
			<path d="M360.365 407.192C360.365 407.321 358.976 407.845 357.357 408.765C355.738 409.685 354.551 410.651 354.459 410.541C355.978 408.77 358.067 407.586 360.365 407.192Z" fill="#EB996E" />
			<path d="M371.505 382.72C371.505 382.867 370.069 382.72 368.432 383.134C366.794 383.548 365.672 384.394 365.543 384.275C366.304 383.501 367.26 382.949 368.309 382.675C369.359 382.401 370.463 382.417 371.505 382.72Z" fill="#EB996E" />
			<path d="M313.982 297.285L275.499 342.828L186.661 370.716L207.621 320.049C208.379 318.188 209.579 316.539 211.118 315.246C212.658 313.954 214.489 313.057 216.454 312.633C234.625 308.677 286.986 297.214 310.209 291.395C310.959 291.207 311.749 291.259 312.467 291.544C313.186 291.829 313.797 292.333 314.214 292.983C314.631 293.634 314.833 294.4 314.791 295.172C314.749 295.944 314.466 296.683 313.982 297.285Z" fill="#E0E0E0" />
			<path d="M314.286 297.408C314.286 297.408 314.453 297.163 314.693 296.68C315.045 295.948 315.127 295.115 314.926 294.328C314.777 293.695 314.461 293.112 314.011 292.642C313.56 292.172 312.992 291.831 312.364 291.656C311.548 291.487 310.701 291.553 309.921 291.848L307.1 292.564C299.122 294.552 287.545 297.193 273.236 300.498C258.927 303.802 241.852 307.525 222.892 311.659C220.537 312.2 218.066 312.641 215.734 313.273C213.396 314.029 211.35 315.491 209.877 317.458C209.13 318.444 208.528 319.532 208.09 320.689L206.608 324.267L203.605 331.504L191.071 361.697L187.338 370.66L186.846 370.111L275.66 342.526L275.529 342.629C287.382 328.746 297.04 317.432 303.768 309.578L311.533 300.545L313.577 298.221C314.03 297.69 314.289 297.438 314.289 297.438C314.289 297.438 314.08 297.733 313.643 298.277L311.666 300.673L304.032 309.789L275.993 343.025L275.901 343.032L187.147 370.799L186.333 371.064L186.657 370.265C187.879 367.326 189.129 364.338 190.376 361.319L202.943 331.154L205.962 323.916L207.459 320.337C207.914 319.113 208.542 317.96 209.324 316.914C210.902 314.834 213.079 313.287 215.564 312.483C218.031 311.794 220.396 311.392 222.782 310.849L273.171 299.869C287.505 296.686 299.083 294.06 307.07 292.18L309.909 291.509C310.729 291.2 311.619 291.13 312.476 291.307C313.137 291.504 313.732 291.876 314.2 292.384C314.667 292.891 314.989 293.514 315.131 294.189C315.327 295.012 315.214 295.879 314.812 296.624C314.683 296.913 314.505 297.178 314.286 297.408Z" fill="#124173" />
			<path d="M248.253 331.798C248.669 333.7 251.577 334.627 254.625 334.01C257.672 333.393 259.979 331.272 259.578 329.369C259.177 327.465 256.253 326.539 253.206 327.156C250.158 327.773 247.853 329.91 248.253 331.798Z" fill="white" />
			<path d="M210.401 327.459C210.436 327.07 210.525 326.688 210.666 326.323C210.962 325.244 211.316 324.182 211.726 323.141C212.332 321.483 213.201 319.933 214.298 318.551C216.057 316.974 218.243 315.952 220.581 315.614C230.504 313.081 244.203 309.547 259.432 305.982L270.524 303.355C274.132 302.584 277.627 301.947 280.95 301.292L298.624 297.902C303.626 296.942 307.577 295.633 310.376 295.005C311.773 294.66 312.853 294.418 313.602 294.296C313.976 294.221 314.359 294.191 314.74 294.204C314.382 294.339 314.012 294.441 313.635 294.51C312.906 294.693 311.83 294.981 310.452 295.37C307.693 296.104 303.776 297.457 298.736 298.528L281.137 302.082C277.816 302.752 274.337 303.404 270.73 304.174L259.64 306.833C244.44 310.379 230.779 313.802 220.749 316.158C218.502 316.445 216.388 317.379 214.663 318.847C213.569 320.173 212.685 321.659 212.045 323.254C211.469 324.57 211.104 325.606 210.835 326.309C210.721 326.704 210.576 327.088 210.401 327.459Z" fill="#124173" />
			<path d="M164.714 337.007C164.714 337.007 180.562 325.356 185.772 322.753C190.982 320.15 200.994 320.444 203.205 318.098C205.416 315.752 210.325 310.37 210.325 310.37C210.325 310.37 214.997 314.547 212.557 318.862C210.118 323.177 199.951 329.416 199.951 329.416L236.848 323.983C236.848 323.983 239.811 325.788 238.494 328.279C238.494 328.279 241.335 330.868 239.391 333.843C237.447 336.818 212.878 361.312 204.656 362.655C196.433 363.998 172.046 361.518 172.046 361.518L164.714 337.007Z" fill="#FFBE9D" />
			<path d="M211.118 315.246C209.579 316.539 208.378 318.187 207.621 320.049L205.238 325.809L210.175 323.309L215.93 316.371L220.948 311.653C219.284 312.016 217.778 312.345 216.454 312.633C214.489 313.057 212.658 313.954 211.118 315.246Z" fill="#E0E0E0" />
			<path d="M215.734 313.273C218.066 312.641 220.537 312.2 222.892 311.659L222.782 310.849C220.396 311.392 218.031 311.794 215.564 312.483C213.079 313.287 210.902 314.834 209.324 316.914C208.542 317.96 207.914 319.113 207.459 320.337L205.962 323.916L204.577 327.237L205.5 326.939L206.608 324.267L208.09 320.689C208.528 319.532 209.13 318.444 209.877 317.458C211.35 315.491 213.396 314.029 215.734 313.273Z" fill="#124173" />
			<path d="M199.761 337.638C201.766 337.567 203.774 337.596 205.776 337.726C210.576 337.973 215.383 337.414 219.997 336.07C222.473 335.144 224.889 334.065 227.232 332.84L233.196 329.99C234.866 329.19 236.178 328.558 237.18 328.121C237.658 327.871 238.161 327.675 238.682 327.536C238.263 327.881 237.803 328.173 237.312 328.405C236.408 328.911 235.071 329.623 233.422 330.483L227.624 333.474C225.268 334.764 222.822 335.882 220.305 336.82C215.609 338.201 210.696 338.692 205.819 338.265C203.952 338.152 202.454 338.009 201.422 337.891C200.859 337.89 200.299 337.805 199.761 337.638Z" fill="#EB996E" />
			<path d="M204.879 349.459C204.866 349.305 207.213 349.41 210.916 349.05C213.079 348.843 215.194 348.286 217.178 347.4C219.598 346.279 221.896 344.911 224.035 343.317C226.364 341.674 228.571 340.041 230.711 338.738C232.852 337.435 234.778 336.351 236.301 335.269C237.949 334.123 239.428 332.753 240.696 331.198C240.514 331.719 240.232 332.2 239.865 332.612C238.935 333.796 237.848 334.848 236.633 335.737C235.176 336.877 233.18 338.044 231.096 339.28C229.012 340.517 226.843 342.24 224.484 343.901C222.299 345.523 219.944 346.901 217.46 348.012C215.396 348.876 213.203 349.393 210.971 349.541C209.482 349.644 207.987 349.65 206.497 349.561C205.956 349.608 205.41 349.573 204.879 349.459Z" fill="#EB996E" />
			<path d="M187.499 337.836C187.407 337.843 187.034 337.44 186.822 336.543C186.591 335.378 186.681 334.172 187.083 333.054C187.485 331.963 188.074 330.951 188.825 330.064C189.375 329.4 189.795 329.041 189.879 329.112C189.962 329.183 188.727 330.97 187.848 333.317C187.484 334.32 187.328 335.386 187.388 336.451C187.44 337.283 187.603 337.781 187.499 337.836Z" fill="#EB996E" />
			<path d="M190.931 347.966C190.938 348.042 190.531 347.998 190.09 347.708C189.507 347.294 189.069 346.706 188.84 346.028C188.59 345.36 188.564 344.628 188.765 343.944C188.924 343.42 189.168 343.183 189.249 343.223C189.329 343.263 189.116 344.457 189.593 345.766C190.07 347.075 191.038 347.756 190.931 347.966Z" fill="#EB996E" />
		</svg>

	)

}

IconNotDelivery.propTypes = {
	className: PropTypes.string,
}

export default IconNotDelivery