import React from 'react'
import PropTypes from 'prop-types'

const IconAvatar = ({ className, onClick, backgroundColor = null, lineColor = null }) => {
	return (
		<svg
			className={className} onClick={onClick}
			width="35" height="35"
			viewBox="0 0 35 35" fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<mask
				id="mask0" mask-type="alpha"
				maskUnits="userSpaceOnUse" x="0"
				y="0" width="35"
				height="35">
				<circle
					cx="17.5" cy="17.5"
					r="17.5" fill="#FBFBFB" />
			</mask>
			<g mask="url(#mask0)">
				<circle
					cx="17.5" cy="17.5"
					r="17.5" fill={backgroundColor ? backgroundColor : '#EC6608'} />
				<path d="M26.7709 24.2969C25.3673 22.8934 23.6967 21.8544 21.874 21.2254C23.8262 19.8808 25.1088 17.6306 25.1088 15.0862C25.1088 10.9783 21.7667 7.63623 17.6588 7.63623C13.5509 7.63623 10.2089 10.9783 10.2089 15.0862C10.2089 17.6306 11.4914 19.8808 13.4437 21.2254C11.621 21.8544 9.95036 22.8934 8.54681 24.2969C6.11289 26.7309 4.77246 29.9669 4.77246 33.409H6.78596C6.78596 27.4136 11.6635 22.5361 17.6588 22.5361C23.6542 22.5361 28.5317 27.4136 28.5317 33.409H30.5452C30.5452 29.9669 29.2048 26.7309 26.7709 24.2969ZM17.6588 20.5226C14.6612 20.5226 12.2224 18.0839 12.2224 15.0862C12.2224 12.0885 14.6612 9.64972 17.6588 9.64972C20.6565 9.64972 23.0953 12.0885 23.0953 15.0862C23.0953 18.0839 20.6565 20.5226 17.6588 20.5226Z" fill={lineColor ? lineColor : '#0256B1'} />
			</g>
		</svg>
	)
}

IconAvatar.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,
	backgroundColor: PropTypes.string,
	lineColor: PropTypes.string

}

export default IconAvatar