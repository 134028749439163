import React from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'

import Button from 'components/ui/Button/Button'
import Title from 'components/ui/Title/Title'

import './style.scss'

const CheckoutSuccess = ({ orderId }) => {

	const history = useHistory()

	return (
		<div className="сheckoutSuccess">
			<Title
				content="Спасибо, ваш заказ оформлен"
				className='сheckoutSuccess__title'
			/>
			<div className="сheckoutSuccess__text">
				Номер заказа:&nbsp;
				<div className="сheckoutSuccess__orderId">{orderId}</div>
			</div>
			<div className="сheckoutSuccess__buttons">
				<Button
					text="На главную"
					className="сheckoutSuccess__button сheckoutSuccess__button--toHome"
					callback={() => history.push(`/`)}

				/>
				<Button
					text="В каталог"
					className="сheckoutSuccess__button сheckoutSuccess__button--toCatalog"
					callback={() => history.push(`/catalog/`)}
				/>
			</div>
		</div>
	)
}

CheckoutSuccess.propTypes = {
	orderId: PropTypes.string,
}
export default CheckoutSuccess