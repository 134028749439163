import React, { forwardRef } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Button from 'components/ui/Button/Button'

import './confirm-notification.scss'
const ConfirmNotification = forwardRef(({
	className,
	notification,
	confirmCallback,
	confirmButtonText = null,
	cancelCallback,
	cancelButtonText = null,
	disableButton = false
}, ref) => {

	return (
		<div className={classNames('confirm-notification', className)} ref={ref}>
			{notification}
			<div className="confirm-notification__buttons">
				<Button
					disabled={disableButton} text={confirmButtonText || 'Верно'}
					callback={confirmCallback} />
				<Button
					className="confirm-notification__buttons--cancel"
					disabled={disableButton} text={cancelButtonText || 'Отмена'}
					callback={cancelCallback} />
			</div>
		</div>
	)
})

ConfirmNotification.propTypes = {
	className: PropTypes.string,
	notification: PropTypes.string,
	confirmCallback: PropTypes.func,
	confirmButtonText: PropTypes.string,
	cancelCallback: PropTypes.func,
	cancelButtonText: PropTypes.string,
	disableButton: PropTypes.bool,
}
ConfirmNotification.displayName = 'ConfirmNotification'
export default ConfirmNotification