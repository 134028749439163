import React, { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import { Parser } from 'html-to-react'

import { useFetch } from 'hooks/useFetch'
import useDispatchSaga from 'hooks/useDispatchSaga'

import { priceFormatter } from 'helpers'

import { REQUEST_FAILED } from 'redux/types/errorTypes'

import BasketService from 'services/BasketService'
import LocalStorageService from 'services/LocalStorageService'

import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs'
import AvailabilityInfoDetail from 'components/AvailabilityInfoDetail/AvailabilityInfoDetail'
import SwitchQuantity from 'components/SwitchQuantity/SwitchQuantity'
import Accordion from 'components/ui/Accordion/Accordion'
import Title from 'components/ui/Title/Title'
import Button from 'components/ui/Button/Button'

import IconNoPhoto from 'assets/icons/IconNoPhoto'
import CirclePreloader from 'assets/preloaders/Circle'
import LogoPreloader from 'assets/preloaders/LogoPreloader'

import './element-detail.scss'
import IconFavorite from 'assets/icons/IconFavorite'
import ModalAddToFavorite from 'components/Modals/AddToFavorite'

const ElementDetail = ({ elementId, breadcrumbs, setFlagLoading, isFinishLoading }) => {

	const basketProducts = useSelector(state => state.basket.basket)
	const errors = useSelector(state => state.errors.errors)
	const localStorage = LocalStorageService.get()

	const { dispatchSaga } = useDispatchSaga()
	const dispatch = useDispatch()

	const htmlToReactParser = new Parser()

	const favoriteInfoInitial = {
		openModal: false,
		product: null,
		folderIds: []
	}
	const [favoriteInfo, setFavoriteInfo] = useState(favoriteInfoInitial)
	const [elementData, setElementData] = useState(null)
	const [user, setUser] = useState(localStorage)
	const [accordionItems, setAccordionItems] = useState([])
	const [errorText, setErrorText] = useState(null)
	const [productAddToBasketProcessing, setProductAddToBasketProcessing] = useState('')
	const [isLoadingRests, setIsLoadingRests] = useState(false)
	const [actualRests, setActualRests] = useState(null)
	const { request, error } = useFetch()

	const loadData = async () => {

		try {

			const res = await request('catalog/product', { id: elementId }, {}, 'GET')

			setElementData({ ...res.data, quantity: 1 })

			document.body.scrollIntoView({ behavior: 'smooth' })

		} catch (error) {

			setErrorText(error.message)

		} finally {

			setFlagLoading('loadingDetail', true)

		}

	}

	useEffect(() => {

		loadData()

	}, [elementId])

	useEffect(() => {

		if (isFinishLoading && errorText) {
			dispatch({ type: REQUEST_FAILED, payload: { message: errorText } })
		}

	}, [errorText, isFinishLoading])

	useEffect(async () => {

		if (elementData) {

			let items = []

			if (elementData.properties) {

				items.push({ name: 'Характеристики', code: 'properties', content: elementData.properties })
			}

			if (elementData.description) {

				items.push({ name: 'Описание', code: 'description', content: elementData.description })

			}

			if (items.length > 0) {
				setAccordionItems(items)
			}

			if (!isLoadingRests) {

				const uuids = [elementData.xml_id]

				const { data } = await request(
					'catalog/rests',
					{ uuids },
					{},
					'GET'
				)

				setActualRests(data)
			}

		}

	}, [elementData])

	useEffect(() => {

		if (actualRests) {

			setElementData(prevState => {
				return { ...prevState, availability: actualRests[elementData.id] }
			})

			setIsLoadingRests(true)
		}

	}, [actualRests])

	// Обновление цены при изменении пользователя или соглашения
	useEffect(() => {

		if (user.login !== localStorage.login || user.agreement !== localStorage.agreement) {
			loadData()
			setUser(localStorage)
		}

	}, [localStorage.login, localStorage.agreement])

	const breadcrumbsDetailPage = useMemo(() => {
		if (breadcrumbs.length > 0 && !breadcrumbs.find(i => i === elementId) && elementData) {
			return [...breadcrumbs, { id: elementId, name: elementData.name, detail: true }]
		}

	}, [breadcrumbs, elementData])

	const addToBasket = () => {

		setProductAddToBasketProcessing(true)

		BasketService.addToBasket(
			basketProducts[basketProducts.findIndex(product => product.product_id === elementData.id)],
			{ ...elementData, price: elementData.price.base },
			dispatchSaga,
			() => setProductAddToBasketProcessing(false)
		)

	}

	useEffect(() => {

		if (productAddToBasketProcessing) {
			setProductAddToBasketProcessing(false)
		}

	}, [basketProducts, errors])

	return (

		<div className="element-detail">
			{
				isFinishLoading && !error && elementData ?
					<>
						{breadcrumbsDetailPage &&
							<Breadcrumbs
								root='/catalog/'
								path={breadcrumbsDetailPage}
								className="element-detail__breadcrumbs"
							/>
						}
						<div className="element-detail__content">
							<div className="element-detail__image">
								{
									elementData.picture ?
										<img className="element-detail__imagePicture" src={elementData.picture} /> :
										<IconNoPhoto className="element-detail__imageNoPhoto" />
								}

							</div>
							<div className="element-detail__info">

								<div className="element-detail__wrap">
									<Title content={htmlToReactParser.parse(elementData.name)} />
									<IconFavorite
										className={
											classNames(
												'element-detail__favorite',
												elementData.in_favorities.length > 0 && 'element-detail__favorite--active'
											)
										}
										onClick={() => (setFavoriteInfo({
											openModal: true,
											productId: elementData.id,
											folderIds: elementData.in_favorities
										}))}
									/>
								</div>
								<div className="element-detail__article">Арт. {elementData.vendorCode}</div>

								<AvailabilityInfoDetail stores={elementData.availability} preloader={!isLoadingRests} />

								<div className="element-detail__price">
									<div className="element-detail__priceTitle">Цена</div>
									<div className="element-detail__priceValue">{priceFormatter(elementData.price.base)}</div>
								</div>
								<div className="element-detail__addToBasket">
									<div className="element-detail__quantity">
										<div className="element-detail__summ">
											<div className="element-detail__summTitle">Сумма</div>
											<div className="element-detail__summValue">

												{priceFormatter(elementData.price.base * elementData.quantity)}

												{
													elementData.price.unit.length > 0 &&
													<div className="element-detail__priceUnit">
														{elementData.price.unit}
													</div>
												}

											</div>
										</div>
										<SwitchQuantity
											theme="detailPage"
											item={elementData}
											callback={item => setElementData(item)}
										/>
									</div>
									{
										productAddToBasketProcessing ?
											<CirclePreloader
												className='element-detail__addToBasketPreloader'
												width="14px"
												height="14px"
											/>
											:

											<Button
												callback={() => elementData.price.base !== 0 && addToBasket()}
												text="В корзину"
												className={
													classNames(
														'element-detail__addToBasketButton',
														elementData.price.base === 0 && 'element-detail__addToBasketButton--disabled'
													)
												}
											/>

									}

								</div>
								{accordionItems.length > 0 && <Accordion items={accordionItems} />}
								{
									favoriteInfo.openModal &&
										<ModalAddToFavorite
											productId={favoriteInfo.productId}
											folderIds={elementData.in_favorities}
											onClose={() => setFavoriteInfo(favoriteInfoInitial)}
											callback={params => setElementData(prevState => {
												return { ...prevState, in_favorities: params.element_id }
											})}
										/>
								}
							</div>
						</div>

					</> :
					isFinishLoading && error ?
						<div>Ошибка загрузки данных</div> :
						<div className="catalog__preloader sssssssssssss"><LogoPreloader /></div>

			}

		</div>
	)
}
ElementDetail.propTypes = {
	elementId: PropTypes.string,
	breadcrumbs: PropTypes.array,
	setFlagLoading: PropTypes.func,
	isFinishLoading: PropTypes.bool,
}
export default ElementDetail
