import React from 'react'
import PropTypes from 'prop-types'

const IconPassword = ({ className, onClick }) => {
	return (
		<svg
			className={className} onClick={onClick}
			width="20" height="20"
			viewBox="0 0 20 20" fill="#B7B7B7"
			xmlns="http://www.w3.org/2000/svg">
			<path d="M18.8856 9.38616C18.7248 9.16619 14.8934 4 9.99992 4C5.10647 4 1.27485 9.16619 1.11422 9.38595C0.961926 9.59464 0.961926 9.87768 1.11422 10.0864C1.27485 10.3063 5.10647 15.4725 9.99992 15.4725C14.8934 15.4725 18.7248 10.3063 18.8856 10.0865C19.0381 9.8779 19.0381 9.59464 18.8856 9.38616ZM9.99992 14.2857C6.39538 14.2857 3.27346 10.8568 2.3493 9.73586C3.27226 8.61392 6.38764 5.18681 9.99992 5.18681C13.6043 5.18681 16.726 8.61511 17.6505 9.73667C16.7276 10.8586 13.6122 14.2857 9.99992 14.2857Z" />
			<path d="M10 10C10 12.2091 8.20914 14 6 14C4 13 3.5 12 2 10C3.5 8 4.5 7 6 6C8.20914 6 10 7.79086 10 10Z" />
		</svg>

	)

}

IconPassword.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,

}

export default IconPassword