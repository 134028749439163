import React from 'react'
import { Route } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { useDocumentTitle } from 'hooks/useDocumentTitle'

const PrivateRoute = ({ component: Component, ...rest }) => {

	const { isSearching } = useSelector(state => state.search)
	const { title } = rest

	if (title) {
		useDocumentTitle(title)
	}

	return (
		<Route {...rest}>
			{({ match }) => (
				<Component
					match={match}
					invisible={isSearching}
				/>
			)}
		</Route>
	)
}
PrivateRoute.propTypes = {
	component: PropTypes.func,
}
export default PrivateRoute