import React from 'react'
import PropTypes from 'prop-types'

const IconArrow = ({ className, onClick }) => {
	return (
		<svg
			className={className} onClick={onClick}
			width="12" height="7"
			viewBox="0 0 12 7" fill="#0D1011"
			xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd" clipRule="evenodd"
				d="M11.7614 1.42872L6.57614 6.75487C6.25794 7.08171 5.74206 7.08171 5.42386 6.75487L0.238644 1.42872C-0.0795479 1.10188 -0.0795479 0.571968 0.238644 0.245129C0.556835 -0.0817103 1.07272 -0.0817103 1.39091 0.245129L6 4.97949L10.6091 0.245129C10.9273 -0.0817099 11.4432 -0.0817099 11.7614 0.24513C12.0795 0.571969 12.0795 1.10188 11.7614 1.42872Z" />
		</svg>
	)

}

IconArrow.propTypes = {
	className: PropTypes.string,
	onClick: PropTypes.func,

}

export default IconArrow