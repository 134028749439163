import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import FinanceWidget from 'components/ui/FinanceWidget/FinanceWidget'

import './finance-widgets.scss'

const FinanceWidgets = ({ blockArrangement, layer, userFinances }) => {

	return (

		<div className={classNames('user-finance-info', layer && 'user-finance-info--layer')} >
			{userFinances.map((data, key) => {
				return <FinanceWidget
					className={
						classNames(
							'user-finance-info__widget',
							`user-finance-info__widget--${blockArrangement}`,
							layer && 'user-finance-info__widget--zIndexNegative'
						)
					}
					theme={blockArrangement === 'column' ? 'header' : ''}
					key={key}
					data={data}
				/>
			})}
		</div >
	)
}

FinanceWidgets.propTypes = {
	blockArrangement: PropTypes.string,
	layer: PropTypes.bool,
	userFinances: PropTypes.array,
}
export default FinanceWidgets