import React, { useState, useEffect } from 'react'
import Cookies from 'universal-cookie'

import { useAuth } from 'hooks/useAuth'

import Button from 'components/ui/Button/Button'
import Modal from 'components/ui/Modal'

import IconClose from 'assets/icons/IconClose'

import './notifications.scss'

const Notifications = () => {
	const cookies = new Cookies()
	const cookieNotificationPrefix = 'viewed_notification_'

	const { notifications, setNotifications } = useAuth()
	const [detailNotification, setDetailNotification] = useState(null)
	
	const closeMessage = (e, messageId) => {
		e.stopPropagation()

		const now = new Date()
		const expire = new Date()

		expire.setFullYear(now.getFullYear())
		expire.setMonth(now.getMonth())
		expire.setDate(now.getDate() + 1)
		expire.setHours(0)
		expire.setMinutes(0)
		expire.setSeconds(0)

		cookies.set(`${cookieNotificationPrefix}${messageId}`, true, { path: '/', expires: expire })

		setNotifications(prevState => {
			return prevState.filter(prevItem => prevItem.id !== messageId)
		})
		
		setDetailNotification(null)
	}

	const items = notifications.filter(item => !Object.keys(cookies.getAll()).includes(`${cookieNotificationPrefix}${item.id}`))

	return (
		items.length > 0 &&
		<div className="notifications">
			{
				items.map(item => {
					return (
						<div 
							key={item.id}
							className="notification" 
							style={{ zIndex: item.id }}
							onClick={() => setDetailNotification(item)}
							
						>
							<div className="notification__name">{item.name}</div>
							<IconClose
								className="notification__close"
								onClick={e => closeMessage(e, item.id)}
							/>
						</div>
					)
				})
			}

			{
				detailNotification &&
				<Modal classNameWrapper="notification__modal">
					<div className="notification__caption" dangerouslySetInnerHTML={{ __html: detailNotification.caption }} />
					<Button
						className="notification__button"
						text='Понятно'
						onClick={e => closeMessage(e, detailNotification.id)}
					/>
				</Modal>
			}
		</div>

	)
}

export default Notifications