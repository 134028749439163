import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { priceFormatter } from 'helpers'

import useDispatchSaga from 'hooks/useDispatchSaga'
import BasketService from 'services/BasketService'

import AvailabilityInfo from 'components/AvailabilityInfo/AvailabilityInfo'
import SwitchQuantity from 'components/SwitchQuantity/SwitchQuantity'
import BasketListSkeleton from 'components/ui/skeletons/BasketListSkeleton'
import Title from 'components/ui/Title/Title'

import IconDelete from 'assets/icons/IconDelete'
import CirclePreloader from 'assets/preloaders/Circle'

import 'scss/common.blocks/table.scss'
import './style.scss'

const Basket = () => {

	const { dispatchSaga } = useDispatchSaga()
	const basket = useSelector(state => state.basket)
	const history = useHistory()

	const tableHead = {
		article: 'Артикул',
		name: 'Наименование',
		availability: 'Наличие',
		price: 'Цена',
		quantity: 'Количество',
		summ: 'Сумма',
	}

	const [productDeletingId, setProductDeletingId] = useState('')
	const [productChangeQuantityId, setProductChangeQuantityId] = useState('')

	const deleteProduct = (id) => {

		BasketService.deleteToBasket(id, dispatchSaga, () => setProductDeletingId(''))
		setProductDeletingId(id)

	}

	const changeQuantityProduct = (product) => {

		if (product.quantity.length !== 0) {

			BasketService.changeQuantityBasketItem(product, dispatchSaga, () => setProductChangeQuantityId(''))
			setProductChangeQuantityId(product.id)

		}

	}

	useEffect(() => {

		// Отрабатывает при удалении товара из корзины
		if (productDeletingId.length > 0) {
			setProductDeletingId('')
		}

		// Отрабатывает при изменениии кол-ва товара в корзине
		if (productChangeQuantityId.length > 0) {
			setProductChangeQuantityId('')
		}

	}, [basket])
	return (
		<div className="basket">
			<Title
				content="Моя корзина"
				className='basket__title'
			/>
			<table className="table basket__table">
				<thead className="table__head">
					<tr className="table__row">
						{Object.keys(tableHead).map(index => (
							<td
								className={`table__cell table__cell--head basket__tableHeadСell--${index}`}
								key={index}
							>
								{tableHead[index]}
							</td>
						))}
						<td
							className="table__cell"
						></td>
					</tr>

				</thead>
				<tbody className="table__body">
					{
						basket.basket.length > 0 && basket.basketLoading ?
							basket.basket.map(item => {
								return (
									<tr
										className="table__row"
										key={item.id}>
										<td className="table__cell basket__tableBodyСell">
											{item.article}
										</td>
										<td className="table__cell basket__tableBodyСell basket__tableBodyСell--name" onClick={() => history.push(`/catalog/${item.sectionId}/${item.product_id}/`)} >
											{item.name}
										</td>
										<td className="table__cell basket__tableBodyСell">

											<>
												{`${item.availability.reduce((prevValue, currentEl) => {
													return prevValue + currentEl.free
												}, 0)}/${item.availability.reduce((prevValue, currentEl) => {
													return prevValue + currentEl.quantity
												}, 0)}`}
												<AvailabilityInfo stores={item.availability} />
											</>

										</td>
										<td className="table__cell basket__tableBodyСell">
											{priceFormatter(item.price)}
										</td>
										<td className="table__cell basket__tableBodyСell">
											<SwitchQuantity
												debounced
												item={item}
												processing={productChangeQuantityId === item.id}
												callback={item => changeQuantityProduct(item)}
											/>
										</td>
										<td className="table__cell basket__tableBodyСell">
											{priceFormatter(item.quantity * item.price)}

										</td>
										<td className="table__cell basket__tableBodyСell--delete">

											{
												productDeletingId === item.id ?
													<CirclePreloader width="14px" height="14px" />
													:

													<IconDelete
														className="basket__tableBodyСell--deleteIcon"
														onClick={() => deleteProduct(item.id)} />

											}

										</td>
									</tr>
								)
							}) :
							basket.basketLoadingError ?
								<tr className="table__row">
									<td
										className="table__cell basket__tableBodyСell basket__tableBodyСell--empty"
										colSpan="6">Ошибка загрузки данных</td>
								</tr>
								:
								!basket.basketLoading ?
									<BasketListSkeleton rowCount={3} />
									:
									<tr className="table__row">
										<td
											className="table__cell basket__tableBodyСell basket__tableBodyСell--empty"
											colSpan="6">Ваша корзина пуста</td>
									</tr>
					}
				</tbody>
				<tfoot className="table__foot">
					<tr className="table__row">
						<td
							className="table__cell table__cell--foot basket__tableFootСell basket__tableFootСell--pl15"
							colSpan="5">Итого</td>
						<td
							className="table__cell table__cell--foot basket__tableFootСell"
							colSpan="2">
							{priceFormatter(basket.totalPrice)}
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
	)

}

export default Basket