import React from 'react'
import PropTypes from 'prop-types'

const ToTimeIcon = ({ circleFill, pathFill }) => {
	return (
		<svg
			width="24" height="24"
			viewBox="0 0 24 24" fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<circle
				cx="12" cy="12"
				r="12" fill={circleFill || '#EDF3FA'} />
			<path d="M15.3182 13.2275C14.4147 13.2275 13.6797 13.9616 13.6797 14.8639C13.6797 15.7662 14.4147 16.5003 15.3182 16.5003C16.2219 16.5003 16.9567 15.7662 16.9567 14.8639C16.9567 13.9616 16.2217 13.2275 15.3182 13.2275ZM15.3182 15.6821C14.8664 15.6821 14.499 15.3151 14.499 14.8639C14.499 14.4127 14.8664 14.0457 15.3182 14.0457C15.77 14.0457 16.1375 14.4127 16.1375 14.8639C16.1375 15.3152 15.77 15.6821 15.3182 15.6821Z" fill={pathFill || '#0256B1'} />
			<path d="M9.71861 13.2275C8.8151 13.2275 8.08008 13.9616 8.08008 14.8639C8.08008 15.7662 8.8151 16.5003 9.71861 16.5003C10.6221 16.5003 11.3571 15.7662 11.3571 14.8639C11.3571 13.9616 10.6221 13.2275 9.71861 13.2275ZM9.71861 15.6821C9.26679 15.6821 8.89934 15.3151 8.89934 14.8639C8.89934 14.4127 9.26679 14.0457 9.71861 14.0457C10.1703 14.0457 10.5379 14.4127 10.5379 14.8639C10.5379 15.3152 10.1704 15.6821 9.71861 15.6821Z" fill={pathFill || '#0256B1'} />
			<path d="M16.42 8.54364C16.3503 8.40551 16.2087 8.31836 16.0539 8.31836H13.8965V9.13655H15.8013L16.9167 11.3522L17.6488 10.9846L16.42 8.54364Z" fill={pathFill || '#0256B1'} />
			<path d="M14.0898 14.4683H10.9902V15.2865H14.0898V14.4683Z" fill={pathFill || '#0256B1'} />
			<path d="M8.49176 14.4683H7.07173C6.84547 14.4683 6.66211 14.6514 6.66211 14.8773C6.66211 15.1033 6.84549 15.2864 7.07173 15.2864H8.49179C8.71805 15.2864 8.90141 15.1033 8.90141 14.8773C8.90141 14.6514 8.71803 14.4683 8.49176 14.4683Z" fill={pathFill || '#0256B1'} />
			<path d="M18.4144 11.9673L17.6087 10.9309C17.5313 10.8311 17.4118 10.7727 17.2852 10.7727H14.3085V7.90908C14.3085 7.68312 14.1252 7.5 13.8989 7.5H7.07173C6.84547 7.5 6.66211 7.68314 6.66211 7.90908C6.66211 8.13503 6.84549 8.31817 7.07173 8.31817H13.4893V11.1818C13.4893 11.4078 13.6727 11.5909 13.8989 11.5909H17.0846L17.6812 12.3584V14.4682H16.5478C16.3216 14.4682 16.1382 14.6514 16.1382 14.8773C16.1382 15.1033 16.3216 15.2864 16.5478 15.2864H18.0908C18.317 15.2864 18.5004 15.1032 18.5004 14.8773V12.2182C18.5004 12.1274 18.4701 12.039 18.4144 11.9673Z" fill={pathFill || '#0256B1'} />
			<path d="M8.46386 12.396H6.57954C6.35328 12.396 6.16992 12.5791 6.16992 12.8051C6.16992 13.031 6.3533 13.2142 6.57954 13.2142H8.46383C8.69009 13.2142 8.87345 13.031 8.87345 12.8051C8.87348 12.5791 8.69009 12.396 8.46386 12.396Z" fill={pathFill || '#0256B1'} />
			<path d="M9.40514 10.7861H5.90962C5.68338 10.7861 5.5 10.9693 5.5 11.1952C5.5 11.4212 5.68338 11.6043 5.90962 11.6043H9.40514C9.63141 11.6043 9.81476 11.4212 9.81476 11.1952C9.81476 10.9693 9.63141 10.7861 9.40514 10.7861Z" fill={pathFill || '#0256B1'} />
			<path d="M10.0751 9.17725H6.57954C6.35328 9.17725 6.16992 9.36039 6.16992 9.58633C6.16992 9.8123 6.3533 9.99541 6.57954 9.99541H10.0751C10.3013 9.99541 10.4847 9.81227 10.4847 9.58633C10.4847 9.36039 10.3013 9.17725 10.0751 9.17725Z" fill={pathFill || '#0256B1'} />
		</svg>
	)

}

ToTimeIcon.propTypes = {
	circleFill: PropTypes.string,
	pathFill: PropTypes.string,
}

export default ToTimeIcon
