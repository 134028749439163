import { withRouter } from 'react-router-dom'
import React from 'react'

import { AuthContext } from 'context/AuthContext'

import { useAuth } from 'hooks/useAuth'

import { routes } from './routes'

import ErrorNotification from 'components/ErrorNotification/ErrorNotification'

const App = () => {

	const { token, login, logout, user, agreement, setUser } = useAuth()
	return (

		<AuthContext.Provider
			value={{
				token,
				login,
				logout,
				user,
				agreement,
				setUser
			}}>
			{routes(token, agreement)}
			<ErrorNotification />
		</AuthContext.Provider>

	)
}
export default withRouter(App)